import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { Title } from 'components/UI/Title/Title';

import { ToolkitBody } from './ToolkitBody';

import './Toolkit.css';

export const Toolkit = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className="toolkit-container">
        <Title title={t('admin.tools.title_toolbox')} />
        <div className="tool-links-container">
          <ToolkitBody />
        </div>
      </div>
    </>
  );
};

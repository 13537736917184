import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import './Thumbnail.scss';

interface ThumbnailParams {
  src: string;
  alt: string;
  shadow?: boolean;
  onClick?: () => void;
  dataTestId?: string;
}

export const Thumbnail: React.FC<ThumbnailParams> = ({ src, alt, shadow, onClick, dataTestId }) => {
  const [imgSrc, setImgSrc] = useState(src);
  const [altText, setAltText] = useState(alt);
  const fallbackImage = 'https://devmycolasv2as.blob.core.windows.net/public/media-error.png';

  const handleError = (): void => {
    setImgSrc(fallbackImage);
    setAltText('Media Not Found');
  };

  return (
    <div className="thumbnail-aspect-ratio-container">
      <div className="thumbnail-aspect-ratio">
        <div
          className={`thumbnail-content ${onClick ? 'expandable' : ''}`}
          onClick={onClick}
          data-testid={dataTestId ? `${dataTestId}-content` : ''}
        >
          <img src={imgSrc} alt={altText} className="thumbnail" data-testid={dataTestId ?? ''} onError={handleError} />
          {shadow && <div className="thumbnail-shadow" />}
          {onClick && (
            <div className="thumbnail-expand">
              <div className="thumbnail-expand-content">
                <FontAwesomeIcon icon={['fas', 'up-right-and-down-left-from-center']} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import React, { useCallback } from 'react';

import { BlackCrossButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { PublicationScopeSelectorByActivityModalContent } from 'components/PublicationScopeSelectorByActivityModalContent/PublicationScopeSelectorByActivityModalContent';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { PublicationScope, PublicationScopeType, UserCountryPublicationScope } from 'types/PublicationScope';
import { getPublicationScopeFromLocalStorage, setLocalStorageItem } from 'utils/localStorage';

import './FrontOfficeLocalScopeSelectorModal.css';

interface FrontOfficeLocalScopeSelectorModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const FrontOfficeLocalScopeSelectorModal: React.FC<FrontOfficeLocalScopeSelectorModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { frontOfficeLocalScope, setFrontOfficeLocalScope } = useFrontOfficeLocalScopeContext();

  const handleSelectFrontOfficeLocalScope = useCallback(
    (newScope: PublicationScope, newRelatedActivity?: PublicationScope): void => {
      const newPublicationScopeWithParent: UserCountryPublicationScope = {
        ...newScope,
        activityParentScope: newRelatedActivity ?? null,
      };

      const localStorageLocalScope = getPublicationScopeFromLocalStorage();
      if (localStorageLocalScope === null && frontOfficeLocalScope === null) {
        setLocalStorageItem('frontOfficeLocalScope', newPublicationScopeWithParent);
      }
      setFrontOfficeLocalScope(newPublicationScopeWithParent);
      onClose();
    },
    [setFrontOfficeLocalScope, onClose, frontOfficeLocalScope],
  );

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose} dataTestId="front-office-local-scope-selector-modal">
      <div className="front-office-local-scope-selector-modal-container">
        <div className="front-office-local-scope-selector-modal-header">
          <span className="front-office-local-scope-selector-modal-title">
            {t('frontoffice.local_scope_selector_modal.title')}
          </span>
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="front-office-local-scope-selector-modal-content">
          <PublicationScopeSelectorByActivityModalContent
            onSelectScope={handleSelectFrontOfficeLocalScope}
            scopeTypesToFilterOn={[PublicationScopeType.COUNTRY]}
            initiallySelectedActivityId={frontOfficeLocalScope?.activityParentScope?.id}
            currentlySelectedScopeId={frontOfficeLocalScope?.id}
          />
        </div>
      </div>
    </ModalWithAnimation>
  );
};

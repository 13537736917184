import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Expo, gsap } from 'gsap';
import React, { useEffect, useState } from 'react';

import { useDeleteLink } from 'components/App/hooks/useDeleteLink';
import Loader from 'components/Loader/Loader';
import { receiveUserLinks } from 'data/actions/UserActions';
import { useTypedDispatch, useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { Link } from 'types/Link';

import './Tool.css';

type Props = {
  shouldShowDeleteButton?: boolean;
  onDelete?: () => void;
  userLink: Link;
  onClick: (label: string) => void;
};

export const LinkItem = ({ shouldShowDeleteButton = false, userLink, onClick }: Props): JSX.Element => {
  const linkUrl = userLink.url;

  const [tile, setTile] = useState<HTMLDivElement | null>(null);

  const tileImage = <span className="initial-icon">{userLink.title.charAt(0)}</span>;

  const onMouseEnter = (): void => {
    if (!tile) {
      return;
    }

    gsap.to(tile, {
      duration: 0.5,
      scale: 0.9,
      ease: Expo.easeOut,
    });
  };

  const onMouseLeave = (): void => {
    if (!tile) {
      return;
    }
    gsap.to(tile, {
      duration: 0.5,
      scale: 1,
      ease: Expo.easeOut,
    });
  };

  const { loading, deleteLink, isSuccess } = useDeleteLink(userLink);
  const user = useTypedSelector(selectUser);
  const dispatch = useTypedDispatch();
  useEffect(() => {
    if (isSuccess) {
      const userLinks = user.links.filter(link => link['@id'] !== userLink['@id']);
      dispatch(receiveUserLinks(userLinks));
    }
  }, [dispatch, isSuccess, user.links, userLink]);

  return (
    <div className={'tool-container'}>
      <div className={loading ? 'loading-tool' : ''}>
        <a
          id={userLink['@id']}
          href={linkUrl}
          className={`tool ${shouldShowDeleteButton && 'disabled-tool'}`}
          target="_blank"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={_ => onClick(userLink.title)}
          rel="noreferrer"
        >
          <div ref={setTile} className="tool-tile link">
            {tileImage}
          </div>

          <div className="tool-details">
            <p className="tool-title">{userLink.title}</p>
            <p className="tool-subtitle">{userLink.subtitle}</p>
          </div>
        </a>
        {shouldShowDeleteButton && (
          <button className="tools-delete-button" onClick={deleteLink} type="button" data-testid="delete-link-button">
            <div className="tools-delete-button-content">
              <FontAwesomeIcon icon={['fas', 'xmark']} />
            </div>
          </button>
        )}
      </div>
      {loading && (
        <div className="tool-loader">
          <Loader />
        </div>
      )}
    </div>
  );
};

import React from 'react';

import './ContactCommunicationInfo.css';

interface Props {
  text: string;
  icon: JSX.Element;
}

export const ContactCommunicationInfo: React.FC<Props> = ({ text, icon }) => (
  <div className="contact-communication-info">
    <div className="contact-communication-info-icon">{icon}</div>
    <p className="contact-communication-info-text">{text}</p>
  </div>
);

import { LocalStorage } from 'types/LocalStorage';
import { isUserCountryPublicationScope, UserCountryPublicationScope } from 'types/PublicationScope';

export const setLocalStorageItem = (
  key: keyof LocalStorage,
  value: NonNullable<LocalStorage[keyof LocalStorage]>,
): void => {
  if (typeof value === 'string') {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getLocalStorageItem = (key: keyof LocalStorage): string | null => localStorage.getItem(key);

export const removeLocalStorageItem = (key: keyof LocalStorage): void => localStorage.removeItem(key);

export const getPublicationScopeFromLocalStorage = (): UserCountryPublicationScope | null => {
  const stringifiedScope = getLocalStorageItem('frontOfficeLocalScope');
  if (stringifiedScope === null) {
    return null;
  }
  const potentialPublicationScope: unknown = JSON.parse(stringifiedScope);

  if (isUserCountryPublicationScope(potentialPublicationScope)) {
    return potentialPublicationScope;
  }

  return null;
};

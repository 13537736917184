import { combineReducers } from 'redux';

import { TOOL_LOAD_END, TOOL_LOAD_START } from 'data/actions/UIActions';

// eslint-disable-next-line no-restricted-imports -- FIXME
import { START_ADD_TOOL } from '../actions/ToolActions';
// eslint-disable-next-line no-restricted-imports -- FIXME
import { RECEIVE_USER_TOOLS_ASYNC } from '../actions/UserActions';

const deftaultToolUiState = {
  loading: true,
  showAddModal: false,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
function userUiReducer(state = { loading: false }, action) {
  switch (action.type) {
    case START_ADD_TOOL:
      return Object.assign({}, state, { loading: true });
    case RECEIVE_USER_TOOLS_ASYNC:
      return Object.assign({}, state, { loading: false });
    default:
      return state;
  }
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
function toolsUiReducer(state = deftaultToolUiState, action) {
  let newState;

  switch (action.type) {
    case TOOL_LOAD_START:
      newState = Object.assign({}, state);
      newState.loading = true;
      break;
    case TOOL_LOAD_END:
      newState = Object.assign({}, state);
      newState.loading = false;
      break;
    default:
      newState = Object.assign({}, state);
      break;
  }

  return newState;
}

export default combineReducers({
  tools: toolsUiReducer,
  user: userUiReducer,
});

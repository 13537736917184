import React from 'react';

import { BlackCrossButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { ToolkitBody } from 'components/Toolkit/ToolkitBody';

import './ToolkitModal.css';

interface ToolkitModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ToolkitModal: React.FC<ToolkitModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="toolkit-modal-container">
        <div className="toolkit-modal-header">
          <span className="toolkit-modal-title">{t('admin.tools.title_toolbox')}</span>
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="toolkit-modal-content">
          <ToolkitBody />
        </div>
      </div>
    </ModalWithAnimation>
  );
};

export const TOOL_LOAD_START = 'TOOL_LOAD_START';
export const TOOL_LOAD_END = 'TOOL_LOAD_END';

export const LOAD_TOOL_COMPAGNY_START = 'LOAD_TOOL_COMPAGNY_START';
export const LOAD_TOOL_COMPAGNY_END = 'LOAD_TOOL_COMPAGNY_END';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function toolLoadStart() {
  return {
    type: TOOL_LOAD_START,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function toolLoadEnd() {
  return {
    type: TOOL_LOAD_END,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function loadToolAdminStart() {
  return {
    type: LOAD_TOOL_COMPAGNY_START,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function loadToolAdminEnd() {
  return {
    type: LOAD_TOOL_COMPAGNY_END,
  };
}

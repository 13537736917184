// eslint-disable-next-line no-restricted-imports -- FIXME
import { RECEIVE_COMPANY_LIST } from '../actions/CompanyListActions';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function companyListReducer(state = [], action) {
  let newState;

  switch (action.type) {
    case RECEIVE_COMPANY_LIST:
      newState = action.companyLists;
      break;

    default:
      newState = state ? Object.assign([], state) : null;
      break;
  }

  return newState;
}

import { RECEIVE_LANGUAGE } from 'data/actions/LanguageActions';
import { Language } from 'types/Language';

type LanguageAction = { type: typeof RECEIVE_LANGUAGE; languages: Language[] };

export const languageReducer = (state = [], action: LanguageAction): Language[] => {
  let newState;

  switch (action.type) {
    case RECEIVE_LANGUAGE:
      newState = action.languages;
      break;

    default:
      newState = state ? Object.assign([], state) : [];
      break;
  }

  return newState;
};

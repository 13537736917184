import { Thumbnail } from 'components/App/FrontOffice/Thumbnail/Thumbnail';
import { LikeCount } from 'components/UI/LikeCount/LikeCount';
import { ViewCount } from 'components/ViewCount/ViewCount';
import { HTML_TAGS_EXCEPT_P } from 'utils/constants';
import { useDisplayLongDate } from 'utils/date';
import { sanitizeContentForDisplay } from 'utils/sanitize';

import './PreviewNewsWithSameThemes.css';

interface PreviewNewsWithSameThemesProps {
  title: string;
  date: string;
  content: string;
  thumbnail: string;
  likeCount: number;
  viewCount: number;
}

export const PreviewNewsWithSameThemes: React.FC<PreviewNewsWithSameThemesProps> = ({
  thumbnail,
  title,
  content,
  date,
  likeCount,
  viewCount,
}) => (
  <div className="news-preview-element-container">
    <div className="news-preview-thumbnail-container">
      <Thumbnail src={thumbnail} alt={'news item thumbnail'} />
    </div>
    <div className="news-preview-element-news">
      <p className="news-preview-element-title">{title}</p>
      <div className="news-preview-element-content-and-date">
        <p
          className="news-preview-element-content"
          dangerouslySetInnerHTML={{
            __html: sanitizeContentForDisplay(content).replace(HTML_TAGS_EXCEPT_P, ''),
          }}
        />

        <div className="news-preview-element-date-and-stats">
          <p className="news-preview-element-date">{useDisplayLongDate(date)}</p>
          <div className="news-box-element-stats">
            {viewCount > 0 && <ViewCount count={viewCount} />}
            {likeCount > 0 && <LikeCount likeCount={likeCount} />}
          </div>
        </div>
      </div>
    </div>
  </div>
);

import get from 'lodash/get';

// eslint-disable-next-line no-restricted-imports -- FIXME
import { RECEIVE_METEO } from '../actions/MeteoActions';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function meteoReducer(state = { forecasts: [], geocode: [] }, action) {
  switch (action.type) {
    case RECEIVE_METEO:
      // eslint-disable-next-line no-case-declarations
      const forecasts = get(action.meteo, 'forecasts', []);
      // eslint-disable-next-line no-case-declarations
      const geocode = get(action.meteo, 'geocode', []);

      return Object.assign({}, state, { forecasts, geocode });
    default:
      return state;
  }
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Autoplay, Navigation, Pagination, Swiper as SwiperType } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import useClickTracking from 'analytics/useClickTracking';
import { getFrontOfficePath } from 'components/App/utils';
import { HeadlineNewsItem } from 'components/UI/HeadlineNews/HeadlineNewsItem';
import { FrontOfficeRoutes } from 'router/routes';
import { HeadlineInternalNews } from 'types/HeadlineInternalNews';
import { ANALYTICS_LANGUAGE, CodeIso } from 'types/Language';
import { displayLongDate } from 'utils/date';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';

import 'swiper/swiper.min.css';
import './DesktopSwiper.css';
import './SwiperPagination.css';

interface Props {
  headlinesNews: HeadlineInternalNews[];
  selectedLanguage: CodeIso;
}

export const DesktopSwiper: React.FC<Props> = ({ headlinesNews, selectedLanguage }) => {
  const swiperRef = useRef<SwiperType>();

  const hasHeadlineNewsMoreThanOneElement = useMemo(() => headlinesNews.length > 1, [headlinesNews]);

  const { clickHeadlineNewsTracking, clickHeadlineNewsSwiperArrowTracking } = useClickTracking();

  const handleNextSwiperButtonClick = useCallback(() => {
    swiperRef.current?.slideNext();
    clickHeadlineNewsSwiperArrowTracking('next');
  }, [clickHeadlineNewsSwiperArrowTracking]);

  const handlePreviousSwiperButtonClick = useCallback(() => {
    swiperRef.current?.slidePrev();
    clickHeadlineNewsSwiperArrowTracking('previous');
  }, [clickHeadlineNewsSwiperArrowTracking]);

  return (
    <div className="desktop-headline-news">
      <Swiper
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={16}
        loop={hasHeadlineNewsMoreThanOneElement}
        autoplay={hasHeadlineNewsMoreThanOneElement && { delay: 5000 }}
        onBeforeInit={swiper => {
          swiperRef.current = swiper;
        }}
        modules={[Autoplay, Navigation, Pagination]}
      >
        {headlinesNews?.map((headlineNews: HeadlineInternalNews) => {
          const headlineTranslation = getSelectedTranslation(headlineNews.translations, selectedLanguage);
          const headlineNewsTranslationInAnalyticsLanguage = getSelectedTranslation(
            headlineNews.translations,
            ANALYTICS_LANGUAGE,
          );

          if (headlineTranslation === undefined || headlineNewsTranslationInAnalyticsLanguage === undefined) {
            return null;
          }
          const headlineNewsPublicationDate = displayLongDate(headlineNews.publishedAt, selectedLanguage);

          return (
            <SwiperSlide key={headlineNews.id}>
              <NavLink
                to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${headlineNews.id}`)}
                onClick={_ => clickHeadlineNewsTracking(headlineNewsTranslationInAnalyticsLanguage.title)}
              >
                <HeadlineNewsItem
                  title={headlineTranslation?.title ?? ''}
                  content={headlineTranslation?.text ?? ''}
                  thumbnail={
                    selectedLanguage === 'FRA' ? headlineNews.facultativeThumbnail : headlineNews.mainThumbnail
                  }
                  date={headlineNewsPublicationDate}
                  viewCount={headlineNews.viewCount}
                  likeCount={headlineNews.likeCount}
                  isUnique={headlinesNews.length === 1}
                />
              </NavLink>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {hasHeadlineNewsMoreThanOneElement && (
        <div className="next-swiper-button swiper-button" onClick={handleNextSwiperButtonClick}>
          <div className="swiper-button-content">
            <FontAwesomeIcon icon={['fas', 'chevron-right']} />
          </div>
        </div>
      )}
      {hasHeadlineNewsMoreThanOneElement && (
        <div className="previous-swiper-button swiper-button" onClick={handlePreviousSwiperButtonClick}>
          <div className="swiper-button-content">
            <FontAwesomeIcon icon={['fas', 'chevron-left']} />
          </div>
        </div>
      )}
    </div>
  );
};

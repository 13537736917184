// eslint-disable-next-line no-restricted-imports -- FIXME
import { RECEIVE_NOTIFICATION_APPLICATIONS } from '../actions/NotificationApplicationActions';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export default function notificationApplicationsReducer(state = [], action) {
  let newState;

  switch (action.type) {
    case RECEIVE_NOTIFICATION_APPLICATIONS:
      newState = action.applications;
      break;

    default:
      newState = state.concat();
      break;
  }

  return newState;
}

import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';

import { ErrorBlockWithReload } from './ErrorBlockWithReload';

export const NewsBlockError: React.FC = () => {
  const { t } = useTranslation();

  return <ErrorBlockWithReload message={t('news.display_error')} />;
};

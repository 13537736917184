import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';

import { ErrorBlockWithReload } from './ErrorBlockWithReload';

export const PublicationScopeBlockError: React.FC = () => {
  const { t } = useTranslation();

  return <ErrorBlockWithReload message={t('publication_scope.fetch_error')} />;
};

import React from 'react';

import { Snackbar } from './Snackbar';

import './Snackbar.css';

interface Props {
  text: string;
  buttonText: string;
  onClick: () => void;
}
export const InfoSnackbar: React.FC<Props> = ({ text, buttonText, onClick }) => (
  <Snackbar text={text} buttonText={buttonText} onClick={onClick} type="info" />
);

import React from 'react';

import { Thumbnail } from 'components/App/FrontOffice/Thumbnail/Thumbnail';
import { LikeCount } from 'components/UI/LikeCount/LikeCount';
import { ViewCount } from 'components/ViewCount/ViewCount';
import { HTML_TAGS_EXCEPT_P_AND_BR } from 'utils/constants';
import { removeEmptyLines, sanitizeContentForDisplay } from 'utils/sanitize';

import './HeadlineNewsItem.css';

interface Props {
  title: string;
  thumbnail: string;
  content: string;
  date: string;
  viewCount: number;
  likeCount: number;
  isUnique?: boolean;
}
export const HeadlineNewsItem: React.FC<Props> = ({
  title,
  thumbnail,
  content,
  date,
  viewCount,
  likeCount,
  isUnique = false,
}) => {
  const sanitizeContent = (contentCarousel: string): string => {
    return removeEmptyLines(sanitizeContentForDisplay(contentCarousel).replace(HTML_TAGS_EXCEPT_P_AND_BR, ''));
  };

  return (
    <div className={`headline-news-item ${isUnique ? 'unique-headline-news-item' : 'multiple-headline-news-item'}`}>
      <div className="headline-news-thumbnail-container">
        <Thumbnail src={thumbnail} alt={'thumbnail of the headline news'} />
      </div>
      <div className="headline-news-item-news">
        <p className="headline-news-item-title">{title}</p>
        <p
          className="headline-news-item-content"
          dangerouslySetInnerHTML={{
            __html: sanitizeContent(content),
          }}
        />

        <div className="headline-news-item-footer">
          <p className="headline-news-item-date">{date}</p>
          <div className="headline-news-item-stats">
            {viewCount > 0 && <ViewCount count={viewCount} />}
            {likeCount > 0 && <LikeCount likeCount={likeCount} />}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useRef } from 'react';

import useViewTracking from 'analytics/useViewTracking';
import { BlackCrossButton, SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { InternalNewsMediaType } from 'types/InternalNewsMediaType';

import './styles/NewsDetailsMediaModal.css';
import './styles/NewsDetailsMediaModalDesktop.css';
import './styles/NewsDetailsMediaModalMobile.css';

interface NewsDetailsMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  mediaUrl: string;
  mediaType: InternalNewsMediaType | null;
  disableTracking?: boolean;
}

export const NewsDetailsMediaModal: React.FC<NewsDetailsMediaModalProps> = ({
  isOpen,
  onClose,
  mediaUrl,
  mediaType,
  disableTracking = false,
}) => {
  const { t } = useTranslation();

  const { viewNewsMediaTracking, startViewMediaTracking, endViewMediaTracking, endViewNewsDetailTracking } =
    useViewTracking();

  const shouldSendEndInstructionForTrackingOnClose = useRef(false);

  useEffect(() => {
    if (!disableTracking && mediaType !== null) {
      if (shouldSendEndInstructionForTrackingOnClose.current) {
        endViewMediaTracking();
        shouldSendEndInstructionForTrackingOnClose.current = false;
      } else if (isOpen) {
        endViewNewsDetailTracking();
        startViewMediaTracking();
        viewNewsMediaTracking(mediaType);
        shouldSendEndInstructionForTrackingOnClose.current = true;
      }
    }
  }, [
    disableTracking,
    endViewMediaTracking,
    endViewNewsDetailTracking,
    isOpen,
    mediaType,
    startViewMediaTracking,
    viewNewsMediaTracking,
  ]);

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="news-details-media-modal-container">
        <div className="media-modal-header">
          <span className="media-modal-title">{t('frontoffice.news_details.modal.title')}</span>
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="media-modal-content">
          {mediaType === InternalNewsMediaType.PICTURE ? (
            <img src={mediaUrl} alt="Media Content" />
          ) : (
            <iframe
              title="media-embedded-viewer"
              src={mediaUrl}
              width="100%"
              height="100%"
              allow="fullscreen"
              className="media-modal-embedded-viewer"
              data-testid="media-modal-embedded-viewer"
            />
          )}
        </div>
        <div className="media-modal-footer">
          <SecondaryButton text={t('go_back_link')} onClick={onClose} />
        </div>
      </div>
    </ModalWithAnimation>
  );
};

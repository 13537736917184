export enum FrontOfficeRoutes {
  HOME = '/',
  NEWS_DETAILS = '/news/:id',
  NEWS_PREVIEW = '/news/:id/preview',
  ACCESSIBILITY = '/accessibility',
}

export enum BackOfficeRoutes {
  HOME = '/',
  ADD_THEMES = '/add-themes',
  THEMES = '/themes',
  NEWS = '/news',
  ADD_NEWS = '/add-news',
  EDIT_NEWS = '/news/:id',
  NEWSLETTERS = '/newsletters',
  ADD_NEWSLETTER = '/add-newsletter',
  TRANSLATIONS = '/translations',
  EDITORS = '/editors',
  ADD_EDITOR = '/add-editor',
  TOOLBOX = '/toolbox',
  NEWSLETTER_PREVIEW = '/newsletter/preview/:newsIds',
}

export enum AdminRoutes {
  HOME = '/',
  TOOLS = '/tools',
  ELIGIBILITY = '/eligibility',
  COMPANIES = '/companies',
}

export enum EligibilityRoutes {
  ELIGIBILITY = '/eligibility',
}

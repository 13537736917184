import { useCallback, useEffect, useState } from 'react';

import { PublicationScope, PublicationScopeType } from 'types/PublicationScope';

import { useFetchCollection } from './useFetchCollection';

interface UseFetchPublicationScopesParams {
  type?: PublicationScopeType;
  parentScopeId?: string;
  autoFetch?: boolean;
}

interface UseFetchPublicationScopesResult {
  isLoading: boolean;
  isError: boolean;
  publicationScopes: PublicationScope[] | null;
  fetchPublicationScopes: () => void;
  resetPublicationScopes: () => void;
}

export const useFetchPublicationScopes = ({
  type,
  parentScopeId,
  autoFetch = false,
}: UseFetchPublicationScopesParams): UseFetchPublicationScopesResult => {
  const [publicationScopes, setPublicationScopes] = useState<PublicationScope[] | null>(null);

  const resetPublicationScopes = useCallback(() => setPublicationScopes(null), []);

  const filters = new URLSearchParams();

  if (type) {
    filters.append('type', type);
  }

  if (parentScopeId) {
    filters.append('parentScope.id', parentScopeId);
  }

  const {
    loading: isLoading,
    isError,
    data: publicationScopesFromFetch,
    refetch: fetchPublicationScopes,
  } = useFetchCollection<PublicationScope>({
    url: `/api/publication_scopes?${filters.toString()}`,
    errorMessage: 'Failed to fetch publication scopes',
    autoFetch,
  });

  useEffect(() => setPublicationScopes(publicationScopesFromFetch), [publicationScopesFromFetch]);

  return { isLoading, isError, publicationScopes, fetchPublicationScopes, resetPublicationScopes };
};

import React from 'react';

import './ThemeFilterItem.css';

interface Props {
  title: string;
  isSelected: boolean;
  onClick: () => void;
}

export const ThemeFilterItem: React.FC<Props> = ({ title, isSelected, onClick }) => (
  <div className={`theme-filter-item-container ${isSelected ? 'selected-theme-filter-item' : ''}`} onClick={onClick}>
    <span className="theme-filter-item-content">{title}</span>
  </div>
);

import React, { useCallback } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import {
  NewsDetailsContent,
  NewsDetailsContentDisplayMode,
} from 'components/App/FrontOffice/NewsDetails/NewsDetailsContent';
import { useFetchNewsPreview } from 'components/App/hooks/useFetchNewsPreview';
import { getFrontOfficePath } from 'components/App/utils';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { NewsBlockError } from 'components/UI/Errors/NewsBlockError';
import { InfoSnackbar } from 'components/UI/Snackbar/InfoSnackbar';
import { useHasFetchedUser } from 'hooks/useHasFetchedUser';
import { useIsUserAdmin } from 'hooks/useIsUserAdmin';
import { useIsUserEditor } from 'hooks/useIsUserEditor';
import { isCodeIso } from 'utils/typeguard';

import 'components/App/FrontOffice/NewsDetails/styles/NewsDetails.css';

interface NewsPreviewUrlParams {
  id: string;
}

const DEFAULT_LANGUAGE_TO_DISPLAY = 'ENG';

export const NewsPreview: React.FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const isUserAdmin = useIsUserAdmin();
  const isUserEditor = useIsUserEditor();
  const hasFetchedUser = useHasFetchedUser();

  const { id: newsId } = useParams<NewsPreviewUrlParams>();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const queryLanguage = searchParams.get('language');
  const language = isCodeIso(queryLanguage) ? queryLanguage : DEFAULT_LANGUAGE_TO_DISPLAY;

  const handleFetchError = useCallback(
    (rejectionError: Record<string, unknown>): void => {
      if (404 === rejectionError.code) {
        history.push(getFrontOfficePath());
      }
    },
    [history],
  );

  const { newsPreview, isLoading, isError } = useFetchNewsPreview(newsId, language, handleFetchError);

  if (hasFetchedUser && !(isUserAdmin || isUserEditor)) {
    return <Redirect to={getFrontOfficePath()} />;
  }

  return (
    <div className="news-details-container">
      {isLoading || !hasFetchedUser ? (
        <div className="news-details-full-height-container">
          <div className="news-details-loader">
            <Loader />
          </div>
        </div>
      ) : isError ? (
        <div className="news-details-full-height-container">
          <NewsBlockError />
        </div>
      ) : (
        <NewsDetailsContent
          news={newsPreview}
          language={language}
          displayMode={NewsDetailsContentDisplayMode.PREVIEW}
        />
      )}
      <InfoSnackbar
        text={t('preview.snackbar.message')}
        buttonText={t('preview.snackbar.button')}
        onClick={() => window.location.reload()}
      />
    </div>
  );
};

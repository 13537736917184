import { datadogLogs } from '@datadog/browser-logs';

import Config from 'Config';

import { LoggerInterface } from './LoggerInterface';

export class DatadogLogger implements LoggerInterface {
  init(): void {
    datadogLogs.init({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      clientToken: Config.datadog.clientToken,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      site: Config.datadog.site,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      service: Config.datadog.service,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME
      env: Config.datadog.env,
    });
  }

  info(message: string, details?: Record<string, unknown>): void {
    datadogLogs.logger.info(message, { details });
  }

  warn(message: string, details?: Record<string, unknown>): void {
    datadogLogs.logger.warn(message, { details });
  }

  error(message: string, details?: Record<string, unknown>): void {
    datadogLogs.logger.error(message, { details });
  }
}

import { Link } from './Link';
import { Tool } from './Tool';

export type ToolElement =
  | {
      type: ToolElementType.INDISPENSABLE_TOOL;
      element: Tool;
    }
  | { type: ToolElementType.USER_TOOL; element: Tool }
  | { type: ToolElementType.USER_LINK; element: Link };

export enum ToolElementType {
  INDISPENSABLE_TOOL = 'INDISPENSABLE_TOOL',
  USER_TOOL = 'USER_TOOL',
  USER_LINK = 'USER_LINK',
}

import React from 'react';

import './AddAppModalTab.css';

interface Props {
  isSelected: boolean;
  text: string;
  onClick: () => void;
}

export const AddAppModalTab: React.FC<Props> = ({ isSelected, text, onClick }) => (
  <div
    className={`add-app-modal-tab-container ${isSelected ? 'add-app-modal-tab-container-selected' : ''}`}
    onClick={onClick}
  >
    <p>{text}</p>
  </div>
);

export enum Category {
  ALL_NEWS = 'all-news',
  HEADLINE_NEWS = 'headline-news',
  MY_APPS = 'my-apps',
  ALL_APPS = 'all-apps',
  ADD_APPS = 'add-apps',
  NEWS = 'news',
  MEDIA = 'media',
  CONTACT = 'contact',
  TOOLKIT = 'toolkit',
  BOUYGUES_LINK = 'bouygues-links',
  THEME_FILTER = 'theme-filter',
  WEATHER = 'weather',
  LANGUAGE = 'language',
  SEARCH_NEWS = 'search-news',
  NEWS_TRANSLATION = 'news-translation',
  HOME = 'home',
  SHARE_NEWS = 'share-news',
  BACK_OFFICE_NEWS = 'back-office-news',
  NEWS_THEMES = 'news-themes',
}

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'usehooks-ts';

import useClickTracking from 'analytics/useClickTracking';
import useScrollTracking from 'analytics/useScrollTracking';
import useViewTracking from 'analytics/useViewTracking';
import { BouyguesLinks } from 'components/App/BouyguesLinks/BouyguesLinks';
import { SearchNewsModal } from 'components/App/FrontOffice/Home/SearchNewsModal/SearchNewsModal';
import { SecondaryButton } from 'components/CustomButton/CustomButton';
import { GlobalHeadlineNews } from 'components/GlobalHeadlineNews/GlobalHeadlineNews';
import { LocalHeadlineNews } from 'components/LocalHeadlineNews/LocalHeadlineNews';
import { useTranslation } from 'components/Localization/Localisation';
import { AllNewsBox } from 'components/UI/AllNewsBox/AllNewsBox';
import { Title } from 'components/UI/Title/Title';
import { isFeatureFlagEnabled } from 'helpers/isFeatureFlagEnabled';
import { useOnUnmount } from 'hooks/useOnUnmount';

import './Home.css';

export const Home: React.FC = () => {
  const { t } = useTranslation();

  const MAX_SCROLL_HEIGHT = document.documentElement.scrollHeight - document.documentElement.clientHeight;

  const { scrollNewsBlockTracking } = useScrollTracking();
  const { clickSearchNewsButtonTracking } = useClickTracking();
  const [shouldOpenSearchNewsModal, setShouldOpenSearchNewsModal] = useState(false);

  const [maxScrollPercentage, setMaxScrollPercentage] = useState(0);

  const { viewHomePageTracking, startHomePageTracking, endHomePageTracking } = useViewTracking();

  useEffect(() => {
    startHomePageTracking();
    viewHomePageTracking();
  }, [startHomePageTracking, viewHomePageTracking]);

  useOnUnmount(endHomePageTracking);

  //we need to get the size of the browser to catch resize
  useWindowSize();

  const getNormalizedPercentage = useCallback((maxScrollPercentage_: number, maxScrollHeight: number): number => {
    const currentPosition = window.scrollY;
    const currentPercentage = Math.round((100 * currentPosition) / maxScrollHeight);

    return isFinite(currentPercentage) ? Math.min(Math.max(currentPercentage, maxScrollPercentage_), 100) : 0;
  }, []);

  const handleScroll = useCallback((): void => {
    const currentNormalizedPercentage = getNormalizedPercentage(maxScrollPercentage, MAX_SCROLL_HEIGHT);
    currentNormalizedPercentage > maxScrollPercentage && setMaxScrollPercentage(currentNormalizedPercentage);
  }, [MAX_SCROLL_HEIGHT, getNormalizedPercentage, maxScrollPercentage]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useOnUnmount(() => scrollNewsBlockTracking(maxScrollPercentage));

  return (
    <div className="front-office-home">
      <div className="header">
        <Title title={t('frontoffice.news')} />
        <SecondaryButton
          text={t('frontoffice.news.search')}
          image={
            <span className="search-icon">
              <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
            </span>
          }
          positionOfImage="right"
          className="search-button"
          onClick={() => {
            setShouldOpenSearchNewsModal(true);
            clickSearchNewsButtonTracking();
          }}
        />
      </div>
      <div className="content">
        <GlobalHeadlineNews />
        {isFeatureFlagEnabled('localFO') && <LocalHeadlineNews />}
        <AllNewsBox />
        <div className="mobile-bouygues-links">
          <BouyguesLinks />
        </div>
      </div>
      <SearchNewsModal isOpen={shouldOpenSearchNewsModal} onClose={() => setShouldOpenSearchNewsModal(false)} />
    </div>
  );
};

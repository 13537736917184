import { InternalNewsTranslation } from 'types/InternalNews';
import { InternalNewsDetailsThemeTranslation } from 'types/InternalNewsDetails';
import { InternalNewsPreviewThemeTranslation } from 'types/InternalNewsPreview';
import { CodeIso } from 'types/Language';
import { Theme } from 'types/Theme';
import { Translation } from 'types/Translation';

export const findThemesByLanguage = (codeIso: CodeIso | string | undefined, themes: Theme[]): string[] =>
  themes.reduce((themeTexts: string[], currentTheme: Theme) => {
    const themeTranslation = currentTheme.translations.find(translation => translation.language?.codeIso === codeIso);
    if (themeTranslation === undefined) {
      return themeTexts;
    }

    return [...themeTexts, themeTranslation?.text];
  }, []);

export const getSelectedTranslation = <
  K extends
    | Translation
    | InternalNewsTranslation
    | InternalNewsDetailsThemeTranslation
    | InternalNewsPreviewThemeTranslation,
>(
  translations: K[] | undefined,
  selectedLanguage: CodeIso,
): K | undefined => {
  return translations?.find(translation => translation.language.codeIso === selectedLanguage);
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fade } from '@mui/material';
import React from 'react';

import './PublicationScopeSelectorModalButton.css';

interface PublicationScopeSelectorModalButtonProps {
  label: string;
  condensedMode?: boolean;
  onClick?: () => void;
}

export const PublicationScopeSelectorModalButton: React.FC<PublicationScopeSelectorModalButtonProps> = ({
  label,
  condensedMode = false,
  onClick,
}) => (
  <div
    className="publication-scope-selector-modal-button-container"
    data-testid="publication-scope-selector-modal-button"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={['fas', 'earth']} className="selector-icon" />
    <Fade in={!condensedMode} timeout={{ enter: 400, exit: 0 }} easing={'ease-in-out'} mountOnEnter unmountOnExit>
      <div className="details-block">
        <span>{label}</span>
        <FontAwesomeIcon icon={['fas', 'caret-down']} />
      </div>
    </Fade>
  </div>
);

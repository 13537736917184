type Props = {
  setFakeTiles: (value: React.SetStateAction<unknown[]>) => void;
  numberOfTiles: number;
};

export const TilesSkeleton: React.FC<Props> = ({ setFakeTiles, numberOfTiles }) => {
  return (
    <div className="frontoffice-tool-list empty">
      {Array(numberOfTiles)
        .fill(0)
        .map((v, i) => (
          <div ref={el => el && setFakeTiles(current => [...current, el])} key={i} className="tool placeholder">
            <div className="tool-tile" />
            <div className="tool-details">
              <p className="tool-title">&nbsp;</p>
              <p className="tool-subtitle">&nbsp;</p>
            </div>
          </div>
        ))}
    </div>
  );
};

import React, { ComponentType, createContext, useContext, useEffect, useState } from 'react';

import { CodeIso, DEFAULT_USER_LANGUAGE } from 'types/Language';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';
import { isCodeIso } from 'utils/typeguard';

interface UserLanguageContextProps {
  userLanguage: CodeIso;
  setUserLanguage: (language: CodeIso) => void;
  hasUserLanguageBeenSet: boolean;
}

const defaultSetUserLanguage = (_: string): void => {
  return;
};

export const UserLanguageContext = createContext<UserLanguageContextProps>({
  userLanguage: DEFAULT_USER_LANGUAGE,
  setUserLanguage: defaultSetUserLanguage,
  hasUserLanguageBeenSet: false,
});

export const withUserLanguageContext =
  (WrappedComponent: ComponentType): ComponentType =>
  (props: object) => {
    const languageStored = getLocalStorageItem('lang');
    const [userLanguage, setUserLanguage] = useState<CodeIso | null>(isCodeIso(languageStored) ? languageStored : null);

    useEffect(() => {
      if (userLanguage) {
        setLocalStorageItem('lang', userLanguage);
      } else {
        removeLocalStorageItem('lang');
      }
    }, [userLanguage]);

    return (
      <UserLanguageContext.Provider
        value={{
          userLanguage: userLanguage ?? DEFAULT_USER_LANGUAGE,
          setUserLanguage,
          hasUserLanguageBeenSet: !!userLanguage,
        }}
      >
        <WrappedComponent {...props} />
      </UserLanguageContext.Provider>
    );
  };

export const useUserLanguageContext = (): UserLanguageContextProps => useContext(UserLanguageContext);

import React, { useCallback, useState } from 'react';

import { FrontOfficeLocalScopeSelectorModal } from 'components/FrontOfficeLocalScopeSelectorModal/FrontOfficeLocalScopeSelectorModal';
import { useTranslation } from 'components/Localization/Localisation';
import { HeadlineNews } from 'components/UI/HeadlineNews/HeadlineNews';
import { PublicationScopeSelectorModalButton } from 'components/UI/PublicationScopeSelectorModalButton/PublicationScopeSelectorModalButton';
import { Title } from 'components/UI/Title/Title';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { useCountryPublicationScopeDisplayNameWithActivityInLanguage } from 'utils/publicationScopesDisplayNameGetter';

import './LocalHeadlineNews.css';

export const LocalHeadlineNews: React.FC = () => {
  const { t } = useTranslation();
  const { frontOfficeLocalScope } = useFrontOfficeLocalScopeContext();

  const [shouldShowModalToSelectLocalScope, setShouldShowModalToSelectLocalScope] = useState(false);

  const showModalToSelectLocalScope = useCallback(() => setShouldShowModalToSelectLocalScope(true), []);
  const hideModalToSelectLocalScope = useCallback(() => setShouldShowModalToSelectLocalScope(false), []);

  const frontOfficeLocalScopeDisplayName =
    useCountryPublicationScopeDisplayNameWithActivityInLanguage(frontOfficeLocalScope);

  return (
    <>
      <HeadlineNews
        titleBlock={
          <div className="local-headline-news-title-block-container">
            <Title title={t('news.local_headline_news')} size="small" />
            {frontOfficeLocalScope && (
              <PublicationScopeSelectorModalButton
                label={frontOfficeLocalScopeDisplayName}
                onClick={showModalToSelectLocalScope}
              />
            )}
          </div>
        }
        publicationScopeId={frontOfficeLocalScope?.id ?? null}
        onClickOnWorldMap={showModalToSelectLocalScope}
      />
      <FrontOfficeLocalScopeSelectorModal
        isOpen={shouldShowModalToSelectLocalScope}
        onClose={hideModalToSelectLocalScope}
      />
    </>
  );
};

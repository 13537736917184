import fetchApi from 'data/fetchApi';

export const RECEIVE_COMPANY_LIST = 'RECEIVE_COMPANY_LIST';
export const COMPANY_LIST_LOAD_START = 'COMPANY_LIST_LOAD_START';
export const COMPANY_LIST_LOAD_END = 'COMPANY_LIST_LOAD_START';
export const COMPANY_LIST_UPDATE = 'COMPANY_LIST_UPDATE';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function companyListLoadStart() {
  return {
    type: COMPANY_LIST_LOAD_START,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function companyListLoadEnd() {
  return {
    type: COMPANY_LIST_LOAD_END,
  };
}

// Synchronous actions
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveCompanyList(companyLists = []) {
  return {
    type: RECEIVE_COMPANY_LIST,
    companyLists: companyLists,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function companyListUpdate(data) {
  return {
    type: COMPANY_LIST_UPDATE,
    companyList: data,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestFetchCompanyList() {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(companyListLoadStart());
    dispatch(receiveCompanyList([]));

    return fetchApi('/api/company_lists').then(responseJson => {
      const companyList = responseJson['hydra:member'];

      dispatch(receiveCompanyList(companyList));
      dispatch(companyListLoadEnd());
    });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function associateCompanyLanguage(data) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(companyListLoadStart());

    return fetchApi(data['@id'], {
      method: 'PUT',
      body: JSON.stringify(data['body']),
    });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function makeCompanyEligible(data) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(companyListLoadStart());

    return fetchApi('/api/companyList/update', {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  };
}

import { useCallback } from 'react';

import useMatomo from './useMatomo';

const useScrollTracking = (): { scrollNewsBlockTracking: (percentage: number) => void } => {
  const { trackEvent } = useMatomo();

  const scrollNewsBlockTracking = useCallback(
    (percentage: number): void => {
      trackEvent({ category: 'news', action: 'Scroll block', name: percentage.toString() });
    },
    [trackEvent],
  );

  return { scrollNewsBlockTracking };
};

export default useScrollTracking;

import NoHeadlineNewsIcon from 'images/icons/no-headline.svg';

import './NoNewsAvailable.css';

interface Props {
  text: string;
}

export const NoNewsAvailable: React.FC<Props> = ({ text }) => (
  <div className="no-news-available-container">
    <div className="file-icon">
      <img src={NoHeadlineNewsIcon} alt="A Sheet of Paper" />
    </div>
    {text}
  </div>
);

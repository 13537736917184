import React from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { useTranslation } from 'components/Localization/Localisation';
import { ThemeFilterItem } from 'components/UI/ThemeFilterItem/ThemeFilterItem';
import { ANALYTICS_LANGUAGE, CodeIso } from 'types/Language';
import { ThemeTranslation } from 'types/ThemeTranslation';
import { getSelectedLanguageThemeTranslation } from 'utils/themeTranslation';
import { getThemeTranslationsByThemeId } from 'utils/themeTranslations';

import './ThemeFilter.css';

interface Props {
  themeTranslations: ThemeTranslation[] | null;
  selectedFilter: number | null;
  onSelectFilter: (themeId: number | null) => void;
  selectedLanguage: CodeIso;
}

export const ThemeFilter: React.FC<Props> = ({
  themeTranslations,
  selectedFilter,
  onSelectFilter,
  selectedLanguage,
}) => {
  const { t } = useTranslation();

  const { clickThemeFilterTracking } = useClickTracking();

  const themeTranslationsByThemeId: Record<string, ThemeTranslation[]> =
    getThemeTranslationsByThemeId(themeTranslations);

  return (
    <div className="theme-filter-container">
      <ThemeFilterItem title={t('all')} isSelected={selectedFilter === null} onClick={() => onSelectFilter(null)} />

      {Object.values(themeTranslationsByThemeId).map(translations => {
        const selectedLanguageThemeTranslation = getSelectedLanguageThemeTranslation(translations, selectedLanguage);
        const themeTranslationInAnalyticsLanguage = getSelectedLanguageThemeTranslation(
          translations,
          ANALYTICS_LANGUAGE,
        );

        if (selectedLanguageThemeTranslation === undefined || themeTranslationInAnalyticsLanguage === undefined) {
          return <></>;
        }

        return (
          <ThemeFilterItem
            title={selectedLanguageThemeTranslation.text}
            isSelected={selectedFilter === selectedLanguageThemeTranslation.theme.id}
            onClick={() => {
              onSelectFilter(selectedLanguageThemeTranslation.theme.id);
              clickThemeFilterTracking(themeTranslationInAnalyticsLanguage.text);
            }}
            key={selectedLanguageThemeTranslation.id}
          />
        );
      })}
    </div>
  );
};

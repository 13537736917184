import { configureStore } from '@reduxjs/toolkit';
import { applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { companyListReducer } from './reducers/companyListReducer';
import eligibilityReducer from './reducers/eligibilityReducer';
import errorReducer from './reducers/errorReducer';
import { languageReducer } from './reducers/languageReducer';
import { meteoReducer } from './reducers/meteoReducer';
import notificationApplicationsReducer from './reducers/notificationApplicationsReducer';
import { statusListReducer } from './reducers/statusListReducer';
import toolReducer from './reducers/toolReducer';
import uiReducer from './reducers/uiReducer';
import userReducer from './reducers/userReducer';

export const reducers = combineReducers({
  ui: uiReducer,
  user: userReducer,
  tools: toolReducer,
  companyLists: companyListReducer,
  languages: languageReducer,
  statusLists: statusListReducer,
  meteo: meteoReducer,
  notificationApplications: notificationApplicationsReducer,
  error: errorReducer,
  eligibility: eligibilityReducer,
});

const reduxMiddleware = applyMiddleware(thunkMiddleware);

export const store = configureStore({ reducer: reducers, enhancers: [compose(reduxMiddleware)] });

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { useCallback, useState } from 'react';

import { receiveUserTools } from 'data/actions/UserActions';
import fetchApi from 'data/fetchApi';
import { useTypedDispatch, useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { logger } from 'services/logs/logger';
import { Tool } from 'types/Tool';

export const useAddUserTool = (): { isLoading: boolean; doFetch: (tool: Tool) => void } => {
  const user = useTypedSelector(selectUser);
  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const doFetch = useCallback(
    (tool: Tool) => {
      setIsLoading(true);
      const userTools = user.tools === null ? [tool] : [...user.tools, tool];
      user.uuid &&
        fetchApi(`/api/users/${user.uuid}`, {
          method: 'PUT',
          body: JSON.stringify({ tools: userTools }),
        })
          .then(
            () => {
              dispatch(receiveUserTools(userTools));
            },
            (rejectionError: Record<string, unknown>) => {
              logger.error('Failed to add a user tool', rejectionError);
            },
          )
          .finally(() => {
            setIsLoading(false);
          });
    },

    [dispatch, user],
  );

  return { isLoading, doFetch };
};

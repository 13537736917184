import React, { useEffect } from 'react';
import svgUrl from 'images/icon-sprite.svg';

const IconSprite: React.FC = () => {
  useEffect(() => {
    const body = document.body;

    fetch(svgUrl)
      .then(response => response.text())
      .then(svgEl => {
        const svgInjection = document.createElement('svg');
        body.insertBefore(svgInjection, body.firstChild);
        svgInjection.outerHTML = svgEl;
      })
      .catch(error => {
        throw error;
      });
  }, []);

  return null;
};

export default IconSprite;

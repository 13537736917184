import sortBy from 'lodash/fp/sortBy';
import React, { useEffect, useMemo, useState } from 'react';

import { useFetchPublicationScopes } from 'components/App/hooks/useFetchPublicationScopes';
import { useFetchPublicationScopesWithDescendants } from 'components/App/hooks/useFetchPublicationScopesWithDescendants';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { PublicationScopeSelectorModalScopesList } from 'components/PublicationScopeSelectorModalScopesList/PublicationScopeSelectorModalScopesList';
import { PublicationScopeBlockError } from 'components/UI/Errors/PublicationScopeBlockError';
import {
  GLOBAL_PUBLICATION_SCOPE,
  GLOBAL_PUBLICATION_SCOPE_ID,
  PublicationScope,
  PublicationScopeType,
  PublicationScopeWithType,
} from 'types/PublicationScope';

import { PublicationScopeTab } from './PublicationScopeTab/PublicationScopeTab';

import './PublicationScopeSelectorByActivityModalContent.css';

interface PublicationScopeSelectorByActivityModalContentProps {
  onSelectScope: (newScope: PublicationScopeWithType, newRelatedActivity?: PublicationScope) => void;
  shouldIncludeGlobalScope?: boolean;
  scopeTypesToFilterOn?: PublicationScopeType[];
  initiallySelectedActivityId?: string;
  currentlySelectedScopeId?: string;
}

export const PublicationScopeSelectorByActivityModalContent: React.FC<
  PublicationScopeSelectorByActivityModalContentProps
> = ({
  onSelectScope,
  shouldIncludeGlobalScope = false,
  scopeTypesToFilterOn,
  initiallySelectedActivityId,
  currentlySelectedScopeId,
}) => {
  const { t } = useTranslation();

  const [selectedTabScope, setSelectedTabScope] = useState<PublicationScope | undefined>(undefined);
  const [availableScopes, setAvailableScopes] = useState<PublicationScopeWithType[]>([]);

  const {
    publicationScopes: activities,
    isLoading: isLoadingActivities,
    isError: areActivitiesInError,
  } = useFetchPublicationScopes({
    type: PublicationScopeType.ACTIVITY,
    parentScopeId: GLOBAL_PUBLICATION_SCOPE_ID,
    autoFetch: true,
  });

  const orderedActivities = useMemo(() => (activities ? sortBy(['id'], activities) : []), [activities]);

  const tabScopes = useMemo(
    () => [...(shouldIncludeGlobalScope ? [GLOBAL_PUBLICATION_SCOPE] : []), ...orderedActivities],
    [shouldIncludeGlobalScope, orderedActivities],
  );

  useEffect(() => {
    if (!selectedTabScope) {
      const initiallySelectedTabScope =
        tabScopes?.find(scope => scope.id === initiallySelectedActivityId) ?? tabScopes[0];
      setSelectedTabScope(initiallySelectedTabScope);
    }
  }, [selectedTabScope, tabScopes, initiallySelectedActivityId]);

  const {
    publicationScopesWithDescendants,
    isLoading: isLoadingPublicationScopes,
    isError: arePublicationScopesInError,
    fetchPublicationScopesWithDescendants,
  } = useFetchPublicationScopesWithDescendants({
    scopes: selectedTabScope ? [selectedTabScope] : [],
  });

  useEffect(() => {
    if (selectedTabScope === GLOBAL_PUBLICATION_SCOPE) {
      setAvailableScopes([GLOBAL_PUBLICATION_SCOPE]);
    } else {
      setAvailableScopes([]);
      if (selectedTabScope) {
        fetchPublicationScopesWithDescendants();
      }
    }
  }, [selectedTabScope, fetchPublicationScopesWithDescendants]);

  useEffect(() => {
    publicationScopesWithDescendants && setAvailableScopes(publicationScopesWithDescendants);
  }, [publicationScopesWithDescendants]);

  const availableScopesToDisplay = useMemo(
    () =>
      scopeTypesToFilterOn
        ? availableScopes.filter(scope => scopeTypesToFilterOn.includes(scope.type))
        : availableScopes,
    [availableScopes, scopeTypesToFilterOn],
  );

  return (
    <div className="publication-scope-selector-modal-by-activity-content-container">
      {isLoadingActivities ? (
        <div className="publication-scope-selector-modal-loader-block">
          <div className="publication-scope-selector-modal-loader-container">
            <Loader />
          </div>
        </div>
      ) : areActivitiesInError ? (
        <div className="publication-scope-selector-modal-error-block">
          <PublicationScopeBlockError />
        </div>
      ) : (
        <div className="publication-scope-selector-modal-content">
          <div className="publication-scope-selector-modal-scope-tabs">
            {tabScopes.map(scope => (
              <PublicationScopeTab
                scope={scope}
                currentlySelectedScope={selectedTabScope}
                onSelectScope={setSelectedTabScope}
                customLabel={
                  GLOBAL_PUBLICATION_SCOPE === scope
                    ? t('publication_scope_selector_modal.global_tab_label')
                    : undefined
                }
                key={scope.id}
              />
            ))}
          </div>
          <div className="publication-scope-selector-modal-scopes-list">
            {arePublicationScopesInError && selectedTabScope !== GLOBAL_PUBLICATION_SCOPE ? (
              <div className="publication-scope-selector-modal-error-block">
                <PublicationScopeBlockError />
              </div>
            ) : (
              <PublicationScopeSelectorModalScopesList
                scopes={availableScopesToDisplay}
                isLoading={isLoadingPublicationScopes}
                onSelectScope={newScope => onSelectScope(newScope, selectedTabScope)}
                currentlySelectedScopeId={currentlySelectedScopeId}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

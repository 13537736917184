import React from 'react';

import { TabToDisplay } from 'components/App/FrontOffice/FrontOffice';
import { useTranslation } from 'components/Localization/Localisation';
import { FrontOfficeHomeTab } from 'components/UI/FrontOfficeHomeTab/FrontOfficeHomeTab';
import { ReactComponent as Grid } from 'images/icons/grid.svg';
import { ReactComponent as NewsSheet } from 'images/icons/news_sheet.svg';

import './FrontOfficeHomeTabs.css';

interface Props {
  tabOnClick: (tabValueToSet: TabToDisplay) => void;
  tabToDisplay: TabToDisplay;
}

export const FrontOfficeHomeTabs: React.FC<Props> = ({ tabToDisplay, tabOnClick }) => {
  const { t } = useTranslation();

  return (
    <div className="frontoffice-home-tabs-container">
      <FrontOfficeHomeTab
        text={t('news_abbreviation')}
        isSelected={tabToDisplay === TabToDisplay.NEWS}
        icon={<NewsSheet />}
        onClick={() => tabOnClick(TabToDisplay.NEWS)}
      />
      <FrontOfficeHomeTab
        text={t('frontoffice.apps')}
        isSelected={tabToDisplay === TabToDisplay.APPS}
        icon={<Grid />}
        onClick={() => tabOnClick(TabToDisplay.APPS)}
      />
    </div>
  );
};

import { FormState } from 'react-hook-form';

import { useTranslation } from 'components/Localization/Localisation';
import { AddURLFormValues } from 'types/AddURLFormValues';

export const useGenerateURLFormError = (
  formState: FormState<AddURLFormValues>,
  field: keyof AddURLFormValues,
): string | undefined => {
  const { t } = useTranslation();

  const MISSING_FIELD = 'required';
  const BAD_PATTERN = 'pattern';

  const fieldError = formState.errors[field];

  switch (fieldError?.type) {
    case MISSING_FIELD:
      return t('required_field');

    case BAD_PATTERN:
      return fieldError?.message;

    default:
      return;
  }
};

import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import './HeaderLinkAndButton.css';

interface Props {
  to: string;
  className?: string;
  exact?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- FIXME
export const HeaderLink = ({ children, to, className, exact }: PropsWithChildren<Props>) => (
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- FIXME
  <NavLink to={to} className={`${className} header-link`} exact={exact}>
    <div className="header-link-content">{children}</div>
  </NavLink>
);

import { ToolboxLink } from 'types/ToolboxLink';

import { useFetchCollection } from './useFetchCollection';

interface useFetchPublicationScopeToolboxLinksReturnType {
  isLoading: boolean;
  toolboxLinks: ToolboxLink[] | null;
  refetch: () => void;
}

export const useFetchPublicationScopeToolboxLinks = (
  publicationScopeId: string,
  onError?: (rejectionError: Record<string, unknown>) => void,
): useFetchPublicationScopeToolboxLinksReturnType => {
  const { loading, data, refetch } = useFetchCollection<ToolboxLink>({
    url: `/api/publication_scopes/${publicationScopeId}/toolbox_links`,
    errorMessage: ` Failed to get toolbox links of publication scope with id: ${publicationScopeId}`,
    errorCallback: onError,
    autoFetch: false,
  });

  return { isLoading: loading, toolboxLinks: data, refetch };
};

/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
//TODO type fetchApi which currently returns any

import { cloneDeep } from 'lodash/fp';
import set from 'lodash/fp/set';
import { AnyAction, Dispatch } from 'redux';

import fetchApi from 'data/fetchApi';
import { Group, GroupWithTools } from 'types/Group';
import { Link } from 'types/Link';
import { Tool, ToolWithGroup } from 'types/Tool';
import { User } from 'types/User';

// Other actions
import { loadToolAdminEnd, loadToolAdminStart, toolLoadEnd, toolLoadStart } from './UIActions';
import { receiveUserLinks, receiveUserTools, updateUser } from './UserActions';

// Action types
export const RECEIVE_INDISPENSABLE_TOOLS = 'RECEIVE_INDISPENSABLE_TOOLS';
export const RECEIVE_INDISPENSABLE_TOOL_ADMIN = 'RECEIVE_INDISPENSABLE_TOOL_ADMIN';
export const RECEIVE_CUSTOM_TOOLS = 'RECEIVE_CUSTOM_TOOLS';

// Only indispensible tools are concered
export const CREATE_TOOL = 'CREATE_TOOL';
export const START_ADD_TOOL = 'START_ADD_TOOL';
export const UPDATE_TOOL = 'UPDATE_TOOL';
export const DELETE_TOOL = 'DELETE_TOOL';

export const RECEIVE_GROUPS = 'RECEIVE_GROUPS';
export const CREATE_GROUP = 'CREATE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

// Synchronous actions
export const receiveIndispensableTools = (
  tools: Tool[] = [],
): {
  type: string;
  tools: Tool[];
} => ({
  type: RECEIVE_INDISPENSABLE_TOOLS,
  tools: tools,
});

// Synchronous actions
export const receiveIndispensableToolAdmin = (
  indispensableAdmin: Tool[] = [],
): {
  type: string;
  indispensableAdmin: Tool[];
} => ({
  type: RECEIVE_INDISPENSABLE_TOOL_ADMIN,
  indispensableAdmin: indispensableAdmin,
});

export const receiveCustomTools = (
  tools: Tool[] = [],
): {
  type: string;
  tools: Tool[];
} => ({
  type: RECEIVE_CUSTOM_TOOLS,
  tools: tools,
});

export const startAddTool = (): {
  type: string;
} => ({
  type: START_ADD_TOOL,
});

export const createTool = (
  tool: Tool,
): {
  type: string;
  tool: Tool;
} => ({
  type: CREATE_TOOL,
  tool: tool,
});

export const updateTool = (
  tool: Tool,
): {
  type: string;
  tool: Tool;
} => ({
  type: UPDATE_TOOL,
  tool: tool,
});

export const deleteTool = (
  tool: Tool,
): {
  type: string;
  tool: Tool;
} => ({
  type: DELETE_TOOL,
  tool: tool,
});

export const receiveGroups = (
  groups: GroupWithTools[],
): {
  type: string;
  groups: GroupWithTools[];
} => ({
  type: RECEIVE_GROUPS,
  groups: groups,
});

export const createGroup = (
  group: GroupWithTools,
): {
  type: string;
  group: GroupWithTools;
} => ({
  type: CREATE_GROUP,
  group: group,
});

export const updateGroup = (
  group: GroupWithTools,
): {
  type: string;
  group: GroupWithTools;
} => ({
  type: UPDATE_GROUP,
  group: group,
});

export const deleteGroup = (
  group: Group,
): {
  type: string;
  group: Group;
} => ({
  type: DELETE_GROUP,
  group: group,
});

export const fetchExternalToolsForUser = (user: User, actionToDispatchOnError: AnyAction) => {
  return (dispatch: Dispatch) => {
    dispatch(toolLoadStart());

    Promise.all([
      fetchApi(`/api/users/me/authorized_custom_tools`),
      user.uuid && fetchApi(`/api/users/${user.uuid}/tools`),
      user.uuid && fetchApi(`/api/users/${user.uuid}/links`),
    ]).then(
      responses => {
        const [customTools, userTools, userLinks] = responses;

        // Receive complete list of custom tools. display some.
        dispatch(receiveCustomTools(customTools['hydra:member']));

        // Receive users added tools and custom links
        dispatch(receiveUserTools(userTools['hydra:member']));
        dispatch(receiveUserLinks(userLinks['hydra:member']));

        // Declare tool lod complete
        dispatch(toolLoadEnd());
      },
      _ => {
        // Display first error
        dispatch(actionToDispatchOnError);
      },
    );
  };
};

export const addToolToUser = (tool: ToolWithGroup) => (dispatch: Dispatch, getState: () => { user: User }) => {
  const { user } = getState();

  const userWithNewTools = cloneDeep(user);
  userWithNewTools.tools = userWithNewTools.tools.concat([tool]);

  dispatch(startAddTool());
  //@ts-expect-error TODO: explain why ts-expect-error is needed
  dispatch(updateUser(userWithNewTools));
};

export const removeToolFromUser = (
  tool: Tool,
): ((
  dispatch: Dispatch,
  getState: () => {
    user: User;
  },
) => Promise<ToolWithGroup>) => {
  const pathElement = tool['@id']?.split('/') ?? '';

  const uuid = pathElement[pathElement.length - 1];

  return (dispatch: Dispatch, getState: () => { user: User }) => {
    const { user } = getState();

    return fetchApi('/api/users/custom_tools/update', {
      method: 'PUT',
      body: JSON.stringify({ toolUuid: uuid }),
    }).then(responseJson => {
      let newTools = cloneDeep(user.tools);
      newTools = newTools.filter(toolI => toolI['@id'] !== tool['@id']);
      dispatch(receiveUserTools(newTools));

      return responseJson;
    });
  };
};

export const addLinkToUser = (link: Link) => (dispatch: Dispatch, getState: () => { user: User }) => {
  const { user } = getState();

  return fetchApi('/api/custom_links', {
    method: 'POST',
    body: JSON.stringify(link),
  }).then(responseJson => {
    // Add newly created link to user and save
    const userWithNewLinks = cloneDeep(user);
    userWithNewLinks.links = userWithNewLinks.links.concat([responseJson]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- FIXME
    //@ts-expect-error
    dispatch(updateUser(userWithNewLinks));

    return responseJson;
  });
};

export const removeLinkFromUser = (
  link: Link,
): ((
  dispatch: Dispatch,
  getState: () => {
    user: User;
  },
) => Promise<Link>) => {
  const pathElement = link['@id'].split('/');

  const uuid = pathElement[pathElement.length - 1];

  return (dispatch: Dispatch, getState: () => { user: User }) => {
    const { user } = getState();

    return fetchApi('/api/users/custom_links/update', {
      method: 'PUT',
      body: JSON.stringify({ linkUuid: uuid }),
    }).then(responseJson => {
      // Add newly created link to user and save
      let newLinks = cloneDeep(user.links);
      newLinks = newLinks.filter(linkI => linkI['@id'] !== link['@id']);
      dispatch(receiveUserLinks(newLinks));

      return responseJson;
    });
  };
};

export const requestFetchAdminTools = () => (dispatch: Dispatch) => {
  dispatch(loadToolAdminStart());

  return fetchApi('/api/admin/indispensable_tools/all').then(responseJson => {
    const tools = responseJson['hydra:member'];

    dispatch(receiveIndispensableToolAdmin(tools));
    dispatch(loadToolAdminEnd());
  });
};

export const requestFetchToolsCompany = (id: string): Promise<ToolWithGroup[]> => {
  const data = id.split('/');
  const uuid = data[data.length - 1];

  return new Promise(resolve => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/restrict-template-expressions -- FIXME
    fetchApi(`/api/admin/indispensable_tools/${uuid}`).then(responseJson => {
      let toolCompany = [];
      if (responseJson['hydra:member'][0]?.['companyList']) {
        toolCompany = responseJson['hydra:member'][0]['companyList'];
      }
      resolve(toolCompany);
    });
  });
};

export const requestCreateTool =
  (tool: ToolWithGroup) => (dispatch: Dispatch, getState: () => { tools: { indispensableAdmin: Tool[] } }) => {
    const serverTool = set('group', tool.group?.['@id'], tool);

    const tools = getState().tools.indispensableAdmin;

    // generate position for tool
    serverTool.position =
      tools.reduce((currentMax, currentTool) => Math.max(currentMax, currentTool?.position ?? 0), -1) + 1;

    // remove ID if present or creation will fail
    delete serverTool['@id'];

    return fetchApi('/api/indispensable_tools', {
      method: 'POST',
      body: JSON.stringify(serverTool),
    }).then(responseJson => {
      dispatch(createTool(responseJson));
      dispatch(toolLoadStart());
    }); // TODO handle error
  };

export const requestUpdateTool = (tool: ToolWithGroup) => (dispatch: Dispatch) => {
  // Optimisitc update
  dispatch(updateTool(tool));
  dispatch(toolLoadStart());

  // transform tool for server representation
  const serverTool = set('group', tool.group?.['@id'], tool);

  return fetchApi(tool['@id'], {
    method: 'PUT',
    body: JSON.stringify(serverTool),
  }).then(responseJson => {
    dispatch(updateTool(responseJson));
    dispatch(toolLoadStart());
  });
};

export const onToolUpdatePosition = (tool: Tool, swappedTool: Tool, type: string) => (dispatch: Dispatch) => {
  // Optimistic update
  dispatch(updateTool(tool));
  dispatch(updateTool(swappedTool));
  dispatch(toolLoadStart());

  return fetchApi(`${tool['@id'] ?? ''}/update/` + type, {
    method: 'PUT',
    body: JSON.stringify({}),
  }).catch(); // TODO handle error
};

export const requestDeleteTool = (tool: Tool) => (dispatch: Dispatch) => {
  // delete
  dispatch(deleteTool(tool));
  dispatch(toolLoadStart());

  return fetchApi(tool['@id'], {
    method: 'DELETE',
  }).catch(); // TODO handle error
};

/*


Group actions


*/

export const requestFetchGroups = () => (dispatch: Dispatch) =>
  fetchApi('/api/group_tools').then(responseJson => {
    const groups = responseJson['hydra:member'];
    dispatch(receiveGroups(groups));

    return groups;
  });

export const requestCreateGroup = (group: Group) => (dispatch: Dispatch) =>
  fetchApi('/api/group_tools', {
    method: 'POST',
    body: JSON.stringify(group),
  }).then(responseJson => dispatch(createGroup(responseJson)));

export const requestUpdateGroup = (group: GroupWithTools) => (dispatch: Dispatch) => {
  dispatch(updateGroup(group));
  const requestBody = {
    ...group,
    tools: group.tools.map(tool => tool['@id']),
  };

  return fetchApi(group['@id'], {
    method: 'PUT',
    body: JSON.stringify(requestBody),
  });
};

export const requestDeleteGroup = (group: Group) => (dispatch: Dispatch) => {
  dispatch(deleteGroup(group));

  return fetchApi(group['@id'], { method: 'DELETE' });
};

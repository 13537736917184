import React, { MouseEventHandler, PropsWithChildren } from 'react';

import './HeaderLinkAndButton.css';

interface Props {
  className: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const HeaderButton = ({ children, className, onClick }: PropsWithChildren<Props>): JSX.Element => (
  // eslint-disable-next-line react/button-has-type -- FIXME
  <button className={`${className} header-button`} onClick={onClick}>
    {children}
  </button>
);

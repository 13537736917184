import { useMemo } from 'react';

import { useFetchItem } from './useFetchItem';

interface useUnlikeNewsReturnType {
  isUnlikeLoading: boolean;
  doUnlike: () => void;
}

export const useUnlikeNews = (onSuccess: () => void, id?: string): useUnlikeNewsReturnType => {
  const extraOptions = useMemo(
    () => ({
      method: 'PUT',
      body: JSON.stringify({}),
    }),
    [],
  );

  const { loading, refetch } = useFetchItem({
    url: `/api/unlike_internal_news/${id ?? ''}`,
    errorMessage: `Failed to unlike the internal news n°${id ?? ''}`,
    successCallback: onSuccess,
    extraOptions,
    autoFetch: false,
  });

  return { isUnlikeLoading: loading, doUnlike: id ? refetch : () => undefined };
};

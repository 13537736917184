import { AdminRoutes, BackOfficeRoutes, EligibilityRoutes, FrontOfficeRoutes } from 'router/routes';

const FRONT_OFFICE_BASE_ROUTE = '';
const BACK_OFFICE_BASE_ROUTE = '/backoffice';
const ADMIN_BASE_ROUTE = '/portal-admin';
const ELIGIBILITY_BASE_ROUTE = '';

export const getFrontOfficePath = (path?: FrontOfficeRoutes): string =>
  FRONT_OFFICE_BASE_ROUTE + (path ?? FrontOfficeRoutes.HOME);

export const getBackOfficePath = (path?: BackOfficeRoutes): string =>
  BACK_OFFICE_BASE_ROUTE + (path ?? BackOfficeRoutes.HOME);

export const getAdminPath = (path?: AdminRoutes): string => ADMIN_BASE_ROUTE + (path ?? AdminRoutes.HOME);

export const getEligibilityPath = (path?: EligibilityRoutes): string =>
  ELIGIBILITY_BASE_ROUTE + (path ?? EligibilityRoutes.ELIGIBILITY);

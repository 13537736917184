import { DeepPartial } from 'react-hook-form';

import { InternalNewsScopeType } from 'types/InternalNewsScopeType';
import { CodeIso, CodeIsoArray } from 'types/Language';
import { PublicationScope, PublicationScopeWithChildrenHierarchy } from 'types/PublicationScope';

export const isCodeIso = (maybeCodeIso: unknown): maybeCodeIso is CodeIso =>
  CodeIsoArray.some(codeIso => codeIso === maybeCodeIso);

export const isInternalNewsScopeType = (
  maybeInternalNewsScopeType: unknown,
): maybeInternalNewsScopeType is InternalNewsScopeType =>
  Object.values(InternalNewsScopeType).some(
    internalNewsScopeType => internalNewsScopeType === maybeInternalNewsScopeType,
  );

const isFormPublicationScopeFullyDefined = <T extends PublicationScope>(
  potentialFormPublicationScope: DeepPartial<T>,
): boolean =>
  undefined !== potentialFormPublicationScope['@id'] &&
  undefined !== potentialFormPublicationScope.id &&
  undefined !== potentialFormPublicationScope.frenchName &&
  undefined !== potentialFormPublicationScope.englishName;

export const areFormPublicationScopesWithChildrenFullyDefined = (
  potentialFormPublicationScopesWithChildren: (DeepPartial<PublicationScopeWithChildrenHierarchy> | undefined)[],
): boolean =>
  potentialFormPublicationScopesWithChildren.every(potentialFormPublicationScopeWithChildren => {
    const publicationScopeChildren = potentialFormPublicationScopeWithChildren?.childrenScopes;
    const areChildrenFullyDefinedIfExist =
      undefined === publicationScopeChildren ||
      areFormPublicationScopesWithChildrenFullyDefined(publicationScopeChildren);

    return (
      undefined !== potentialFormPublicationScopeWithChildren &&
      isFormPublicationScopeFullyDefined(potentialFormPublicationScopeWithChildren) &&
      areChildrenFullyDefinedIfExist
    );
  });

export const hasProperty = <T extends string>(element: unknown, property: T): element is Record<T, unknown> => {
  if (element === undefined || element === null) {
    return false;
  }

  return Object.prototype.hasOwnProperty.call(element, property);
};

export const hasTypedProperties = <T extends string>(
  element: unknown,
  properties: {
    name: T;
    type: 'string' | 'number' | 'undefined' | 'object' | 'boolean' | 'function' | 'array';
  }[],
): element is Record<T, unknown> => {
  if (element === undefined || element === null) {
    return false;
  }

  return properties.every(property => {
    if (!hasProperty(element, property.name)) {
      return false;
    }
    if (property.type === 'array') {
      return Array.isArray(element[property.name]);
    }

    return typeof element[property.name] === property.type;
  });
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { getUserInitials } from 'components/utils';
import { useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { User } from 'types/User';

import './UserBadge.css';

type Props = {
  author?: User;
  shouldShowIsEditor?: boolean;
};

const UserBadge: React.FC<Props> = ({ shouldShowIsEditor = true, author }) => {
  const user = useTypedSelector(selectUser);
  const userToDisplay = useMemo(() => author ?? user, [author, user]);

  if (!userToDisplay.uuid) {
    return <UserBadgePlaceholder />;
  }

  const displayName = (userToDisplay?.firstname ?? '') + ' ' + (userToDisplay?.lastname ?? '');

  return (
    <div className="user-badge">
      <UserBadgeAvatar displayName={displayName} userToDisplay={userToDisplay} />
      <UserBadgeDetail
        displayName={displayName}
        shouldShowIsEditor={shouldShowIsEditor}
        userToDisplay={userToDisplay}
      />
    </div>
  );
};

export default UserBadge;

type UserBadgeDetailProps = {
  displayName: string;
  shouldShowIsEditor: boolean;
  userToDisplay: User;
};

const UserBadgeDetail: React.FC<UserBadgeDetailProps> = ({ displayName, shouldShowIsEditor, userToDisplay }) => {
  const { t } = useTranslation();

  return (
    <div className="details">
      <span className="display-name">{displayName}</span>
      <span>{shouldShowIsEditor && userToDisplay.isEditor && t('editor_title')}</span>
    </div>
  );
};

type UserBadgeAvatarProps = {
  displayName: string;
  userToDisplay: User;
};

const UserBadgeAvatar: React.FC<UserBadgeAvatarProps> = ({ displayName, userToDisplay }) => {
  const [userPicture, setUserPicture] = useState(userToDisplay.picture);

  return userPicture ? (
    <img
      className="avatar"
      src={userPicture}
      alt={displayName}
      onError={() => {
        setUserPicture(null);
      }}
    />
  ) : (
    <span className="avatar empty">
      <p>
        {getUserInitials(userToDisplay.firstname, userToDisplay.lastname) || <FontAwesomeIcon icon={['fas', 'user']} />}
      </p>
    </span>
  );
};

const UserBadgePlaceholder: React.FC = () => (
  <div className="user-badge placeholder">
    <span className="avatar empty"></span>
    <div className="details">
      <span className="display-name"></span>
    </div>
  </div>
);

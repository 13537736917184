import fetchApi from 'data/fetchApi';

export const RECEIVE_NOTIFICATION_APPLICATIONS = 'RECEIVE_NOTIFICATION_APPLICATIONS';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveNotificationApplication(applications) {
  return {
    type: RECEIVE_NOTIFICATION_APPLICATIONS,
    applications: applications,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function fetchNotificationApplications() {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch, getState) {
    return fetchApi('/api/notification_applications').then(responseJson => {
      dispatch(receiveNotificationApplication(responseJson['hydra:member']));
    });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function toggleNotificationApplication(application, toggled) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    return fetchApi(application['@id'], {
      method: toggled ? 'POST' : 'DELETE',
      expectResponse: false,
    });
  };
}

import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import useClickTracking from 'analytics/useClickTracking';
import { getFrontOfficePath } from 'components/App/utils';
import { HeadlineNewsItem } from 'components/UI/HeadlineNews/HeadlineNewsItem';
import { FrontOfficeRoutes } from 'router/routes';
import { HeadlineInternalNews } from 'types/HeadlineInternalNews';
import { ANALYTICS_LANGUAGE, CodeIso } from 'types/Language';
import { displayLongDate } from 'utils/date';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';

import 'swiper/swiper.min.css';

interface Props {
  headlinesNews: HeadlineInternalNews[];
  selectedLanguage: CodeIso;
}
export const MobileSwiper: React.FC<Props> = ({ headlinesNews, selectedLanguage }) => {
  const { clickHeadlineNewsTracking } = useClickTracking();

  return (
    <Swiper slidesPerView={1.2} spaceBetween={16} className="headline-news-mobile-swiper">
      {headlinesNews?.map((headlineNews: HeadlineInternalNews) => {
        const headlineTranslation = getSelectedTranslation(headlineNews.translations, selectedLanguage);

        const headlineNewsTranslationInAnalyticsLanguage = getSelectedTranslation(
          headlineNews.translations,
          ANALYTICS_LANGUAGE,
        );

        if (headlineTranslation === undefined || headlineNewsTranslationInAnalyticsLanguage === undefined) {
          return null;
        }
        const headlineNewsPublicationDate = displayLongDate(headlineNews?.publishedAt, selectedLanguage);

        return (
          <SwiperSlide key={`mobile-${headlineNews.id}`}>
            <NavLink
              to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${headlineNews.id}`)}
              onClick={_ => clickHeadlineNewsTracking(headlineNewsTranslationInAnalyticsLanguage.title)}
            >
              <HeadlineNewsItem
                title={headlineTranslation?.title ?? ''}
                content={headlineTranslation?.text ?? ''}
                thumbnail={selectedLanguage === 'FRA' ? headlineNews.facultativeThumbnail : headlineNews.mainThumbnail}
                date={headlineNewsPublicationDate}
                viewCount={headlineNews.viewCount}
                likeCount={headlineNews.likeCount}
              />
            </NavLink>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

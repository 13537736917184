import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import useViewTracking from 'analytics/useViewTracking';
import { ContactSearch } from 'components/ContactSearch/ContactSearch';
import { ContactModal } from 'components/UI/ContactModal/ContactModal';
import { preventDefaultHandlerForNonInputTarget } from 'components/utils';
import { Contact } from 'types/Contact';

import './ContactDirectory.scss';

export const ContactDirectory: React.FC = () => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [shouldShowContactModal, setShouldShowContactModal] = useState(false);
  const [contactToDisplay, setContactToDisplay] = useState<Contact | null>(null);

  const handleSelectContact = (contact: Contact): void => {
    setContactToDisplay(contact);
    setShouldShowContactModal(true);
  };

  const onCloseContactModal = (): void => {
    setShouldShowContactModal(false);
    setIsSearchActive(true);
  };

  const activateSearch = useCallback((event: MouseEvent) => {
    setIsSearchActive(true);
    preventDefaultHandlerForNonInputTarget(event);
  }, []);

  const deactivateSearch = useCallback(() => {
    // Known issue in React: we need to wait for next tick to know if focus has been taken by a child
    // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
    setTimeout(() => setIsSearchActive(false));
  }, []);

  const { viewContactModalTracking } = useViewTracking();

  useEffect(() => {
    if (shouldShowContactModal) {
      viewContactModalTracking();
    }
  }, [shouldShowContactModal, viewContactModalTracking]);

  return (
    <div className="contact-directory-container" onBlur={deactivateSearch} onMouseDown={activateSearch}>
      <ContactSearch isSearchActive={isSearchActive} onSelectContact={handleSelectContact} />
      {contactToDisplay !== null && (
        <ContactModal
          onClose={onCloseContactModal}
          isOpen={shouldShowContactModal}
          contactToDisplay={contactToDisplay}
        />
      )}
    </div>
  );
};

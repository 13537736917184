import fetchApi from 'data/fetchApi';

export const RECEIVE_LANGUAGE = 'RECEIVE_LANGUAGE';
export const LANGUAGE_LOAD_START = 'LANGUAGE_LOAD_START';
export const LANGUAGE_LOAD_END = 'LANGUAGE_LOAD_END';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function languageLoadStart() {
  return {
    type: LANGUAGE_LOAD_START,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function LanguageLoadEnd() {
  return {
    type: LANGUAGE_LOAD_END,
  };
}

// Synchronous actions
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveLanguage(languages = []) {
  return {
    type: RECEIVE_LANGUAGE,
    languages: languages,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestFetchLanguage() {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    return fetchApi('/api/languages').then(responseJson => {
      const languages = responseJson['hydra:member'];
      dispatch(languageLoadStart());
      dispatch(receiveLanguage(languages));
      dispatch(LanguageLoadEnd());
    });
  };
}

import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { Title } from 'components/UI/Title/Title';
import bouyguesDirectoryLogo from 'images/bouygues-directory-logo.svg';
import bylinkLogo from 'images/bylink-logo.png';
import challengerLogo from 'images/challenger-logo.svg';
import yammerLogo from 'images/yammer-logo.png';

import { BackgroundColor, BouyguesLink } from './BouyguesLink/BouyguesLink';

import './styles/BouyguesLinks.css';

export const BouyguesLinks = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bouygues-container">
        <Title title={t('admin.tools.bouygues_links')} />

        <div className="bouygues-links-container">
          <BouyguesLink
            alt="link to bylink"
            url={t('frontoffice.bylink.url')}
            img={bylinkLogo}
            withPadding={true}
            backgroundColor={BackgroundColor.WHITE}
          />
          <BouyguesLink
            alt="link to Bouygues directory"
            url={t('frontoffice.bouygues_directory.url')}
            img={bouyguesDirectoryLogo}
            backgroundColor={BackgroundColor.GREY}
          />
          <BouyguesLink
            alt="link to yammer"
            url={t('frontoffice.yammer.url')}
            img={yammerLogo}
            backgroundColor={BackgroundColor.GREY}
          />

          <BouyguesLink
            alt="link to challenger"
            url={t('frontoffice.challenger.url')}
            img={challengerLogo}
            backgroundColor={BackgroundColor.BLUE}
          />
        </div>
      </div>
    </>
  );
};

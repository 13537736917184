import compact from 'lodash/compact';
import React, { useMemo, useState } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { Thumbnail } from 'components/App/FrontOffice/Thumbnail/Thumbnail';
import { LikeCount } from 'components/UI/LikeCount/LikeCount';
import { ThemeDisplayer } from 'components/UI/ThemeDisplayer/ThemeDisplayer';
import { Title } from 'components/UI/Title/Title';
import { ViewCount } from 'components/ViewCount/ViewCount';
import mediaFallback from 'images/icons/media_fallback.svg';
import { InternalNewsDetails } from 'types/InternalNewsDetails';
import { InternalNewsPreview } from 'types/InternalNewsPreview';
import { ANALYTICS_LANGUAGE, CodeIso } from 'types/Language';
import { displayLongDate } from 'utils/date';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';
import { sanitizeContentForDisplay } from 'utils/sanitize';

import { NewsDetailsMediaModal } from './NewsDetailsMediaModal/NewsDetailsMediaModal';

import './styles/NewsDetailsContent.scss';

export enum NewsDetailsContentDisplayMode {
  DETAILS = 'details',
  PREVIEW = 'preview',
}

type NewsDetailsContentProps = {
  language: CodeIso;
} & (
  | {
      news: InternalNewsDetails | null;
      displayMode: NewsDetailsContentDisplayMode.DETAILS;
    }
  | {
      news: InternalNewsPreview | null;
      displayMode: NewsDetailsContentDisplayMode.PREVIEW;
    }
);

const FRENCH_ISO_CODE = 'FRA';

export const NewsDetailsContent: React.FC<NewsDetailsContentProps> = ({ news, language, displayMode }) => {
  const [shouldOpenMediaModal, setShouldOpenMediaModal] = useState(false);

  const newsTranslation = useMemo(() => getSelectedTranslation(news?.translations, language), [news, language]);

  const sanitizedNewsContent = useMemo(
    () => (newsTranslation ? sanitizeContentForDisplay(newsTranslation.text) : ''),
    [newsTranslation],
  );

  const publicationDate = displayLongDate(
    NewsDetailsContentDisplayMode.DETAILS === displayMode ? news?.publishedAt : undefined,
    language,
  );

  const themes = useMemo(
    () => compact(news?.themes.map(theme => getSelectedTranslation(theme.translations, language)?.text)),
    [news, language],
  );

  const hasAssociatedMedia =
    !!news?.facultativeThumbnail && !!news.mainThumbnail && !!news.facultativeMedia && !!news?.mainMedia;

  const { clickNewsMediaTracking } = useClickTracking();

  const analyticsTranslation = useMemo(() => getSelectedTranslation(news?.translations, ANALYTICS_LANGUAGE), [news]);

  const onMediaClick = (): void => {
    if (analyticsTranslation !== undefined) clickNewsMediaTracking(analyticsTranslation?.title);
    setShouldOpenMediaModal(true);
  };

  return (
    <>
      {news && (
        <>
          <div className="news-details-content">
            <Title title={newsTranslation?.title ?? ''} />
            <div className="news-details-metadata-container">
              <div className="news-details-themes-and-date">
                <ThemeDisplayer themes={themes} />
                {NewsDetailsContentDisplayMode.DETAILS === displayMode && (
                  <span className="news-details-publication-date">{publicationDate}</span>
                )}
              </div>
              <div className="news-details-stats">
                {NewsDetailsContentDisplayMode.DETAILS === displayMode && news.viewCount > 0 && (
                  <ViewCount count={news.viewCount} />
                )}

                {NewsDetailsContentDisplayMode.DETAILS === displayMode && news.likeCount > 0 && (
                  <LikeCount likeCount={news.likeCount} />
                )}
              </div>
            </div>
            <div className="news-details-thumbnail-container">
              {hasAssociatedMedia ? (
                <Thumbnail
                  src={(FRENCH_ISO_CODE === language ? news.facultativeThumbnail : news.mainThumbnail) ?? ''}
                  alt={'news thumbnail'}
                  shadow
                  onClick={onMediaClick}
                  dataTestId={'news-details-thumbnail'}
                />
              ) : (
                <Thumbnail
                  src={mediaFallback}
                  alt={'news thumbnail fallback'}
                  dataTestId={'news-details-thumbnail-fallback'}
                />
              )}
            </div>
            <div
              className="news-details-news-content"
              dangerouslySetInnerHTML={{
                __html: sanitizedNewsContent,
              }}
            />
          </div>
          {hasAssociatedMedia && (
            <NewsDetailsMediaModal
              isOpen={shouldOpenMediaModal}
              onClose={() => setShouldOpenMediaModal(false)}
              mediaUrl={(FRENCH_ISO_CODE === language ? news.facultativeMedia : news.mainMedia) ?? ''}
              mediaType={news.mediaType}
              disableTracking={NewsDetailsContentDisplayMode.PREVIEW === displayMode}
            />
          )}
        </>
      )}
    </>
  );
};

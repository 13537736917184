import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import useViewTracking from 'analytics/useViewTracking';
import FrontOffice from 'components/App/FrontOffice/FrontOffice';
import { getAdminPath, getBackOfficePath, getEligibilityPath, getFrontOfficePath } from 'components/App/utils';
import { ScrollToTop } from 'components/ScrollToTop/ScrollToTop';
import { store } from 'data/store';

const queryClient = new QueryClient();
const AppAdmin = React.lazy(() => import('components/App/AppAdmin/AppAdmin'));
const AppEligibility = React.lazy(() => import('components/App/AppEligibility/AppEligibility'));
const BackOfficeRouter = React.lazy(() => import('router/AppInternal/BackOfficeRouter'));

export const AppInternal: React.FC = () => {
  const { viewNotInApplicationTracking } = useViewTracking();
  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      viewNotInApplicationTracking();
    });
  }, [viewNotInApplicationTracking]);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Suspense>
            <Switch>
              <Route path={getAdminPath()} children={<AppAdmin />} />
              <Route path={getBackOfficePath()} children={<BackOfficeRouter />} />
              <Route path={getEligibilityPath()} children={<AppEligibility />} />
              <Route path={getFrontOfficePath()} children={<FrontOffice />} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
};

import React, { ReactNode, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import { useFetchHeadlineNews } from 'components/App/hooks/useFetchHeadlineNews';
import { getFrontOfficePath } from 'components/App/utils';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { DesktopSwiper } from 'components/UI/DesktopSwiper/DesktopSwiper';
import { NewsBlockError } from 'components/UI/Errors/NewsBlockError';
import { MobileSwiper } from 'components/UI/MobileSwiper/MobileSwiper';
import { NoNewsAvailable } from 'components/UI/NoNewsAvailable/NoNewsAvailable';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { useHasFetchedUser } from 'hooks/useHasFetchedUser';
import { FrontOfficeRoutes } from 'router/routes';
import { ANALYTICS_LANGUAGE } from 'types/Language';
import { useDisplayLongDate } from 'utils/date';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';

import { HeadlineNewsForEmptyLocalScope } from './HeadlineNewsForEmptyLocalScope';
import { HeadlineNewsItem } from './HeadlineNewsItem';
import { useUserAllowedToSeeWorldMap } from './utils/useUserAllowedToSeeWorldMap';

import './HeadlineNews.css';

interface HeadlineNewsProps {
  titleBlock: ReactNode;
  publicationScopeId: string | null;
  onClickOnWorldMap?: React.MouseEventHandler<HTMLButtonElement>;
}

export const HeadlineNews: React.FC<HeadlineNewsProps> = ({ titleBlock, publicationScopeId, onClickOnWorldMap }) => {
  const { t } = useTranslation();

  const { userLanguage } = useUserLanguageContext();

  const { isLoading, isError, headlinesNews, fetchHeadlineNews } = useFetchHeadlineNews(
    publicationScopeId ?? '',
    userLanguage,
  );

  const { clickHeadlineNewsTracking } = useClickTracking();

  useEffect(() => {
    if (null !== publicationScopeId) {
      fetchHeadlineNews();
    }
  }, [publicationScopeId, fetchHeadlineNews, userLanguage]);

  const firstHeadlinesNews = headlinesNews?.[0];
  const firstHeadlineNewsTranslation = getSelectedTranslation(firstHeadlinesNews?.translations, userLanguage);

  const firstHeadlineNewsPublicationDate = useDisplayLongDate(firstHeadlinesNews?.publishedAt);

  const firstHeadlineNewsTranslationInAnalyticsLanguage = getSelectedTranslation(
    firstHeadlinesNews?.translations,
    ANALYTICS_LANGUAGE,
  );

  const hasFetchedUser = useHasFetchedUser();

  const isLocalScopeEmpty = null === publicationScopeId;
  const shouldShowWorldMap = useUserAllowedToSeeWorldMap() && isLocalScopeEmpty;

  const headlineNewsBoxContainerClassName = 'headline-news-box-container';

  if (isError) {
    return (
      <div className={headlineNewsBoxContainerClassName}>
        {titleBlock}
        <NewsBlockError />
      </div>
    );
  }

  const shouldWaitForUserToBeFetched = !hasFetchedUser && isLocalScopeEmpty;
  const shouldWaitForHeadlineNewsToBeFetched = !shouldShowWorldMap && headlinesNews === null;

  if (isLoading || shouldWaitForUserToBeFetched || shouldWaitForHeadlineNewsToBeFetched) {
    return (
      <div className={headlineNewsBoxContainerClassName}>
        {titleBlock}
        <div className="loader-block">
          <div className="headline-loader">
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  if (shouldShowWorldMap) {
    return (
      <div className={headlineNewsBoxContainerClassName}>
        {titleBlock}
        <HeadlineNewsForEmptyLocalScope onClick={onClickOnWorldMap} />
      </div>
    );
  }

  if (null !== headlinesNews && headlinesNews.length === 0) {
    return (
      <div className={headlineNewsBoxContainerClassName}>
        {titleBlock}
        <NoNewsAvailable text={t('frontoffice.empty_headline')} />
      </div>
    );
  }

  return (
    <div className={headlineNewsBoxContainerClassName}>
      {titleBlock}
      {null !== headlinesNews && (
        <>
          <DesktopSwiper headlinesNews={headlinesNews} selectedLanguage={userLanguage} />
          {
            //TODO check that this disjunction of cases is not factorizable
            headlinesNews.length > 1 ? (
              <MobileSwiper headlinesNews={headlinesNews} selectedLanguage={userLanguage} />
            ) : (
              firstHeadlinesNews &&
              firstHeadlineNewsTranslationInAnalyticsLanguage && (
                <NavLink
                  to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${firstHeadlinesNews.id}`)}
                  className="headline-news-mobile-swiper"
                  onClick={_ => clickHeadlineNewsTracking(firstHeadlineNewsTranslationInAnalyticsLanguage.title)}
                >
                  <HeadlineNewsItem
                    title={firstHeadlineNewsTranslation?.title ?? ''}
                    content={firstHeadlineNewsTranslation?.text ?? ''}
                    thumbnail={
                      userLanguage === 'FRA'
                        ? firstHeadlinesNews.facultativeThumbnail
                        : firstHeadlinesNews.mainThumbnail
                    }
                    date={firstHeadlineNewsPublicationDate}
                    viewCount={firstHeadlinesNews.viewCount}
                    likeCount={firstHeadlinesNews.likeCount}
                  />
                </NavLink>
              )
            )
          }
        </>
      )}
    </div>
  );
};

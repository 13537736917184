import React from 'react';

import { City } from 'types/City';

import { CitySelectorItem } from './CitySelectorItem';

interface Props {
  cities: City[];
  currentCity: string;
  onClickChildren: (city: City) => void;
}

export const CitySelector: React.FC<Props> = ({ cities, currentCity, onClickChildren }) => (
  <>
    {cities.map(city => (
      <CitySelectorItem city={city} onClick={onClickChildren} currentCity={currentCity} key={city.uuid} />
    ))}
  </>
);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import './SearchInput.css';

interface SearchInputProps {
  icon?: JSX.Element;
  placeholder?: string;
  initialValue?: string;
  onValueChange?: (newValue: string) => void;
  hasFocus?: boolean;
  shouldClearSearchInput?: boolean;
  shouldResetSearchInput?: boolean;
  onReset?: () => void;
  handleKeyDown?: (pressedKey: React.KeyboardEvent) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  icon,
  placeholder = '',
  initialValue = '',
  onValueChange,
  hasFocus = false,
  shouldClearSearchInput,
  shouldResetSearchInput,
  onReset,
  handleKeyDown,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchedValue, setSearchedValue] = useState('');

  useEffect(() => {
    setSearchedValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (shouldClearSearchInput) {
      setSearchedValue('');
    }
  }, [shouldClearSearchInput]);

  useEffect(() => {
    if (shouldResetSearchInput) {
      setSearchedValue(initialValue);
      onReset?.();
    }
  }, [initialValue, onReset, shouldResetSearchInput]);

  useEffect(() => {
    if (hasFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [hasFocus]);

  const clearSearchedValueWithFocus = useCallback((withFocus: boolean): void => {
    setSearchedValue('');
    if (withFocus) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, []);

  const onInputValueChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setSearchedValue(event.target.value);
  }, []);

  useEffect(() => {
    if (onValueChange) {
      onValueChange(searchedValue);
    }
  }, [onValueChange, searchedValue]);

  const shouldDisplayClearButton = searchedValue.length > 0;
  const shouldDisplayEndSearchIcon = hasFocus && !shouldDisplayClearButton;

  return (
    <div className={'search-container'}>
      {icon &&
        (hasFocus ? (
          <div className="search-icon clickable" onMouseDown={() => clearSearchedValueWithFocus(false)}>
            <FontAwesomeIcon icon={['fas', 'arrow-left']} />
          </div>
        ) : (
          <div className="search-icon">{icon}</div>
        ))}
      <div className="search-input">
        <input
          ref={inputRef}
          type="text"
          onChange={onInputValueChange}
          value={searchedValue}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
        />
      </div>

      {shouldDisplayClearButton && (
        <div className="clear-search-icon" onMouseDown={() => clearSearchedValueWithFocus(true)}>
          <FontAwesomeIcon icon={['fas', 'xmark']} />
        </div>
      )}

      {shouldDisplayEndSearchIcon && (
        <div className="search-icon">
          <FontAwesomeIcon icon={['fas', 'magnifying-glass']} />
        </div>
      )}
    </div>
  );
};

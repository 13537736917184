import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { City } from 'types/City';

import './CitySelectorItem.css';

interface Props {
  city: City;
  currentCity: string;
  onClick: (city: City) => void;
}

export const CitySelectorItem: React.FC<Props> = ({ city, currentCity, onClick }) => {
  const cityToDisplay = `${city.city}, ${city.country}`;

  return (
    <div className="city-selector-item-container" onClick={() => onClick(city)} data-testid="city-item-selector">
      <p>{cityToDisplay}</p>
      {currentCity === cityToDisplay && (
        <FontAwesomeIcon icon={['fas', 'check']} className="city-selector-item-check" />
      )}
    </div>
  );
};

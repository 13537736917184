import { useFetchCollection } from 'components/App/hooks/useFetchCollection';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { PublishedInternalNews } from 'types/PublishedInternalNews';

interface NewsWithSameThemesResponse {
  isLoading: boolean;
  isError: boolean;
  newsWithSameThemes: PublishedInternalNews[] | null;
  fetchNewsWithSameThemes: () => void;
}
export const useFetchNewsWithSameThemes = (
  themesIds: string[],
  newsIdToExclude: string,
  itemsPerPage: number,
  frontOfficePublicationScope: string,
): NewsWithSameThemesResponse => {
  const { userLanguage } = useUserLanguageContext();

  const filters = new URLSearchParams();

  filters.append('languageIsoCode', userLanguage);
  filters.append('idToExclude', newsIdToExclude);
  filters.append('itemsPerPage', itemsPerPage.toString());
  filters.append('frontOfficePublicationScope', frontOfficePublicationScope);

  themesIds.forEach(themesId => filters.append('themes.id[]', themesId));

  const {
    loading,
    isError,
    data,
    refetch: fetchNewsWithSameThemes,
  } = useFetchCollection<PublishedInternalNews>({
    url: `/api/published_internal_news?${filters.toString()}`,
    errorMessage: `Failed to search for news with the same themes as news with id ${newsIdToExclude}`,
    autoFetch: false,
  });

  return { isLoading: loading, isError, newsWithSameThemes: data, fetchNewsWithSameThemes };
};

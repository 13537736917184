import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { useSearchForNews } from 'components/App/hooks/useSearchForNews';
import { getFrontOfficePath } from 'components/App/utils';
import { BlackCrossButton, PrimaryButton } from 'components/CustomButton/CustomButton';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { NewsBoxElement } from 'components/UI/NewsBoxElement/NewsBoxElement';
import { NewsTypeToggle } from 'components/UI/NewsTypeToggle/NewsTypeToggle';
import { PaginationButtons } from 'components/UI/PaginationButtons/PaginationButtons';
import { preventDefaultHandlerForNonInputTarget } from 'components/utils';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { isFeatureFlagEnabled } from 'helpers/isFeatureFlagEnabled';
import exclamationCircle from 'images/icons/exclamation_circle.svg';
import newsSearch from 'images/icons/news-search.svg';
import noResultIcon from 'images/icons/search-no-result.svg';
import { FrontOfficeRoutes } from 'router/routes';
import { InternalNewsScopeType } from 'types/InternalNewsScopeType';
import { frenchCodeIso } from 'types/Language';
import { GLOBAL_PUBLICATION_SCOPE_ID } from 'types/PublicationScope';
import { findThemesByLanguage, getSelectedTranslation } from 'utils/internalNewsFunctions';

import './styles/SearchNewsModal.scss';

interface SearchNewsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const VALUE_LENGTH_THRESHOLD_FOR_SEARCH = 3;
const SEARCH_RESULTS_PER_PAGE = 10;

const MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_MOBILE = 5;

export const SearchNewsModal: React.FC<SearchNewsModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const { userLanguage } = useUserLanguageContext();
  const isOnMobile = useIsOnMobile();
  const { frontOfficeLocalScope } = useFrontOfficeLocalScopeContext();

  const { clickNewsSearchButtonInModalTracking, clickNewsInSearchModalTracking } = useClickTracking();

  const [explanationsOnSearchNeeded, setExplanationsOnSearchNeeded] = useState(true);
  const [isSearchActive, setIsSearchActive] = useState(true);
  const [shouldResetSearchInput, setShouldResetSearchInput] = useState(false);
  const [valueFromSearchInput, setValueFromSearchInput] = useState('');
  const [lastSearchedValue, setLastSearchedValue] = useState('');
  const [shouldShowPagination, setShouldShowPagination] = useState(false);
  const [selectedResultsPage, setSelectedResultsPage] = useState(1);
  const [newsToSearchScopeType, setNewsToSearchScopeType] = useState(InternalNewsScopeType.GLOBAL);

  const publicationScopeIdToSearch =
    InternalNewsScopeType.GLOBAL === newsToSearchScopeType
      ? GLOBAL_PUBLICATION_SCOPE_ID
      : frontOfficeLocalScope?.id ?? '';

  const { isLoading, isError, news, totalResults, searchForNews } = useSearchForNews({
    searchedValue: lastSearchedValue,
    itemsPerPage: SEARCH_RESULTS_PER_PAGE,
    page: selectedResultsPage,
    frontOfficePublicationScopeId: publicationScopeIdToSearch,
    onSuccess: () => {
      setExplanationsOnSearchNeeded(false);
      setShouldShowPagination(true);
    },
    onError: () => setExplanationsOnSearchNeeded(false),
  });

  const noResult = totalResults === 0;

  const isSearchAllowed = valueFromSearchInput.length >= VALUE_LENGTH_THRESHOLD_FOR_SEARCH;

  const onReset = (): void => setShouldResetSearchInput(false);

  const onSearch = useCallback((): void => {
    setShouldShowPagination(false);
    setSelectedResultsPage(1);
    setLastSearchedValue(valueFromSearchInput);
    clickNewsSearchButtonInModalTracking(valueFromSearchInput);
  }, [clickNewsSearchButtonInModalTracking, valueFromSearchInput]);

  const onChangeNewsScopeType = (newsScopeType: InternalNewsScopeType): void => {
    setNewsToSearchScopeType(newsScopeType);
  };

  useEffect(() => {
    if (lastSearchedValue.length >= VALUE_LENGTH_THRESHOLD_FOR_SEARCH) {
      searchForNews();
    }
  }, [lastSearchedValue, publicationScopeIdToSearch, selectedResultsPage, searchForNews]);

  const handleSelectPage = useCallback(
    (selectedPage: number) => {
      if (selectedPage !== selectedResultsPage) {
        setSelectedResultsPage(selectedPage);
        setShouldResetSearchInput(true);
      }
    },
    [selectedResultsPage],
  );

  const handleKeyDown = (pressedKey: React.KeyboardEvent): void => {
    if (pressedKey.key === 'Enter' && isSearchAllowed) {
      onSearch();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setIsSearchActive(true);
    }
  }, [isOpen]);

  const activateSearch = useCallback((event: MouseEvent) => {
    setIsSearchActive(true);
    preventDefaultHandlerForNonInputTarget(event);
  }, []);

  const deactivateSearch = useCallback(() => {
    // Known issue in React: we need to wait for next tick to know if focus has been taken by a child
    // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
    setTimeout(() => setIsSearchActive(false));
  }, []);

  const onInputChange = (value: string): void => setValueFromSearchInput(value);

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="search-news-modal-container">
        <div className="header">
          <span className="title">{t('frontoffice.news.search_modal.title')}</span>
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="content">
          <div className="search-block">
            <div
              className={`search-input-container${isSearchActive ? ' active' : ''}`}
              onBlur={deactivateSearch}
              onMouseDown={activateSearch}
            >
              <SearchInput
                placeholder={t('frontoffice.news.search_modal.input_placeholder')}
                onValueChange={onInputChange}
                hasFocus={isSearchActive}
                initialValue={lastSearchedValue}
                shouldResetSearchInput={shouldResetSearchInput}
                handleKeyDown={handleKeyDown}
                onReset={onReset}
              />
            </div>
            <PrimaryButton
              text={t('frontoffice.news.search_modal.search_button')}
              disabled={!isSearchAllowed || isLoading}
              className="search-button"
              onClick={onSearch}
            />
          </div>
          <div className="results-container">
            {explanationsOnSearchNeeded ? (
              <div className="explanations-block">
                <img src={newsSearch} alt="news-search-explanations-icon" />
                <div className="explanations">{t('frontoffice.news.search_modal.explanations')}</div>
              </div>
            ) : isLoading ? (
              <div className="search-loader-block">
                <div className="search-loader-container">
                  <Loader />
                </div>
              </div>
            ) : isError ? (
              <div className="warning-block">
                <img src={exclamationCircle} alt="exclamation-circle" />
                <div className="warning-message">{t('frontoffice.news.search_modal.error')}</div>
              </div>
            ) : (
              <>
                <div className="results-total">
                  {t('frontoffice.news.search_modal.total_results', { totalResults: totalResults.toString() })}
                  {isFeatureFlagEnabled('localFO') && (
                    <NewsTypeToggle
                      selectedNewsScopeType={newsToSearchScopeType}
                      onChangeNewsScopeType={onChangeNewsScopeType}
                      inputFieldName="search-modal"
                    />
                  )}
                </div>
                {noResult ? (
                  <div className="warning-block">
                    <img src={noResultIcon} alt="no-result-icon" />
                    <div className="warning-message">{t('frontoffice.news.search_modal.no_result')}</div>
                  </div>
                ) : (
                  <>
                    <div className="results">
                      {news?.map(newsItem => {
                        const newsTranslationInSelectedLanguage = getSelectedTranslation(
                          newsItem.translations,
                          userLanguage,
                        );

                        if (newsTranslationInSelectedLanguage === undefined) {
                          return null;
                        }

                        return (
                          <div className="result" data-testid="search-result" key={newsItem.id}>
                            <NavLink
                              to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${newsItem.id}`)}
                              onClick={clickNewsInSearchModalTracking}
                            >
                              <NewsBoxElement
                                title={newsTranslationInSelectedLanguage.title}
                                content={newsTranslationInSelectedLanguage.text}
                                thumbnail={
                                  userLanguage === frenchCodeIso
                                    ? newsItem.facultativeThumbnail
                                    : newsItem.mainThumbnail
                                }
                                themes={findThemesByLanguage(userLanguage, newsItem.themes)}
                                date={newsItem.publishedAt}
                                likeCount={newsItem.likeCount}
                                viewCount={newsItem.viewCount}
                              />
                            </NavLink>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
            {shouldShowPagination && totalResults > SEARCH_RESULTS_PER_PAGE && (
              <div className="pagination">
                <PaginationButtons
                  totalItems={totalResults}
                  onSelectPage={handleSelectPage}
                  itemsPerPage={SEARCH_RESULTS_PER_PAGE}
                  initiallySelectedPage={selectedResultsPage}
                  maxNumberOfPageButtonsToDisplay={
                    isOnMobile ? MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_MOBILE : undefined
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModalWithAnimation>
  );
};

import fetchApi from 'data/fetchApi';

export const RECEIVE_STATUS_LIST = 'RECEIVE_STATUS_LIST';
export const STATUS_LIST_LOAD_START = 'STATUS_LIST_LOAD_START';
export const STATUS_LIST_LOAD_END = 'STATUS_LIST_LOAD_START';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function statusListLoadStart() {
  return {
    type: STATUS_LIST_LOAD_START,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function statusListLoadEnd() {
  return {
    type: STATUS_LIST_LOAD_END,
  };
}

// Synchronous actions
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveStatusList(statusLists = []) {
  return {
    type: RECEIVE_STATUS_LIST,
    statusLists: statusLists,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestFetchStatusList() {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(statusListLoadStart());

    return fetchApi('/api/status_lists').then(responseJson => {
      const statusLists = responseJson['hydra:member'];

      dispatch(receiveStatusList(statusLists));
      dispatch(statusListLoadEnd());
    });
  };
}

import { Fade, Modal } from '@mui/material';
import { ReactElement } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactElement;
  dataTestId?: string;
  animationDuration?: number;
}

export const ModalWithAnimation: React.FC<Props> = ({
  isOpen,
  onClose,
  dataTestId,
  animationDuration = 200,
  children,
}) => (
  <Modal open={isOpen} onClose={onClose} data-testid={dataTestId}>
    <Fade in={isOpen} timeout={animationDuration} easing={'ease-in-out'}>
      {children}
    </Fade>
  </Modal>
);

import {
  RECEIVE_EXTERNAL_USER,
  RECEIVE_USER,
  RECEIVE_USER_LINKS,
  RECEIVE_USER_TOOLS,
  REQUEST_USER,
} from 'data/actions/UserActions';
import { Contact } from 'types/Contact';
import { User } from 'types/User';

type UserActionType =
  | typeof REQUEST_USER
  | typeof RECEIVE_USER
  | typeof RECEIVE_EXTERNAL_USER
  | typeof RECEIVE_USER_TOOLS
  | typeof RECEIVE_USER_LINKS;

const defaultUser: User = {
  '@id': null, // NULL id means user isn't authed yet

  picture: null,
  firstname: null,
  lastname: null,
  position: null,
  language: null,
  roles: [],
  tools: [],
  links: [],
  profile: null,
  isExternal: true,
  isEditor: false,
  isGlobalEditor: false,
  countryPublicationScope: null,
};

type UserAction = { type: UserActionType; user: User } & Pick<User, 'tools' | 'links'> & { contacts: Contact[] };

const userReducer = (state = defaultUser, action: UserAction): User => {
  let newState;

  switch (action.type) {
    case REQUEST_USER:
      newState = Object.assign({}, state);
      break;

    case RECEIVE_USER:
      newState = Object.assign({}, state, action.user);
      break;

    case RECEIVE_EXTERNAL_USER:
      newState = Object.assign({}, state, action.user);
      break;

    case RECEIVE_USER_TOOLS:
      newState = Object.assign({}, state, { tools: action.tools });
      break;

    case RECEIVE_USER_LINKS:
      newState = Object.assign({}, state, { links: action.links });
      break;

    default:
      newState = Object.assign({}, state);
      break;
  }

  return newState;
};

export default userReducer;

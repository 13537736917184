import React, { useEffect } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import useViewTracking from 'analytics/useViewTracking';
import { BlackCrossButton } from 'components/CustomButton/CustomButton';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { useGetFlagPath } from 'components/utils';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import Switch from 'images/icons/group.svg';
import { CodeIso, Language } from 'types/Language';

import './LanguageModal.css';

interface LanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  languages: Language[];
  userCodeIso: CodeIso;
}
const LanguageModal: React.FC<LanguageModalProps> = ({ isOpen, onClose, languages, title, userCodeIso }) => {
  const { clickChangeLanguageTracking } = useClickTracking();
  const { startViewChangeLanguageTracking, viewChangeLanguageTracking, endViewChangeLanguageTracking } =
    useViewTracking();
  const { getFlagPath } = useGetFlagPath();

  const { setUserLanguage } = useUserLanguageContext();

  useEffect(() => {
    if (isOpen) {
      startViewChangeLanguageTracking();
      viewChangeLanguageTracking();
    }
  }, [isOpen, startViewChangeLanguageTracking, viewChangeLanguageTracking]);

  const onCloseLanguageModal = (): void => {
    endViewChangeLanguageTracking();
    onClose();
  };

  const onClickOnCodeIso = (selectedLanguage: CodeIso): void => {
    clickChangeLanguageTracking(selectedLanguage);
    setUserLanguage(selectedLanguage);
    onCloseLanguageModal();
  };

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onCloseLanguageModal}>
      <div className="language-modal-container">
        <div className="language-modal-header">
          <span className="language-modal-title">
            <img className="error-logo" src={Switch} alt="" />
            {title}
          </span>
          <BlackCrossButton onClick={onCloseLanguageModal} />
        </div>
        <div className="language-modal-content">
          {languages ? (
            <ul className="languages-list">
              {languages.map((language, key) => (
                <li
                  key={key}
                  onClick={() => onClickOnCodeIso(language.codeIso)}
                  className={userCodeIso === language.codeIso ? 'active' : ''}
                >
                  <div className="flag-container flag">
                    <img src={getFlagPath(language.codeIso)} alt="flag" />
                  </div>
                  <p>{language.name}</p>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </ModalWithAnimation>
  );
};

export default LanguageModal;

export type MinorCodeIso =
  | 'CES'
  | 'DAN'
  | 'NLD'
  | 'DEU'
  | 'HRV'
  | 'HUN'
  | 'ITA'
  | 'POL'
  | 'RON'
  | 'SLK'
  | 'SLV'
  | 'SPA'
  | 'SRP'
  | 'ISL';

export const MinorCodeIsoArray: MinorCodeIso[] = [
  'CES',
  'DAN',
  'NLD',
  'DEU',
  'HRV',
  'HUN',
  'ITA',
  'POL',
  'RON',
  'SLK',
  'SLV',
  'SPA',
  'SRP',
  'ISL',
];

export const frenchCodeIso: MajorCodeIso = 'FRA';
export const englishCodeIso: MajorCodeIso = 'ENG';
export const MajorCodeIsoArray: MajorCodeIso[] = [frenchCodeIso, englishCodeIso];

export const ANALYTICS_LANGUAGE: CodeIso = englishCodeIso;
export const DEFAULT_USER_LANGUAGE: CodeIso = englishCodeIso;

export type MajorCodeIso = 'FRA' | 'ENG';

export type CodeIso = MinorCodeIso | MajorCodeIso;

export const CodeIsoArray = [...MajorCodeIsoArray, ...MinorCodeIsoArray];

export type Language = {
  '@id': string;
  '@type': string;
  name?: string;
  codeIso: CodeIso;
  urlImage?: string;
};

import { useUserLanguageContext } from 'context/UserLanguageContext';
import { CodeIso } from 'types/Language';

export const useDisplayShortDate = (date: string | Date | undefined): string => {
  const { userLanguage } = useUserLanguageContext();

  return displayShortDate(date, userLanguage);
};

export const useDisplayLongDate = (date: string | Date | undefined): string => {
  const { userLanguage } = useUserLanguageContext();

  return displayLongDate(date, userLanguage);
};

export const useDisplayShortDateAndTime = (date: string | Date | undefined | null): string => {
  const { userLanguage } = useUserLanguageContext();
  if (date === null) {
    return '';
  }

  return displayShortDateAndTime(date, userLanguage);
};

export const displayShortDate = (date: string | Date | undefined, language: CodeIso | string | undefined): string => {
  const dateToDisplay = new Date(date ?? '');

  return dateToDisplay.toLocaleDateString(language, { dateStyle: 'short' });
};

export const displayLongDate = (date: string | Date | undefined, language: CodeIso | string | undefined): string => {
  const dateToDisplay = new Date(date ?? '');

  return dateToDisplay.toLocaleDateString(language, { dateStyle: 'long' });
};

export const displayShortDateAndTime = (
  date: string | Date | undefined,
  language: CodeIso | string | undefined,
): string => {
  const dateToDisplay = new Date(date ?? '');

  return dateToDisplay.toLocaleString(language, { dateStyle: 'short', timeStyle: 'short' });
};

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

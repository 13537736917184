import Color from 'color';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line no-restricted-imports -- FIXME
import Icon from '../../Icon/Icon';

import './ToolRow.css';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export default function ToolRow(props) {
  const tool = props.data;

  const color = tool.group && tool.group.color ? tool.group.color : '009F4A';

  const isLight = Color('#' + color).isLight();

  return (
    <div className="tool-row">
      <div className="tool-tile" style={{ backgroundColor: '#' + color }}>
        {tool.icon && tool.icon.length > 0 ? (
          <Icon name={tool.icon} color={isLight ? '#3D3D42' : '#FFFFFF'} />
        ) : (
          <span className="initial-icon">{tool.title.charAt(0)}</span>
        )}
      </div>

      <div className="tool-details">
        <p className="tool-title">{tool.title}</p>
        <p className="tool-subtitle">{tool.subtitle}</p>
      </div>

      <div className="tool-actions">
        {props.showAdd ? (
          // eslint-disable-next-line react/button-has-type -- FIXME
          <button className="tool-button" onClick={() => props.onAdd(tool)}>
            <Icon name="plus"></Icon>
          </button>
        ) : null}

        {props.showEdit ? (
          // eslint-disable-next-line react/button-has-type -- FIXME
          <button className="tool-button" onClick={() => props.onEdit(tool)}>
            <Icon name="edit"></Icon>
          </button>
        ) : null}

        {props.showDelete ? (
          // eslint-disable-next-line react/button-has-type -- FIXME
          <button className="tool-button" onClick={() => props.onDelete(tool)}>
            <Icon name="trash"></Icon>
          </button>
        ) : null}
      </div>
    </div>
  );
}

ToolRow.defaultProps = {
  showAdd: true,
  showEdit: false,
  showDelete: false,
};

ToolRow.propTypes = {
  data: PropTypes.object.isRequired,

  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,

  showAdd: PropTypes.bool,
  showEdit: PropTypes.bool,
  showDelete: PropTypes.bool,
};

import { useMemo, useState } from 'react';

import { Link } from 'types/Link';

import { useFetchItem } from './useFetchItem';

type useDeleteLinkReturnType = {
  loading: boolean;
  deleteLink: () => void;
  isSuccess: boolean;
};

export const useDeleteLink = (linkToDelete: Link): useDeleteLinkReturnType => {
  const extraOptions = useMemo(
    () => ({
      method: 'PUT',
      body: JSON.stringify({ linkUuid: linkToDelete.uuid }),
    }),
    [linkToDelete],
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const onSuccess = (): void => setIsSuccess(true);

  const { loading, refetch } = useFetchItem<Link[]>({
    url: `/api/users/custom_links/update`,
    errorMessage: 'Failed to delete user link',
    successCallback: onSuccess,
    extraOptions,
    autoFetch: false,
  });

  return { loading, deleteLink: refetch, isSuccess };
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';

import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { useTranslation } from 'components/Localization/Localisation';
import { useGetFlagPath } from 'components/utils';
import PublishIcon from 'images/icons/publish.svg';
import { CodeIso } from 'types/Language';

import './CustomButton.css';

interface CustomButtonProps {
  text?: string | number;
  image?: string | JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  alt?: string;
  form?: string;
  className?: string;
  disabled?: boolean;
  positionOfImage?: 'left' | 'right';
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  image,
  onClick,
  alt,
  form,
  className = '',
  disabled = false,
  positionOfImage = 'left',
  ...other
}: CustomButtonProps) => {
  const icon = typeof image === 'string' ? <img src={image} alt={alt} className="custom-button-image" /> : image;

  return (
    <button
      type={form ? 'submit' : 'button'}
      disabled={disabled}
      form={form ? form : ''}
      onClick={onClick}
      className={`custom-button ${className}`}
      {...other}
    >
      <div className="custom-button-content">
        <div className={`custom-button-icon-and-text`}>
          {positionOfImage === 'left' && icon}
          {text && <p className="custom-button-text">{text}</p>}
          {positionOfImage === 'right' && icon}
        </div>
      </div>
    </button>
  );
};

export const DeleteButton: React.FC<CustomButtonProps> = ({
  text,
  image,
  onClick,
  alt,
  form,
  disabled,
}: CustomButtonProps) => (
  <CustomButton
    text={text}
    image={image}
    onClick={onClick}
    alt={alt}
    form={form}
    className={'delete-button'}
    disabled={disabled}
  />
);

export const ActionButton: React.FC<CustomButtonProps> = ({ text, image, onClick, alt, form, disabled }) => (
  <CustomButton
    text={text}
    image={image}
    onClick={onClick}
    alt={alt}
    form={form}
    className={'action-button'}
    disabled={disabled}
  />
);

export const PrimaryButton: React.FC<CustomButtonProps> = ({
  text,
  image,
  onClick,
  alt,
  form,
  className,
  disabled,
  positionOfImage,
}: CustomButtonProps) => (
  <CustomButton
    text={text}
    image={image}
    onClick={onClick}
    alt={alt}
    form={form}
    className={`primary-button ${className ?? ''}`}
    disabled={disabled}
    positionOfImage={positionOfImage}
  />
);

export const PublishButton: React.FC<CustomButtonProps> = ({ onClick, disabled }: CustomButtonProps) => {
  const { t } = useTranslation();

  return (
    <PrimaryButton
      text={t('backoffice.publish')}
      image={PublishIcon}
      onClick={onClick}
      alt="publish icon"
      disabled={disabled}
    />
  );
};

export const UnPublishButton: React.FC<CustomButtonProps> = ({ onClick }: CustomButtonProps) => {
  const { t } = useTranslation();

  return <SecondaryButton text={t('backoffice.unpublish')} onClick={onClick} alt="unpublish icon" />;
};

export const DeleteMediaButton: React.FC<CustomButtonProps> = ({ onClick, disabled }: CustomButtonProps) => {
  const { t } = useTranslation();

  return <SecondaryButton text={t('media.delete')} onClick={onClick} alt="delete media icon" disabled={disabled} />;
};

export const AddMediaButton: React.FC<CustomButtonProps> = ({ onClick, disabled }: CustomButtonProps) => {
  const { t } = useTranslation();

  return <SecondaryButton text={t('media.add')} onClick={onClick} alt="add media icon" disabled={disabled} />;
};

export const PaginationButton: React.FC<CustomButtonProps & { isSelected?: boolean }> = ({
  text,
  onClick,
  isSelected = false,
  image,
  alt,
}) => (
  <CustomButton
    text={text}
    image={image}
    alt={alt}
    onClick={onClick}
    className={`pagination-button ${isSelected ? 'pagination-button-selected' : ''}`}
  />
);

export const BlackCrossButton: React.FC<CustomButtonProps> = ({ onClick }) => (
  <button onClick={onClick} type="button" className="black-cross-button" data-testid="black-cross-button">
    <FontAwesomeIcon icon={['fas', 'xmark']} />
  </button>
);

export const SecondaryButton: React.FC<CustomButtonProps> = ({
  text,
  onClick,
  image,
  alt,
  className,
  disabled,
  positionOfImage,
  ...other
}) => (
  <CustomButton
    text={text}
    image={image}
    alt={alt}
    onClick={onClick}
    className={`secondary-button ${className ? className : ''}`}
    disabled={disabled}
    positionOfImage={positionOfImage}
    {...other}
  />
);

export const CountryButton: React.FC<CustomButtonProps & { country: CodeIso }> = ({
  text,
  country,
  disabled,
  onClick,
}) => {
  const { getFlagPath } = useGetFlagPath();

  return (
    <SecondaryButton
      text={text}
      image={getFlagPath(country)}
      alt={`${country} country flag`}
      disabled={disabled}
      className={'country-button'}
      onClick={onClick}
    />
  );
};

export const LikeButton: React.FC<CustomButtonProps & { isActive?: boolean }> = ({ onClick, isActive, disabled }) => {
  const { t } = useTranslation();

  const isOnMobile = useIsOnMobile();

  return (
    <SecondaryButton
      text={!isOnMobile ? t('like') : ''}
      image={<FontAwesomeIcon icon={['far', 'thumbs-up']} />}
      onClick={onClick}
      className={`like-button ${isActive ? 'active' : ''}`}
      disabled={disabled}
    />
  );
};

export const ShareButton: React.FC<CustomButtonProps> = props => {
  const { t } = useTranslation();

  const isOnMobile = useIsOnMobile();

  return (
    <SecondaryButton
      {...props}
      text={!isOnMobile ? t('share') : ''}
      image={<FontAwesomeIcon icon={['fas', 'share-nodes']} />}
      className="share-button"
    />
  );
};

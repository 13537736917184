import { useCallback, useState } from 'react';

import { receiveUserLinks } from 'data/actions/UserActions';
import fetchApi from 'data/fetchApi';
import { useTypedDispatch, useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { logger } from 'services/logs/logger';
import { AddURLFormValues } from 'types/AddURLFormValues';
import { Link } from 'types/Link';

export const useAddUserLink = (
  successCallback: () => void,
): {
  doFetch: (link: AddURLFormValues) => void;
  isLoading: boolean;
  isError: boolean;
} => {
  const user = useTypedSelector(selectUser);
  const dispatch = useTypedDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const doFetch = useCallback(
    (link: AddURLFormValues) => {
      setIsLoading(true);
      setIsError(false);
      fetchApi('/api/custom_links', {
        method: 'POST',
        body: JSON.stringify(link),
      })
        .then((responseJson: Link) => {
          const newLinks = user.links === null ? [responseJson] : [...user.links, responseJson];
          user.uuid &&
            fetchApi(`/api/users/${user.uuid}`, {
              method: 'PUT',
              body: JSON.stringify({
                links: newLinks,
              }),
            })
              .then(() => {
                dispatch(receiveUserLinks(newLinks));
                successCallback();
              })
              .catch((error: Record<string, unknown>) => {
                logger.error('Failed to add link to user:', error);
                setIsError(true);
              })
              .finally(() => setIsLoading(false));
        })
        .catch((error: Record<string, unknown>) => {
          logger.error('Failed to add link to user:', error);
          setIsError(true);
          setIsLoading(false);
        });
    },
    [dispatch, successCallback, user],
  );

  return { doFetch, isLoading, isError };
};

import sortBy from 'lodash/sortBy';
import React, { useEffect } from 'react';

import { useFetchPublicationScopeToolboxLinks } from 'components/App/hooks/useFetchPublicationScopeToolboxLinks';
import Loader from 'components/Loader/Loader';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { useTypedSelector } from 'data/hooks';
import { selectUserCountryPublicationScope } from 'data/selectors';
import { isFeatureFlagEnabled } from 'helpers/isFeatureFlagEnabled';
import { frenchCodeIso } from 'types/Language';
import { GLOBAL_PUBLICATION_SCOPE_ID } from 'types/PublicationScope';

import { ToolLink } from './ToolLink';

export const ToolkitBody = (): JSX.Element => {
  const {
    isLoading: isLoadingGlobalToolLinks,
    toolboxLinks: fetchedGlobalToolLinks,
    refetch: fetchGlobalToolLinks,
  } = useFetchPublicationScopeToolboxLinks(GLOBAL_PUBLICATION_SCOPE_ID);

  useEffect(() => fetchGlobalToolLinks(), [fetchGlobalToolLinks]);

  const userCountryPublicationScope = useTypedSelector(selectUserCountryPublicationScope);
  const {
    isLoading: isLoadingLocalToolLinks,
    toolboxLinks: fetchedLocalToolLinks,
    refetch: fetchLocalToolLinks,
  } = useFetchPublicationScopeToolboxLinks(userCountryPublicationScope?.id ?? '');

  useEffect(() => {
    if (null !== userCountryPublicationScope && isFeatureFlagEnabled('localFO')) fetchLocalToolLinks();
  }, [fetchLocalToolLinks, userCountryPublicationScope]);

  const { userLanguage } = useUserLanguageContext();

  const isLoadingToolLinks = isLoadingGlobalToolLinks || isLoadingLocalToolLinks;
  if (isLoadingToolLinks)
    return (
      <div className="toolkit-loader-block">
        <Loader />
      </div>
    );

  const sortedGlobalToolLinks = sortBy(fetchedGlobalToolLinks, ['position']);
  const sortedLocalToolLinks = sortBy(fetchedLocalToolLinks, ['position']);

  const toolLinksToShow = [...sortedGlobalToolLinks, ...sortedLocalToolLinks].filter(toolLink => {
    const isMainLinkProvided = null !== toolLink.mainLink && null !== toolLink.mainTitle;
    const isFrenchLinkProvided = null !== toolLink.frenchLink && null !== toolLink.frenchTitle;

    return isMainLinkProvided || isFrenchLinkProvided;
  });

  return (
    <>
      {toolLinksToShow.map((toolLink, toolLinkIndex) => {
        const isUserLegalCompanyFrench = userLanguage === frenchCodeIso;
        const isMainLinkProvided = null !== toolLink.mainLink && null !== toolLink.mainTitle;
        const isFrenchLinkProvided = null !== toolLink.frenchLink && null !== toolLink.frenchTitle;
        const shouldShowFrenchToolLink =
          (isUserLegalCompanyFrench && isFrenchLinkProvided) || (!isUserLegalCompanyFrench && !isMainLinkProvided);

        const link = shouldShowFrenchToolLink ? toolLink.frenchLink : toolLink.mainLink;
        const title = shouldShowFrenchToolLink ? toolLink.frenchTitle : toolLink.mainTitle;

        return (
          <ToolLink
            key={toolLinkIndex}
            link={link ?? ''}
            title={title ?? ''}
            isGlobal={sortedGlobalToolLinks.includes(toolLink)}
          />
        );
      })}
    </>
  );
};

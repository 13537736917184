import compact from 'lodash/compact';
import { useMemo } from 'react';

import { useUserLanguageContext } from 'context/UserLanguageContext';
import { PublishedInternalNews } from 'types/PublishedInternalNews';

import { useFetchCollection } from './useFetchCollection';

interface UseSearchForNewsResult {
  isLoading: boolean;
  isError: boolean;
  news: PublishedInternalNews[] | null;
  totalResults: number;
  searchForNews: () => void;
}

interface UseSearchForNewsProps {
  searchedValue: string;
  itemsPerPage: number;
  page: number;
  frontOfficePublicationScopeId: string;
  onSuccess?: () => void;
  onError?: () => void;
}

export const useSearchForNews = ({
  searchedValue,
  itemsPerPage,
  page,
  frontOfficePublicationScopeId,
  onSuccess,
  onError,
}: UseSearchForNewsProps): UseSearchForNewsResult => {
  const { userLanguage } = useUserLanguageContext();

  const filters = new URLSearchParams();

  filters.append('languageIsoCode', userLanguage);

  const keywordsToSearch = useMemo(() => compact(searchedValue.split(' ')), [searchedValue]);
  keywordsToSearch.forEach(keyword => filters.append('search[]', keyword));

  filters.append('itemsPerPage', itemsPerPage.toString());
  filters.append('page', page.toString());
  filters.append('frontOfficePublicationScope', frontOfficePublicationScopeId);

  const {
    loading: isLoading,
    isError,
    data: news,
    totalItems: totalResults,
    refetch: searchForNews,
  } = useFetchCollection<PublishedInternalNews>({
    url: `/api/published_internal_news?${filters.toString()}`,
    errorMessage: 'Failed to search for news',
    successCallback: onSuccess,
    errorCallback: onError,
    autoFetch: false,
  });

  return { isLoading, isError, news, totalResults, searchForNews };
};

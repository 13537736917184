export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

type ErrorAction = typeof DISPLAY_ERROR | typeof DISMISS_ERROR;

export const displayError = (
  error: unknown,
): {
  type: ErrorAction;
  error: unknown;
} => ({
  type: DISPLAY_ERROR,
  error,
});

export const dismissError = (): {
  type: ErrorAction;
} => ({
  type: DISMISS_ERROR,
});

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';

import './GoBackBody.css';
interface Props {
  wording?: string;
}

export const GoBackBody: React.FC<Props> = ({ wording }) => {
  const { t } = useTranslation();

  return (
    <div className="go-back-container">
      <span className="go-back-icon">
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </span>
      <span>{wording ?? t('go_back_link')}</span>
    </div>
  );
};

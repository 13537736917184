import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import useViewTracking from 'analytics/useViewTracking';
import { NewsWithSameThemes } from 'components/App/FrontOffice/Home/NewsWithSameThemes/NewsWithSameThemes';
import { useLikeNews } from 'components/App/hooks/useLikeNews';
import { useUnlikeNews } from 'components/App/hooks/useUnlikeNews';
import { getFrontOfficePath } from 'components/App/utils';
import { LikeButton, ShareButton } from 'components/CustomButton/CustomButton';
import { GoBackLink } from 'components/GoBackLink';
import Loader from 'components/Loader/Loader';
import { ShareModal } from 'components/ShareModal/ShareModal';
import { NewsBlockError } from 'components/UI/Errors/NewsBlockError';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { useOnUnmount } from 'hooks/useOnUnmount';
import { ANALYTICS_LANGUAGE } from 'types/Language';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';

import { NewsDetailsContent, NewsDetailsContentDisplayMode } from './NewsDetailsContent';
import { useFetchPublishedInternalNewsDetails } from './hooks/useFetchPublishedInternalNewsDetails';

import './styles/NewsDetails.css';

interface NewsDetailsUrlParams {
  id: string;
}

export const NewsDetails: React.FC = () => {
  const history = useHistory();

  const { id: newsId } = useParams<NewsDetailsUrlParams>();

  const { userLanguage } = useUserLanguageContext();

  const onLikeButtonClick = (): void => (newsDetails?.hasUserLiked ? doUnlike() : doLike());

  const [shouldOpenShareModal, setShouldOpenShareModal] = useState(false);

  const handleFetchError = useCallback(
    (rejectionError: Record<string, unknown>): void => {
      if (404 === rejectionError.code) {
        history.push(getFrontOfficePath());
      }
    },
    [history],
  );

  const {
    newsDetails,
    isLoading,
    isError: shouldShowError,
    refetch,
  } = useFetchPublishedInternalNewsDetails(newsId, userLanguage, handleFetchError);

  const englishTitle = getSelectedTranslation(newsDetails?.translations, ANALYTICS_LANGUAGE)?.title;

  const { clickNewsReturnButtonTracking, clickUnlikeButtonTracking } = useClickTracking();

  const onLikeSuccess = (): void => {
    newsDetails && (newsDetails.likeCount = newsDetails.likeCount + 1) && (newsDetails.hasUserLiked = true);
  };
  const onUnlikeSuccess = (): void => {
    if (newsDetails !== null) {
      newsDetails.likeCount = newsDetails.likeCount - 1;
      newsDetails.hasUserLiked = false;
      if (englishTitle !== undefined) clickUnlikeButtonTracking(englishTitle);
    }
  };

  const { isLikeLoading, doLike } = useLikeNews(onLikeSuccess, newsDetails?.id);
  const { isUnlikeLoading, doUnlike } = useUnlikeNews(onUnlikeSuccess, newsDetails?.id);

  const { viewNewsDetailTracking, startViewNewsDetailTracking, endViewNewsDetailTracking } = useViewTracking();

  useEffect(() => {
    startViewNewsDetailTracking();
  }, [startViewNewsDetailTracking]);

  useEffect(() => {
    englishTitle && viewNewsDetailTracking(englishTitle, newsDetails?.publicationScopes ?? []);
  }, [
    englishTitle,
    newsDetails?.translations,
    newsDetails?.publicationScopes,
    startViewNewsDetailTracking,
    viewNewsDetailTracking,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch, userLanguage, newsId]);

  useOnUnmount(endViewNewsDetailTracking);

  return (
    <>
      <div className="news-details-container">
        {isLoading ? (
          <div className="news-details-full-height-container">
            <div className="news-details-loader">
              <Loader />
            </div>
          </div>
        ) : shouldShowError ? (
          <div className="news-details-full-height-container">
            <NewsBlockError />
          </div>
        ) : (
          <>
            <div className="news-details-header">
              <GoBackLink
                to={getFrontOfficePath()}
                onClick={() => {
                  if (englishTitle !== undefined) clickNewsReturnButtonTracking(englishTitle);
                }}
              />
              <div className="news-details-header-buttons">
                <ShareButton onClick={() => setShouldOpenShareModal(true)} />
                <LikeButton
                  isActive={newsDetails?.hasUserLiked}
                  onClick={onLikeButtonClick}
                  disabled={isLikeLoading || isUnlikeLoading}
                />
              </div>
            </div>
            <NewsDetailsContent
              news={newsDetails}
              language={userLanguage}
              displayMode={NewsDetailsContentDisplayMode.DETAILS}
            />
          </>
        )}
      </div>
      {!isLoading && newsDetails && <NewsWithSameThemes newsDetails={newsDetails} />}

      <ShareModal
        isOpen={shouldOpenShareModal}
        onClose={() => setShouldOpenShareModal(false)}
        englishTitle={englishTitle}
      />
    </>
  );
};

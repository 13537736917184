import set from 'lodash/fp/set';

// eslint-disable-next-line no-restricted-imports -- FIXME
import {
  RECEIVE_USER_DATA_IN_AD,
  RECEIVE_USER_DATA_IN_ARU,
  RECEIVE_USER_DATA_IN_HRA,
  RECEIVE_USER_DATA_IN_MYCOLAS,
  USER_DELETE_IN_MYCOLAS,
  USER_DELETE_IN_MYCOLAS_ERROR,
  USER_SEARCH_IN_AD_END,
  USER_SEARCH_IN_AD_ERROR,
  USER_SEARCH_IN_AD_START,
  USER_SEARCH_IN_ARU_END,
  USER_SEARCH_IN_ARU_ERROR,
  USER_SEARCH_IN_ARU_START,
  USER_SEARCH_IN_HRA_END,
  USER_SEARCH_IN_HRA_ERROR,
  USER_SEARCH_IN_HRA_START,
  USER_SEARCH_IN_MYCOLAS_END,
  USER_SEARCH_IN_MYCOLAS_ERROR,
  USER_SEARCH_IN_MYCOLAS_START,
} from '../actions/EligibilityActions';

const defaultState = {
  resultSearchAd: { code: null, result: null },
  resultSearchAru: { code: null, result: null },
  resultSearchHra: { code: null, result: null },
  resultSearchMycolas: { code: null, result: null },
  resultDeleteMycolas: { code: null, result: null },
  loadingSearchAd: true,
  loadingSearchAru: true,
  loadingSearchHra: true,
  loadingSearchMycolas: true,
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export default function eligibilityReducer(state = defaultState, action) {
  let newState;

  switch (action.type) {
    case RECEIVE_USER_DATA_IN_AD:
      newState = set(
        'resultSearchAd',
        { code: action.code, result: action.resultSearchAd, loading: action.loading },
        state,
      );
      break;
    case RECEIVE_USER_DATA_IN_ARU:
      newState = set(
        'resultSearchAru',
        { code: action.code, result: action.resultSearchAru, loading: action.loading },
        state,
      );
      break;
    case RECEIVE_USER_DATA_IN_HRA:
      newState = set(
        'resultSearchHra',
        { code: action.code, result: action.resultSearchHra, loading: action.loading },
        state,
      );
      break;
    case RECEIVE_USER_DATA_IN_MYCOLAS:
      newState = set(
        'resultSearchMycolas',
        { code: action.code, result: action.resultSearchMycolas, loading: action.loading },
        state,
      );
      break;
    case USER_DELETE_IN_MYCOLAS:
      newState = set(
        'resultDeleteMycolas',
        { code: action.code, result: action.resultDeleteMycolas, loading: action.loading },
        state,
      );
      break;
    case USER_SEARCH_IN_AD_ERROR:
      newState = set(
        'resultSearchAd',
        { code: action.code, result: action.resultSearchAd, loading: action.loading },
        state,
      );
      break;
    case USER_SEARCH_IN_ARU_ERROR:
      newState = set(
        'resultSearchAru',
        { code: action.code, result: action.resultSearchAru, loading: action.loading },
        state,
      );
      break;
    case USER_SEARCH_IN_HRA_ERROR:
      newState = set(
        'resultSearchHra',
        { code: action.code, result: action.resultSearchHra, loading: action.loading },
        state,
      );
      break;
    case USER_SEARCH_IN_MYCOLAS_ERROR:
      newState = set(
        'resultSearchMycolas',
        { code: action.code, result: action.resultSearchMycolas, loading: action.loading },
        state,
      );
      break;
    case USER_DELETE_IN_MYCOLAS_ERROR:
      newState = set(
        'resultDeleteMycolas',
        { code: action.code, result: action.resultDeleteMycolas, loading: action.loading },
        state,
      );
      break;
    case USER_SEARCH_IN_AD_START:
    case USER_SEARCH_IN_AD_END:
      newState = set('loadingSearchAd', action.loadingSearchAd, state);
      break;
    case USER_SEARCH_IN_ARU_START:
    case USER_SEARCH_IN_ARU_END:
      newState = set('loadingSearchAru', action.loadingSearchAru, state);
      break;
    case USER_SEARCH_IN_HRA_START:
    case USER_SEARCH_IN_HRA_END:
      newState = set('loadingSearchHra', action.loadingSearchHra, state);
      break;
    case USER_SEARCH_IN_MYCOLAS_START:
    case USER_SEARCH_IN_MYCOLAS_END:
      newState = set('loadingSearchMycolas', action.loadingSearchMycolas, state);
      break;

    default:
      newState = Object.assign({}, state);
      break;
  }

  return newState;
}

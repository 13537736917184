import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import useClickTracking from 'analytics/useClickTracking';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { SearchInput } from 'components/SearchInput/SearchInput';
import { countryWithFahrenheit, preventDefaultHandlerForNonInputTarget } from 'components/utils';
import { fetchMeteo } from 'data/actions/MeteoActions';
import fetchApi from 'data/fetchApi';
import { logger } from 'services/logs/logger';
import { City } from 'types/City';
import { FetchApiResponse } from 'types/FetchApiResponse';
import { Forecast } from 'types/Forecast';
import { Meteo } from 'types/Meteo';
import { User } from 'types/User';

import { CitySelector } from './CitySelector';

import './Weather.css';

type Props = React.PropsWithChildren<ConnectedProps<typeof connector>>;

const VALUE_LENGTH_THRESHOLD_FOR_SEARCH = 2;
const DEBOUNCE_DURATION_BEFORE_SEARCH = 300;

const _Weather: React.FC<Props> = ({ user, forecasts, geocode, propsRequestWeather }) => {
  const { t } = useTranslation();

  const { clickChangeWeatherTracking } = useClickTracking();
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [valueFromSearchInput, setValueFromSearchInput] = useState('');
  const [debouncedSearchedValue, setDebouncedSearchedValue] = useState<string | null>(null);

  const [foundCities, setFoundCities] = useState<City[] | null>(null);
  const currentForecast = useRef<Forecast | undefined>(undefined);
  const currentCityAndCountry = useRef<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const shouldShowTemperatureInFahrenheit = isUserCountryUsingFahrenheit(user);

  const [city, setCity] = React.useState<string | undefined>();
  const [country, setCountry] = React.useState<string | undefined>();

  useEffect(() => {
    if (valueFromSearchInput.length < VALUE_LENGTH_THRESHOLD_FOR_SEARCH) {
      setDebouncedSearchedValue(null);

      return;
    }

    const debounce = setTimeout(() => setDebouncedSearchedValue(valueFromSearchInput), DEBOUNCE_DURATION_BEFORE_SEARCH);

    return () => {
      clearTimeout(debounce);
    };
  }, [valueFromSearchInput]);

  useEffect((): void => {
    if (!isSearchActive || !debouncedSearchedValue) {
      setFoundCities(null);

      return;
    }

    const searchEndpoint = `/api/address/search?query=${encodeURIComponent(debouncedSearchedValue)}`;

    fetchApi(searchEndpoint)
      .then((responseJson: FetchApiResponse<City[]>) => {
        setFoundCities(responseJson['hydra:member']);
      })
      .catch((rejectionError: Record<string, unknown>) => {
        setFoundCities([]);
        logger.error('Failed to search address for weather block', rejectionError);
      });
  }, [isSearchActive, debouncedSearchedValue]);

  useEffect(() => {
    if (user.uuid !== undefined) {
      propsRequestWeather();
    }
  }, [user.uuid, propsRequestWeather]);

  useEffect(() => {
    if (geocode.city !== currentCityAndCountry.current) {
      const [geocodeCity, geocodeCountry] = geocode.city.split(',');
      setCity(geocodeCity?.trim());
      setCountry(geocodeCountry?.trim());
      currentCityAndCountry.current = geocode.city;
      currentForecast.current = forecasts[0];
      setIsLoading(false);
    }
  }, [forecasts, geocode]);

  const updateCity = (cityForUpdate: City): void => {
    clickChangeWeatherTracking(cityForUpdate);
    setIsSearchActive(false);
    const cityToDisplay = `${cityForUpdate.city}, ${cityForUpdate.country}`;
    if (cityToDisplay !== currentCityAndCountry.current) {
      setIsLoading(true);
      // TODO: handle case undefined properly
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      fetchApi(`/api/profiles/${user.profile?.uuid}`, {
        method: 'PUT',
        body: JSON.stringify({
          alternativeGeocode: {
            longitude: cityForUpdate.geocode.longitude,
            latitude: cityForUpdate.geocode.latitude,
            city: cityForUpdate.geocode.city + ', ' + cityForUpdate.country,
          },
          alternativeOfficeCity: cityToDisplay,
        }),
      })
        .then(propsRequestWeather)
        .catch((rejectionError: Record<string, unknown>) => {
          logger.error('Failed to update city for user', rejectionError);
        });
    }
  };

  const activateSearch = useCallback((event: MouseEvent) => {
    setIsSearchActive(true);
    preventDefaultHandlerForNonInputTarget(event);
  }, []);

  const deactivateSearch = useCallback(() => {
    // Known issue in React: we need to wait for next tick to know if focus has been taken by a child
    // https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
    setTimeout(() => setIsSearchActive(false));
  }, []);

  return (
    <div
      className="weather-container"
      onBlur={deactivateSearch}
      onMouseDown={activateSearch}
      data-testid="weather-container"
    >
      <div className={`weather${isSearchActive ? ' search-active' : ''}`}>
        <div className={`weather-search-container`}>
          <div className={`weather-search-input${isSearchActive ? ' search-active' : ''}`}>
            <SearchInput
              icon={<FontAwesomeIcon icon={['fas', 'temperature-quarter']} />}
              placeholder={t('weather.search_city')}
              initialValue={currentCityAndCountry.current}
              onValueChange={setValueFromSearchInput}
              hasFocus={isSearchActive}
            />
          </div>
          <div
            className={`weather-infos${isSearchActive ? ' search-active' : ''}${isLoading ? ' loading-overlay' : ''}`}
          >
            <div className="weather-icon">
              <FontAwesomeIcon icon={['fas', 'temperature-quarter']} />
            </div>
            <div className="weather-text-container">
              <span className="weather-forecast">
                {useGetForecast(currentForecast.current, shouldShowTemperatureInFahrenheit)}
              </span>
              <span className="weather-city-country">
                <span className="weather-city">{city}</span>
                <span className="weather-country">, {country}</span>
              </span>
            </div>
            <div className="weather-expand-icon">
              <FontAwesomeIcon icon={['fas', 'caret-down']} />
            </div>
          </div>
          {isLoading && (
            <div className="weather-loader">
              <Loader />
            </div>
          )}
        </div>
        {isSearchActive && currentCityAndCountry.current !== undefined && foundCities !== null && (
          <div className="weather-results" data-testid="weather-results">
            {foundCities.length > 0 ? (
              <CitySelector
                cities={foundCities}
                onClickChildren={updateCity}
                currentCity={currentCityAndCountry.current}
              />
            ) : (
              <span className="weather-no-result">{t('weather.no_result_found')}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const useGetForecast = (forecast: Forecast | undefined, shouldShowTemperatureInFahrenheit: boolean): string => {
  const temperature = forecast?.temperature ?? null;
  const weatherCondition = useGetWeatherCondition(forecast);

  if (!forecast) {
    return '';
  }

  if (temperature !== null) {
    const temperatureToDisplay = shouldShowTemperatureInFahrenheit
      ? `${getTemperatureInFahrenheit(temperature)}°F`
      : `${Math.round(temperature)}°C`;

    return `${temperatureToDisplay}, ${weatherCondition}`;
  }

  return weatherCondition;
};

export const useGetWeatherCondition = (forecast: Forecast | undefined): string => {
  const { t } = useTranslation();

  if (!forecast) {
    return '';
  }

  switch (forecast.icon) {
    case 'partly-cloudy-night':
    case 'cloudy':
    case 'fog':
    case 'sleet':
    case 'partly-cloudy-day':
      return t('weather.cloudy');
    case 'wind':
      return t('weather.windy');
    case 'snow':
      return t('weather.snowy');
    case 'rain':
      return t('weather.rainy');
    default:
      return t('weather.sunny');
  }
};

export const isUserCountryUsingFahrenheit = (user: User): boolean => {
  const userOfficeCountry = user.profile?.officeCity?.toLowerCase().split(',').pop()?.trim();

  if (userOfficeCountry !== undefined) {
    return countryWithFahrenheit.some(country => userOfficeCountry === country);
  }

  const userOfficeLocation = user.profile?.officeLocation?.toLowerCase();

  return countryWithFahrenheit.some(country => userOfficeLocation?.includes(country));
};

const getTemperatureInFahrenheit = (temperature: number): number => {
  return Math.round((temperature * 9) / 5 + 32);
};

const connector = connect(
  (state: { user: User; meteo: Meteo }) => ({
    user: state.user,
    forecasts: state.meteo.forecasts,
    geocode: state.meteo.geocode,
  }),
  dispatch => ({
    //@ts-expect-error TODO: explain why ts-expect-error is needed
    propsRequestWeather: () => dispatch(fetchMeteo()),
  }),
);

export const Weather = connector(_Weather);

import React from 'react';

import LargeTitleIcon from 'images/icons/large-title-icon.svg';
import SmallTitleIcon from 'images/icons/small-title-icon.svg';

import './Title.css';
interface Props {
  title: string;
  size?: 'small' | 'large';
}
export const Title: React.FC<Props> = ({ title, size = 'large' }) => {
  const isTitleSmall = size === 'small';

  return (
    <div className="title-container">
      {isTitleSmall ? (
        <img src={SmallTitleIcon} alt="Small Title Icon" className="small-title-icon" />
      ) : (
        <img src={LargeTitleIcon} alt="Large Title Icon" className="large-title-icon" />
      )}
      <span className={isTitleSmall ? 'small-title-text' : 'large-title-text'}>{title}</span>
    </div>
  );
};

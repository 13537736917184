import { useCallback, useContext } from 'react';

import MatomoContext from './MatomoContext';
import { TrackEventParams, TrackPageViewParams } from './types';

const useMatomo = (): {
  trackEvent: (params: TrackEventParams) => void | undefined;
  trackEvents: () => void | undefined;
  trackPageView: (params?: TrackPageViewParams) => void | undefined;
  pushInstruction: (name: string, ...args: unknown[]) => void;
} => {
  const instance = useContext(MatomoContext);

  const trackPageView = useCallback((params?: TrackPageViewParams) => instance?.trackPageView(params), [instance]);

  const trackEvent = useCallback((params: TrackEventParams) => instance?.trackEvent(params), [instance]);

  const trackEvents = useCallback(() => instance?.trackEvents(), [instance]);

  const pushInstruction = useCallback(
    (name: string, ...args: unknown[]) => {
      instance?.pushInstruction(name, ...args);
    },
    [instance],
  );

  return {
    trackEvent,
    trackEvents,
    trackPageView,
    pushInstruction,
  };
};

export default useMatomo;

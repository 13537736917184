import { useEffect, useState } from 'react';

import fetchApi from 'data/fetchApi';
import { User } from 'types/User';

interface Props {
  errorCallback: (rejectionError: Record<string, unknown>) => void;
  successCallback: (token: User) => void;
  autoFetch?: boolean;
}

export const useFetchToken = ({
  errorCallback,
  successCallback,
  autoFetch,
}: Props): {
  refetch: () => void;
} => {
  const [shouldFetch, setShouldFetch] = useState(autoFetch);

  const refetch = (): void => setShouldFetch(true);

  useEffect(() => {
    if (shouldFetch) {
      setShouldFetch(false);
      fetchApi
        .getToken()
        .then((token: User) => {
          successCallback(token);
        })
        .catch((error: Record<string, unknown>) => {
          errorCallback(error);
        });
    }
  }, [errorCallback, shouldFetch, successCallback]);

  return { refetch };
};

// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';
import React from 'react';
import { AzureAD } from 'react-aad-msal';
import { createRoot } from 'react-dom/client';

import { logger } from 'services/logs/logger';

import { authProvider } from './components/AddSmalAuth/AuthProvider';
import App from './components/App/App';

import './colas-360.css';

logger.init();

const container = document.getElementById('root');
if (container === null) {
  throw new Error('Failed to find the root element');
}

const root = createRoot(container);
root.render(
  //@ts-expect-error The type definition of AzureAD in the package is wrong
  <AzureAD provider={authProvider} forceLogin={true}>
    <App />
  </AzureAD>,
);

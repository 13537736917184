import { useState } from 'react';
import { Provider } from 'react-redux';

import MatomoProvider from 'analytics/MatomoProvider';
import createInstance from 'analytics/instance';
import { GlobalLoader } from 'components/UI/GlobalLoader/GlobalLoader';
import { useUserLanguageContext, withUserLanguageContext } from 'context/UserLanguageContext';
import { displayError } from 'data/actions/ErrorActions';
import { fetchExternalUser, fetchUser } from 'data/actions/UserActions';
import { store } from 'data/store';
import { AppInternal } from 'router/AppInternal/AppInternal';
import { User } from 'types/User';
import { registerFontAwesomeIcons } from 'utils/registerFontAwesomeIcons';

import { AppExternal } from './AppExternal/AppExternal';
import { useFetchToken } from './hooks/useFetchToken';

import './App.css';

registerFontAwesomeIcons();

const App: React.FC = () => {
  const { setUserLanguage, hasUserLanguageBeenSet } = useUserLanguageContext();

  const [isAuthentified, setIsAuthentified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isUserExternal, setIsUserExternal] = useState(false);
  const [hash, setHash] = useState<string | undefined>(undefined);

  const successCallback = (token: User): void => {
    if (!hasUserLanguageBeenSet && token.language !== null) {
      setUserLanguage(token.language);
    }

    const { isExternal, userHash } = token;
    if (!isExternal) {
      setIsAuthentified(true);
      setHash(userHash);

      return void store.dispatch(fetchUser(token));
    }

    setIsAuthentified(true);
    setIsUserExternal(true);

    return void store.dispatch(fetchExternalUser(token));
  };

  const errorCallback = (error: Record<string, unknown>): void => {
    if (error.code === 403 || error.code === '403') {
      setIsAuthentified(false);
      setIsError(true);
      store.dispatch(displayError(error));
    }
  };

  useFetchToken({ errorCallback, successCallback, autoFetch: true });
  if (!isAuthentified && !isError) {
    return <GlobalLoader />;
  }

  if (isUserExternal) {
    return (
      <Provider store={store}>
        <AppExternal />
      </Provider>
    );
  }

  const matomo = createInstance(hash);

  return (
    <MatomoProvider value={matomo}>
      <AppInternal />
    </MatomoProvider>
  );
};
export default withUserLanguageContext(App);

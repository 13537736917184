import React from 'react';

import { GoBackBody } from 'components/UI/GoBackBody/GoBackBody';

import './GoBackButton.css';

interface Props {
  wording?: string;
  onClick: () => void;
}

export const GoBackButton: React.FC<Props> = ({ wording, onClick }) => (
  <div onClick={onClick} className="go-back-button-container">
    <GoBackBody wording={wording} />
  </div>
);

import React from 'react';

import './ThemeDisplayer.css';

interface Props {
  themes: string[];
}

export const ThemeDisplayer: React.FC<Props> = ({ themes }) => (
  <div className="themes-displayer">
    {themes.map((theme, index) => (
      <div className="themes-displayer-theme" key={`${theme}${index}`}>
        {theme}
      </div>
    ))}
  </div>
);

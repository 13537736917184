import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import IconSprite from 'components/Icon/IconSprite';
import { HeaderLanguageSelector } from 'components/LanguageModal/HeaderLanguageSelector';
import LanguageModal from 'components/LanguageModal/LanguageModal';
import { useTranslation } from 'components/Localization/Localisation';
import { Weather } from 'components/Weather/Weather';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { requestFetchLanguage } from 'data/actions/LanguageActions';
import { useTypedSelector } from 'data/hooks';
import { selectLanguages } from 'data/selectors';
import colasLogoSrc from 'images/colas-logo-vector.png';
import { Language, MajorCodeIsoArray } from 'types/Language';

import ToolboxExternal from './ToolboxExternal';

import './styles/AppExternal.css';

export const AppExternal: React.FC = () => {
  const { t } = useTranslation();

  const [showLanguageModal, setShowLanguageModal] = useState(false);

  const { userLanguage } = useUserLanguageContext();

  const dispatch = useDispatch();
  useEffect(() => {
    //@ts-expect-error requestFetchLanguage is still in a js file
    dispatch(requestFetchLanguage());
  }, [dispatch]);

  const majorLanguages: Language[] = useTypedSelector(selectLanguages).filter(({ codeIso }) =>
    MajorCodeIsoArray.some(majorCodeIso => majorCodeIso === codeIso),
  );

  return (
    <div className="app-home-external">
      <div className="site-header-external">
        <div className="logo-container-external">
          <img className="logo-colas-external" src={colasLogoSrc} alt="Colas Logo" />
        </div>
        <Weather />
        <div className="menu-container-external">
          <HeaderLanguageSelector onClick={setShowLanguageModal} language={userLanguage} />
        </div>
      </div>
      <ToolboxExternal />
      <IconSprite />
      <LanguageModal
        isOpen={showLanguageModal}
        title={t('modal-language.title')}
        onClose={() => {
          setShowLanguageModal(false);
          window.location.reload();
        }}
        languages={majorLanguages}
        userCodeIso={userLanguage}
      />
    </div>
  );
};

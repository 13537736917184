import React from 'react';

import { SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import ExclamationCircle from 'images/icons/exclamation_circle.svg';

import './ErrorBlockWithReload.css';

interface ErrorBlockWithReloadProps {
  message: string;
}

export const ErrorBlockWithReload: React.FC<ErrorBlockWithReloadProps> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <div className="error-block-with-reload-container">
      <img src={ExclamationCircle} alt="Round Icon with an Exclamation Circle" />
      <p className="error-block-message">{message}</p>
      <SecondaryButton text={t('refresh_button')} onClick={() => window.location.reload()} />
    </div>
  );
};

import React from 'react';

import './FrontOfficeHomeTab.css';

interface Props {
  text: string;
  isSelected: boolean;
  icon: JSX.Element;
  onClick: () => void;
}

export const FrontOfficeHomeTab: React.FC<Props> = ({ text, isSelected, icon, onClick }) => (
  <div
    className={`frontoffice-home-tab-container ${isSelected ? 'frontoffice-home-tab-container-selected' : ''}`}
    onClick={onClick}
  >
    <>
      {icon}
      <p>{text}</p>
    </>
  </div>
);

import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faBuilding,
  faCaretDown,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faEarth,
  faEnvelope,
  faEye,
  faMagnifyingGlass,
  faMobileScreen,
  faPhone,
  faPlus,
  faShareNodes,
  faStar,
  faTemperatureQuarter,
  faThumbTack,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

export const registerFontAwesomeIcons = (): void => {
  library.add(
    // regular-svg-icons
    faClock,
    faThumbsUp,
    // solid-svg-icons
    faArrowLeft,
    faArrowRight,
    faArrowUpRightFromSquare,
    faArrowDown,
    faBuilding,
    faCaretDown,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDown,
    faCircle,
    faEnvelope,
    faEarth,
    faMagnifyingGlass,
    faMobileScreen,
    faPhone,
    faPlus,
    faStar,
    faTemperatureQuarter,
    faThumbTack,
    faUpRightAndDownLeftFromCenter,
    faUser,
    faXmark,
    faEye,
    faShareNodes,
  );
};

import React, { PropsWithChildren, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { getAdminPath, getBackOfficePath, getFrontOfficePath } from 'components/App/utils';
import { HeaderLanguageSelector } from 'components/LanguageModal/HeaderLanguageSelector';
import LanguageModal from 'components/LanguageModal/LanguageModal';
import { useTranslation } from 'components/Localization/Localisation';
import { Cogwheel } from 'components/UI/Cogwheel/Cogwheel';
import { HeaderButton } from 'components/UI/HeaderLinkAndButton/HeaderButton';
import UserBadge from 'components/UserBadge/UserBadge';
import { Weather } from 'components/Weather/Weather';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { requestFetchLanguage } from 'data/actions/LanguageActions';
import { useHasFetchedUser } from 'hooks/useHasFetchedUser';
import { useIsUserAdmin } from 'hooks/useIsUserAdmin';
import { useIsUserEditor } from 'hooks/useIsUserEditor';
import colasLogoSrc from 'images/colas-logo.svg';
import mobileToolkit from 'images/icons/mobile-toolkit.svg';
import { FrontOfficeRoutes } from 'router/routes';
import { Language } from 'types/Language';

import { ToolkitModal } from './ToolkitModal/ToolkitModal';

import './SiteHeader.css';

type SiteHeaderProps = ConnectedProps<typeof connector>;

const SiteHeader: React.FC<PropsWithChildren<SiteHeaderProps>> = ({
  languages,
  propsRequestFetchLanguage,
  children,
}) => {
  const { t } = useTranslation();

  const { userLanguage } = useUserLanguageContext();
  const isOnMobile = useIsOnMobile();
  const isOnNewsPreviewPage = useRouteMatch(getFrontOfficePath(FrontOfficeRoutes.NEWS_PREVIEW));

  useEffect(() => {
    propsRequestFetchLanguage();
  }, [propsRequestFetchLanguage]);

  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [showToolkitModal, setShowToolkitModal] = useState(false);

  const isUserAdmin = useIsUserAdmin();
  const isUserLoggedIn = useHasFetchedUser();
  const isUserEditor = useIsUserEditor();

  const { clickSiteLogoTracking } = useClickTracking();

  return (
    <div className="site-header-container">
      <div className="site-header">
        <div className="all-menu-lines-container">
          <div className="menu-container">
            <Link to={getFrontOfficePath()} aria-label="Home" onClick={clickSiteLogoTracking}>
              <div className="logo-container">
                <img className="logo" src={colasLogoSrc} alt="" />
              </div>
            </Link>
            {isUserLoggedIn && setShowLanguageModal !== undefined && (
              <HeaderLanguageSelector onClick={setShowLanguageModal} language={userLanguage} />
            )}

            {isOnMobile && (
              <div className="mobile-user-badge">
                <UserBadge />
              </div>
            )}
          </div>

          <div className="mobile-sub-rows-container">
            <div className="menu-container">
              <HeaderButton className="mobile-toolkit" onClick={() => setShowToolkitModal(true)}>
                <img className="mobile-toolkit-logo" src={mobileToolkit} alt="toolkit button for mobile" />
              </HeaderButton>
              <Weather />
            </div>

            <div className="menu-container">
              <nav className="site-menu">
                {children}
                {isUserLoggedIn && (isUserAdmin || isUserEditor) && (
                  <Cogwheel link={isUserAdmin ? getAdminPath() : getBackOfficePath()} />
                )}
              </nav>
              {!isOnMobile && (
                <div className="desktop-user-badge">
                  <UserBadge />
                </div>
              )}
            </div>
          </div>
        </div>

        <LanguageModal
          isOpen={showLanguageModal}
          title={t('modal-language.title')}
          onClose={() => setShowLanguageModal(false)}
          languages={languages}
          userCodeIso={userLanguage}
        />

        <ToolkitModal isOpen={showToolkitModal} onClose={() => setShowToolkitModal(false)} />
      </div>
      {isOnNewsPreviewPage && <div className="preview-overlay" />}
    </div>
  );
};

const connector = connect(
  (state: { languages: Language[] }) => ({
    languages: state.languages,
  }),
  dispatch => ({
    //@ts-expect-error the functions used for the dispatch are not correctly typed with respect to the dispatch of the version of redux used
    propsRequestFetchLanguage: () => dispatch(requestFetchLanguage()),
  }),
);

export default connector(SiteHeader);

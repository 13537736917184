import React from 'react';

import { HeaderLink } from 'components/UI/HeaderLinkAndButton/HeaderLink';
import cogWheelIcon from 'images/cog-wheel.svg';

interface Props {
  link: string;
}
export const Cogwheel: React.FC<Props> = ({ link }) => (
  <HeaderLink to={link} className="active">
    <img src={cogWheelIcon} alt="Cog Wheel" />
  </HeaderLink>
);

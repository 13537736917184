import Config from 'Config';

import MatomoTracker from './MatomoTracker';

const createInstance = (userId: string | undefined): MatomoTracker => {
  return new MatomoTracker({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME
    urlBase: Config.matomo.baseUrl,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME
    siteId: Config.matomo.siteId,
    userId: userId,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME
    trackerUrl: Config.matomo.trackerUrl,
    configurations: {
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  });
};

export default createInstance;

import { InternalNewsPreview } from 'types/InternalNewsPreview';
import { CodeIso } from 'types/Language';

import { useFetchItem } from './useFetchItem';

interface NewsPreviewResponse {
  isLoading: boolean;
  isError: boolean;
  newsPreview: InternalNewsPreview | null;
  fetchNewsPreview: () => void;
}
export const useFetchNewsPreview = (
  newsId: string,
  selectedLanguage: CodeIso,
  onError?: (rejectionError: Record<string, unknown>) => void,
): NewsPreviewResponse => {
  const {
    loading: isLoading,
    isError,
    data: newsPreview,
    refetch: fetchNewsPreview,
  } = useFetchItem<InternalNewsPreview>({
    url: `/api/internal_news_preview/${newsId}?languageIsoCode=${selectedLanguage}`,
    errorMessage: `Failed to search news preview for news with id ${newsId} in language ${selectedLanguage}`,
    errorCallback: onError,
  });

  return { isLoading, isError, newsPreview, fetchNewsPreview };
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import { BlackCrossButton, SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { ContactCommunicationInfo } from 'components/UI/ContactCommunicationInfo/ContactCommunicationInfo';
import { ModalTitle } from 'components/UI/ModalTitle/ModalTitle';
import { Contact } from 'types/Contact';

import './ContactModal.css';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  contactToDisplay: Contact;
}

export const ContactModal: React.FC<Props> = ({ onClose, isOpen, contactToDisplay }) => {
  const { t } = useTranslation();

  const [avatarUrl, setAvatarUrl] = useState<string | null>();

  useEffect(() => {
    setAvatarUrl(contactToDisplay.avatarUrl);
  }, [contactToDisplay.avatarUrl]);

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="contact-modal">
        <div className="contact-modal-title-container">
          <ModalTitle text={t('home.contact-modal-title')} />
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="contact-modal-content">
          <div className="contact-modal-picture">
            {avatarUrl !== null ? (
              <img
                src={avatarUrl}
                alt={`${contactToDisplay?.firstname} ${contactToDisplay?.lastname} portrait`}
                onError={() => {
                  setAvatarUrl(null);
                }}
              />
            ) : (
              <FontAwesomeIcon icon={['fas', 'user']} />
            )}
          </div>
          <div className="contact-modal-infos">
            <p className="contact-modal-name">{`${contactToDisplay?.firstname} ${contactToDisplay?.lastname}`}</p>
            <p className="contact-modal-position">{contactToDisplay?.post}</p>
            <div className="contact-modal-communication-infos">
              {contactToDisplay.company && (
                <ContactCommunicationInfo
                  icon={<FontAwesomeIcon icon={['fas', 'building']} />}
                  text={contactToDisplay.company}
                />
              )}
              {contactToDisplay.phone && (
                <ContactCommunicationInfo
                  icon={<FontAwesomeIcon icon={['fas', 'phone']} />}
                  text={contactToDisplay.phone}
                />
              )}
              {contactToDisplay.mail && (
                <ContactCommunicationInfo
                  icon={<FontAwesomeIcon icon={['fas', 'envelope']} />}
                  text={contactToDisplay.mail}
                />
              )}
              {contactToDisplay.mobilePhone && (
                <ContactCommunicationInfo
                  icon={<FontAwesomeIcon icon={['fas', 'mobile-screen']} />}
                  text={contactToDisplay.mobilePhone}
                />
              )}
            </div>
          </div>
        </div>
        <div className="contact-modal-button">
          <SecondaryButton text={t('home.tools.close')} onClick={onClose} />
        </div>
      </div>
    </ModalWithAnimation>
  );
};

import React from 'react';

import Loader from 'components/Loader/Loader';
import colasLogoSrc from 'images/colas-logo.svg';

import './GlobalLoader.css';

export const GlobalLoader: React.FC = () => (
  <div className="global-loader">
    <img className="global-loader-logo" src={colasLogoSrc} alt="Colas Logo" />
    <div className="loader">
      <Loader />
    </div>
  </div>
);

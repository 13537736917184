import { Language } from 'types/Language';
import { UserCountryPublicationScope, UserPublicationScope } from 'types/PublicationScope';
import { User } from 'types/User';

import { ToolState } from './reducers/toolReducer';
import { RootState } from './store';

export const selectUser = (state: RootState): User => state.user;

export const selectTools = (state: RootState): Partial<ToolState> => state.tools;

export const selectLanguages = (state: RootState): Language[] => state.languages;

export const selectUserPublicationScopes = (state: RootState): UserPublicationScope[] | undefined =>
  state.user.publicationScopes;

export const selectUserCountryPublicationScope = (state: RootState): UserCountryPublicationScope | null =>
  state.user.countryPublicationScope;

import { ThemeTranslation } from 'types/ThemeTranslation';

export const getThemeTranslationsByThemeId = (
  themeTranslations: ThemeTranslation[] | null,
): Record<string, ThemeTranslation[]> => {
  const getThemeId = (themeTranslation: ThemeTranslation): string => themeTranslation.theme['@id'];

  const themeTranslationsByThemeId: Record<string, ThemeTranslation[]> = {};
  if (themeTranslations === null) {
    return themeTranslationsByThemeId;
  }

  return themeTranslations.reduce((currentThemeTranslationsByThemeId, item) => {
    const themeId = getThemeId(item);
    const currentThemeTranslationsForId = currentThemeTranslationsByThemeId[themeId] ?? [];

    currentThemeTranslationsByThemeId[themeId] = [...currentThemeTranslationsForId, item];

    return currentThemeTranslationsByThemeId;
  }, themeTranslationsByThemeId);
};

import fetchApi from 'data/fetchApi';

export const RECEIVE_USER_DATA_IN_AD = 'RECEIVE_USER_DATA_IN_AD';
export const USER_SEARCH_IN_AD_START = 'USER_SEARCH_IN_AD_START';
export const USER_SEARCH_IN_AD_END = 'USER_SEARCH_IN_AD_END';
export const USER_SEARCH_IN_AD_ERROR = 'USER_SEARCH_IN_AD_ERROR';

export const RECEIVE_USER_DATA_IN_ARU = 'RECEIVE_USER_DATA_IN_ARU';
export const USER_SEARCH_IN_ARU_START = 'USER_SEARCH_IN_ARU_START';
export const USER_SEARCH_IN_ARU_END = 'USER_SEARCH_IN_ARU_END';
export const USER_SEARCH_IN_ARU_ERROR = 'USER_SEARCH_IN_ARU_ERROR';

export const RECEIVE_USER_DATA_IN_HRA = 'RECEIVE_USER_DATA_IN_HRA';
export const USER_SEARCH_IN_HRA_START = 'USER_SEARCH_IN_HRA_START';
export const USER_SEARCH_IN_HRA_END = 'USER_SEARCH_IN_HRA_END';
export const USER_SEARCH_IN_HRA_ERROR = 'USER_SEARCH_IN_HRA_ERROR';

export const RECEIVE_USER_DATA_IN_MYCOLAS = 'RECEIVE_USER_DATA_IN_MYCOLAS';
export const USER_SEARCH_IN_MYCOLAS_START = 'USER_SEARCH_IN_MYCOLAS_START';
export const USER_SEARCH_IN_MYCOLAS_END = 'USER_SEARCH_IN_MYCOLAS_END';
export const USER_SEARCH_IN_MYCOLAS_ERROR = 'USER_SEARCH_IN_MYCOLAS_ERROR';
export const USER_DELETE_IN_MYCOLAS_ERROR = 'USER_DELETE_IN_MYCOLAS_ERROR';
export const USER_DELETE_IN_MYCOLAS = 'USER_DELETE_IN_MYCOLAS';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserSearchInAd(code, result) {
  return {
    type: RECEIVE_USER_DATA_IN_AD,
    code: code,
    resultSearchAd: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveErrorUserSearchInAd(code, result) {
  return {
    type: USER_SEARCH_IN_AD_ERROR,
    code: code,
    resultSearchAd: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInAdStart(loading) {
  return {
    type: USER_SEARCH_IN_AD_START,
    loadingSearchAd: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInAdEnd(loading) {
  return {
    type: USER_SEARCH_IN_AD_END,
    loadingSearchAd: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUerSearchInAru(code, result) {
  return {
    type: RECEIVE_USER_DATA_IN_ARU,
    code: code,
    resultSearchAru: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveErrorUerSearchInAru(code, result) {
  return {
    type: USER_SEARCH_IN_ARU_ERROR,
    code: code,
    resultSearchAru: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInAruStart(loading) {
  return {
    type: USER_SEARCH_IN_ARU_START,
    loadingSearchAru: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInAruEnd(loading) {
  return {
    type: USER_SEARCH_IN_ARU_END,
    loadingSearchAru: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserSearchInHra(code, result) {
  return {
    type: RECEIVE_USER_DATA_IN_HRA,
    code: code,
    resultSearchHra: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveErrorUserSearchInHra(code, result) {
  return {
    type: USER_SEARCH_IN_HRA_ERROR,
    code: code,
    resultSearchHra: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInHraStart(loading) {
  return {
    type: USER_SEARCH_IN_HRA_START,
    loadingSearchHra: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInHraEnd(loading) {
  return {
    type: USER_SEARCH_IN_HRA_END,
    loadingSearchHra: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveUserSearchInMycolas(code, result) {
  return {
    type: RECEIVE_USER_DATA_IN_MYCOLAS,
    code: code,
    resultSearchMycolas: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function receiveErrorUserSearchInMycolas(code, result) {
  return {
    type: USER_SEARCH_IN_MYCOLAS_ERROR,
    code: code,
    resultSearchMycolas: result,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInMycolasStart(loading) {
  return {
    type: USER_SEARCH_IN_MYCOLAS_START,
    loadingSearchMycolas: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function userSearchInMycolasEnd(loading) {
  return {
    type: USER_SEARCH_IN_MYCOLAS_END,
    loadingSearchMycolas: loading,
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestSearchUserInAd(login) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    // Build search query
    const searchEndpoint = `/api/ad/${encodeURIComponent(login)}`;
    dispatch(userSearchInAdStart(true));

    return fetchApi(searchEndpoint)
      .then(responseJson => {
        dispatch(receiveUserSearchInAd('200', responseJson));
        dispatch(userSearchInAdEnd(false));
      })
      .catch(error => {
        dispatch(
          receiveErrorUserSearchInAd('404', 'l\'utilisateur "' + login + "\" n'est pas un utilisateur connu de l'AD"),
        );
        dispatch(userSearchInAdEnd(false));
      });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestSearchUserInAru(login) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    // Build search query
    const searchEndpoint = `/api/aru/${encodeURIComponent(login)}`;
    dispatch(userSearchInAruStart(true));

    return fetchApi(searchEndpoint)
      .then(responseJson => {
        dispatch(receiveUerSearchInAru('200', responseJson));
        dispatch(userSearchInAruEnd(false));
      })
      .catch(error => {
        dispatch(
          receiveErrorUerSearchInAru(
            '404',
            'L\'utilisateur "' + login + "\" n'est ni administrateur ni support de l'application",
          ),
        );
        dispatch(userSearchInAruEnd(false));
      });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestSearchUserInHra(login) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    // Build search query
    const searchEndpoint = `/api/hra/${encodeURIComponent(login)}`;
    dispatch(userSearchInHraStart(true));

    return fetchApi(searchEndpoint)
      .then(responseJson => {
        dispatch(receiveUserSearchInHra('200', JSON.stringify(responseJson)));
        dispatch(userSearchInHraEnd(false));
      })
      .catch(error => {
        dispatch(receiveErrorUserSearchInHra('404', 'L\'utilisateur "' + login + '" n\'est pas connu dans HRA'));
        dispatch(userSearchInHraEnd(false));
      });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestSearchUserInMycolas(login) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    // Build search query
    const searchEndpoint = `/api/mycolas/${encodeURIComponent(login)}`;
    dispatch(userSearchInMycolasStart(true));

    return fetchApi(searchEndpoint)
      .then(responseJson => {
        dispatch(receiveUserSearchInMycolas('200', JSON.stringify(responseJson)));
        dispatch(userSearchInMycolasEnd(false));
      })
      .catch(error => {
        dispatch(
          receiveErrorUserSearchInMycolas('404', 'L\'utilisateur "' + login + '" n\'est pas connu dans mycolas'),
        );
        dispatch(userSearchInMycolasEnd(false));
      });
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function requestDeleteUserInMycolas(login) {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    // Build search query
    const searchEndpoint = `/api/delete/${encodeURIComponent(login)}`;
    dispatch(userSearchInMycolasStart(true));

    return fetchApi(searchEndpoint)
      .then(responseJson => {
        dispatch(receiveUserSearchInMycolas('200', JSON.stringify(responseJson)));
        dispatch(userSearchInMycolasEnd(false));
      })
      .catch(error => {
        dispatch(
          receiveErrorUserSearchInMycolas('404', 'L\'utilisateur "' + login + '" n\'a pas été supprimé de mycolas'),
        );
        dispatch(userSearchInMycolasEnd(false));
      });
  };
}

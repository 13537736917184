import { logger } from 'services/logs/logger';

import { TRACK_TYPES } from './constants';
import { CustomDimension, TrackEventParams, TrackPageViewParams, TrackParams, UserOptions } from './types';

declare global {
  interface Window {
    _paq: unknown[];
  }
}

class MatomoTracker {
  mutationObserver?: MutationObserver;

  constructor(userOptions: UserOptions) {
    this.initialize(userOptions);
  }

  initialize = ({
    urlBase,
    siteId,
    userId,
    trackerUrl,
    srcUrl,
    disabled,
    heartBeat,
    linkTracking = true,
    configurations = {},
  }: UserOptions): void => {
    if (!urlBase) {
      logger.error('Matomo urlBase is required.');

      return;
    }
    if (!siteId) {
      logger.error('Matomo siteId is required.');

      return;
    }

    const normalizedUrlBase = !urlBase.endsWith('/') ? `${urlBase}/` : urlBase;

    if (typeof window === 'undefined') {
      return;
    }

    window._paq = window._paq || [];

    if (window._paq.length !== 0) {
      return;
    }

    if (disabled) {
      return;
    }

    this.pushInstruction('setTrackerUrl', trackerUrl ?? `${normalizedUrlBase}matomo.php`);

    this.pushInstruction('setSiteId', siteId);

    if (userId) {
      this.pushInstruction('setUserId', userId);
    }

    Object.entries(configurations).forEach(([name, instructions]) => {
      if (instructions instanceof Array) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.pushInstruction(name, ...instructions);
      } else {
        this.pushInstruction(name, instructions);
      }
    });

    // accurately measure the time spent on the last pageview of a visit
    if (!heartBeat || heartBeat?.active) {
      this.enableHeartBeatTimer(heartBeat?.seconds ?? 15);
    }

    // // measure outbound links and downloads
    // // might not work accurately on SPAs because new links (dom elements) are created dynamically without a server-side page reload.
    this.enableLinkTracking(linkTracking);

    const scriptElement = document.createElement('script');
    const scripts = document.getElementsByTagName('script')[0];

    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = srcUrl ?? `${normalizedUrlBase}matomo.js`;

    if (scripts?.parentNode) {
      scripts.parentNode.insertBefore(scriptElement, scripts);
    }
  };

  enableHeartBeatTimer(seconds: number): void {
    this.pushInstruction('enableHeartBeatTimer', seconds);
  }

  enableLinkTracking(active: boolean): void {
    this.pushInstruction('enableLinkTracking', active);
  }

  trackEventsForElements = (elements: HTMLElement[]): void => {
    if (elements.length) {
      elements.forEach(element => {
        element.addEventListener('click', () => {
          const { matomoCategory, matomoAction, matomoName, matomoValue } = element.dataset;
          if (matomoCategory && matomoAction) {
            this.trackEvent({
              category: matomoCategory,
              action: matomoAction,
              name: matomoName,
              value: Number(matomoValue),
            });
          } else {
            logger.error('data-matomo-category and data-matomo-action are required.');

            return;
          }
        });
      });
    }
  };

  // Tracks events based on data attributes
  trackEvents(): void {
    const matchString = '[data-matomo-event="click"]';
    let firstTime = false;
    if (!this.mutationObserver) {
      firstTime = true;
      this.mutationObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          mutation.addedNodes.forEach(node => {
            // only track HTML elements
            if (!(node instanceof HTMLElement)) return;

            // check the inserted element for being a code snippet
            if (node.matches(matchString)) {
              this.trackEventsForElements([node]);
            }

            const elements = Array.from(node.querySelectorAll<HTMLElement>(matchString));
            this.trackEventsForElements(elements);
          });
        });
      });
    }
    this.mutationObserver.observe(document, { childList: true, subtree: true });

    // Now track all already existing elements
    if (firstTime) {
      const elements = Array.from(document.querySelectorAll<HTMLElement>(matchString));
      this.trackEventsForElements(elements);
    }
  }

  stopObserving(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  // Tracks events
  // https://matomo.org/docs/event-tracking/#tracking-events
  trackEvent({ category, action, name, value, ...otherParams }: TrackEventParams): void {
    if (category && action) {
      this.track({
        data: [TRACK_TYPES.TRACK_EVENT, category, action, name, value],
        ...otherParams,
      });
    } else {
      logger.error('category and action are required.');

      return;
    }
  }

  // Tracks page views
  // https://developer.matomo.org/guides/spa-tracking#tracking-a-new-page-view
  trackPageView(params?: TrackPageViewParams): void {
    this.track({ data: [TRACK_TYPES.TRACK_VIEW], ...params });
  }

  // Sends the tracked page/view/search to Matomo
  track({ data = [], documentTitle = window.document.title, href, customDimensions = false }: TrackParams): void {
    if (data.length) {
      if (customDimensions && Array.isArray(customDimensions) && customDimensions.length) {
        customDimensions.map((customDimension: CustomDimension) => {
          return this.pushInstruction('setCustomDimension', customDimension.id, customDimension.value);
        });
      }
      this.pushInstruction('setCustomUrl', href ?? window.location.href);

      this.pushInstruction('setDocumentTitle', documentTitle);

      this.pushInstruction(...(data as [string, ...unknown[]]));
    }
  }

  /**
   * Pushes an instruction to Matomo for execution, this is equivalent to pushing entries into the `_paq` array.
   *
   * For example:
   *
   * ```ts
   * pushInstruction('setDocumentTitle', document.title)
   * ```
   * Is the equivalent of:
   *
   * ```ts
   * _paq.push(['setDocumentTitle', document.title]);
   * ```
   *
   * @param name The name of the instruction to be executed.
   * @param args The arguments to pass along with the instruction.
   */
  pushInstruction(name: string, ...args: unknown[]): MatomoTracker {
    if (typeof window !== 'undefined') {
      window._paq.push([name, ...args]);
    }

    return this;
  }
}

export default MatomoTracker;

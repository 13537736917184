import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { AvailableToolCarron } from 'components/UI/AvailableToolCarron/AvailableToolCarron';
import { Tool } from 'types/Tool';

import './AvailableCustomToolRow.css';

interface Props {
  availableCustomTool: Tool;
  onAdd: () => void;
}

export const AvailableCustomToolRow: React.FC<Props> = ({ availableCustomTool, onAdd }) => {
  const { t } = useTranslation();

  return (
    <div className="available-custom-tool-row-container">
      <div className="available-custom-tool-row-content">
        <AvailableToolCarron title={availableCustomTool.title} />
        <p className="available-custom-tool-row-title"> {availableCustomTool.title}</p>
      </div>
      <SecondaryButton
        text={t('add')}
        image={<FontAwesomeIcon icon={['fas', 'plus']} className="available-custom-tool-row-button-icon" />}
        alt="Black Plus"
        onClick={onAdd}
      />
    </div>
  );
};

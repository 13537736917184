import React from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { BlackCrossButton, PrimaryButton, SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';

import './ShareModal.css';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  englishTitle?: string;
}

const writeInClipboard = async (textToWrite: string): Promise<void> => {
  await navigator.clipboard.writeText(textToWrite);
};

export const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose, englishTitle }) => {
  const { t } = useTranslation();

  const { clickShareNewsTracking } = useClickTracking();

  const newsLink = window.location.href;
  const handleCopyLinkButtonClick = (): void => {
    void writeInClipboard(newsLink);
    if (englishTitle !== undefined) {
      clickShareNewsTracking(englishTitle);
    }
    onClose();
  };

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="share-modal-container">
        <div className="share-modal-header">
          <span>{t('modal-share.title')}</span>
          <BlackCrossButton onClick={onClose} />
        </div>
        <div className="share-modal-content">
          <span>{t('modal-share.link')}</span>
          <input className="share-modal-link" disabled value={newsLink}></input>
        </div>
        <div className="share-modal-footer">
          <SecondaryButton text={t('cancel')} onClick={onClose} className="share-modal-footer-button" />
          <PrimaryButton
            text={t('copy-link')}
            onClick={handleCopyLinkButtonClick}
            className="share-modal-footer-button"
          />
        </div>
      </div>
    </ModalWithAnimation>
  );
};

import { ChangeEvent, useCallback } from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { ToggleContainer } from 'components/UI/ToggleContainer/ToggleContainer';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { InternalNewsScopeType } from 'types/InternalNewsScopeType';
import { useCountryPublicationScopeDisplayNameWithActivityInLanguage } from 'utils/publicationScopesDisplayNameGetter';
import { isInternalNewsScopeType } from 'utils/typeguard';

interface Props {
  selectedNewsScopeType: InternalNewsScopeType;
  onChangeNewsScopeType: (scopeType: InternalNewsScopeType) => void;
  inputFieldName?: string;
}
export const NewsTypeToggle: React.FC<Props> = ({
  onChangeNewsScopeType,
  selectedNewsScopeType,
  inputFieldName = '',
}) => {
  const { t } = useTranslation();
  const { frontOfficeLocalScope } = useFrontOfficeLocalScopeContext();
  const frontOfficeLocalScopeNameWithActivityDisplayName =
    useCountryPublicationScopeDisplayNameWithActivityInLanguage(frontOfficeLocalScope);

  const handleChangeNewsScope = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      isInternalNewsScopeType(newValue) && onChangeNewsScopeType(newValue);
    },
    [onChangeNewsScopeType],
  );

  return (
    frontOfficeLocalScope && (
      <ToggleContainer>
        <input
          type="radio"
          name={inputFieldName}
          id={`${inputFieldName}-global`}
          value={InternalNewsScopeType.GLOBAL}
          checked={selectedNewsScopeType === InternalNewsScopeType.GLOBAL}
          onChange={handleChangeNewsScope}
        />
        <label htmlFor={`${inputFieldName}-global`}>{t('admin.tools.Group')}</label>
        <input
          type="radio"
          name={inputFieldName}
          id={`${inputFieldName}-local`}
          value={InternalNewsScopeType.LOCAL}
          checked={selectedNewsScopeType === InternalNewsScopeType.LOCAL}
          onChange={handleChangeNewsScope}
        />
        <label htmlFor={`${inputFieldName}-local`}>{frontOfficeLocalScopeNameWithActivityDisplayName}</label>
      </ToggleContainer>
    )
  );
};

import sortBy from 'lodash/fp/sortBy';
import React, { useMemo } from 'react';

import Loader from 'components/Loader/Loader';
import { PublicationScopeSelectorModalScopesListRow } from 'components/PublicationScopeSelectorModalScopesList/PublicationScopeSelectorModalScopesListRow/PublicationScopeSelectorModalScopesListRow';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { PublicationScopeType, PublicationScopeTypeOrder, PublicationScopeWithType } from 'types/PublicationScope';
import { getPublicationScopeDisplayName } from 'utils/publicationScopesDisplayNameGetter';

import './PublicationScopeSelectorModalScopesList.css';

interface PublicationScopeSelectorModalScopesListProps {
  scopes: PublicationScopeWithType[];
  isLoading?: boolean;
  onSelectScope: (newScope: PublicationScopeWithType) => void;
  currentlySelectedScopeId?: string;
}

export const PublicationScopeSelectorModalScopesList: React.FC<PublicationScopeSelectorModalScopesListProps> = ({
  scopes,
  isLoading = false,
  onSelectScope,
  currentlySelectedScopeId,
}) => {
  const { userLanguage } = useUserLanguageContext();

  const currentlySelectedScope = useMemo(
    () => scopes.find(scope => scope.id === currentlySelectedScopeId),
    [scopes, currentlySelectedScopeId],
  );

  const filteredAndOrderedScopes = useMemo(() => {
    const scopesToKeep = scopes.filter(scope =>
      [
        PublicationScopeType.GLOBAL,
        PublicationScopeType.ZONE,
        PublicationScopeType.BUSINESS_UNIT,
        PublicationScopeType.COUNTRY,
      ].includes(scope.type),
    );
    const scopesToKeepWithoutCurrentlySelectedScope = scopesToKeep.filter(
      scope => scope.id !== currentlySelectedScope?.id,
    );

    return sortBy(
      [
        scope => scope.type && PublicationScopeTypeOrder[scope.type],
        scope => getPublicationScopeDisplayName(scope, userLanguage).normalize('NFD'),
      ],
      scopesToKeepWithoutCurrentlySelectedScope,
    );
  }, [scopes, currentlySelectedScope?.id, userLanguage]);

  return (
    <div className="publication-scope-selector-modal-scopes-list-container">
      {isLoading ? (
        <div className="scopes-list-loader-block">
          <div className="scopes-list-loader-container">
            <Loader />
          </div>
        </div>
      ) : (
        <div className="scopes-list-content">
          {currentlySelectedScope && (
            <>
              <PublicationScopeSelectorModalScopesListRow
                scope={currentlySelectedScope}
                key={currentlySelectedScope.id}
                onSelect={onSelectScope}
                isSelected
              />
              <div className="separator" />
            </>
          )}
          {filteredAndOrderedScopes.map(scope => (
            <PublicationScopeSelectorModalScopesListRow scope={scope} key={scope.id} onSelect={onSelectScope} />
          ))}
        </div>
      )}
    </div>
  );
};

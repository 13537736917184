import { hasProperty, hasTypedProperties } from 'utils/typeguard';

export interface PublicationScope {
  '@id': string;
  id: string;
  frenchName: string;
  englishName: string;
  type?: PublicationScopeType;
}

export enum PublicationScopeType {
  GLOBAL = 'GLOBAL',
  ACTIVITY = 'ACTIVITY',
  ZONE = 'ZONE',
  BUSINESS_UNIT = 'BUSINESS_UNIT',
  COUNTRY = 'COUNTRY',
  LEGAL_COMPANY = 'LEGAL_COMPANY',
}

export type PublicationScopeWithType = Required<PublicationScope>;

export type UserPublicationScope = Omit<Required<PublicationScope>, '@id'>;

type PublicationScopeWithIdAndNames = Pick<PublicationScope, 'id' | 'frenchName' | 'englishName'>;
type UserActivityPublicationScope = PublicationScopeWithIdAndNames;
export type UserCountryPublicationScope = PublicationScopeWithIdAndNames & {
  activityParentScope: UserActivityPublicationScope | null;
};

export type NewsDetailsPublicationScope = Pick<PublicationScope, 'id'>;

const isPublicationScopeWithIdAndNames = (element: unknown): element is PublicationScopeWithIdAndNames =>
  hasTypedProperties(element, [
    { name: 'id', type: 'string' },
    { name: 'frenchName', type: 'string' },
    { name: 'englishName', type: 'string' },
  ]);

export const isUserCountryPublicationScope = (element: unknown): element is UserCountryPublicationScope => {
  if (!isPublicationScopeWithIdAndNames(element)) {
    return false;
  }
  if (!hasProperty(element, 'activityParentScope')) {
    return false;
  }

  return isPublicationScopeWithIdAndNames(element.activityParentScope);
};

export type PublicationScopeWithChildrenHierarchy = PublicationScope & {
  childrenScopes?: PublicationScopeWithChildrenHierarchy[];
};

export type PublicationScopeWithChildren = PublicationScope & {
  childrenScopes?: PublicationScope[];
};

export const PublicationScopeTypeOrder: Record<PublicationScopeType, number> = {
  [PublicationScopeType.GLOBAL]: 1,
  [PublicationScopeType.ACTIVITY]: 2,
  [PublicationScopeType.ZONE]: 3,
  [PublicationScopeType.BUSINESS_UNIT]: 4,
  [PublicationScopeType.COUNTRY]: 5,
  [PublicationScopeType.LEGAL_COMPANY]: 6,
};

export const GLOBAL_PUBLICATION_SCOPE_ID = 'COLAS_GROUP';
export const GLOBAL_PUBLICATION_SCOPE_FRENCH_NAME = 'Global (Groupe)';
export const GLOBAL_PUBLICATION_SCOPE_ENGLISH_NAME = 'Global (Group)';

export const GLOBAL_PUBLICATION_SCOPE_IRI = `/api/publication_scopes/${GLOBAL_PUBLICATION_SCOPE_ID}`;
export const GLOBAL_PUBLICATION_SCOPE: PublicationScopeWithType = {
  '@id': GLOBAL_PUBLICATION_SCOPE_IRI,
  id: GLOBAL_PUBLICATION_SCOPE_ID,
  frenchName: GLOBAL_PUBLICATION_SCOPE_FRENCH_NAME,
  englishName: GLOBAL_PUBLICATION_SCOPE_ENGLISH_NAME,
  type: PublicationScopeType.GLOBAL,
};

import React, { MouseEventHandler } from 'react';

import { PrimaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';

import styles from './HeadlineNewsForEmptyLocalScope.module.css';

interface Props {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const HeadlineNewsForEmptyLocalScope: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <PrimaryButton text={t('frontoffice.select_country')} onClick={onClick} />
    </div>
  );
};

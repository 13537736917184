import { LoginType, MsalAuthProvider } from 'react-aad-msal';

import Config from 'Config';

import 'regenerator-runtime/runtime';

const AZURE_CLIENT_ID = Config.azure.AZURE_CLIENT_ID;
const AZURE_REDIRECT_URI = Config.azure.AZURE_REDIRECT_URI;
const AZURE_ENDPOINT = Config.azure.AZURE_ENDPOINT;

// Msal Configurations
const config = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_ENDPOINT,
    redirectUri: AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [`api://${AZURE_CLIENT_ID}/access_as_user`],
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: AZURE_REDIRECT_URI,
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

import { useCallback } from 'react';

import { NewsDetailsPublicationScope } from 'types/PublicationScope';

import { Category } from './categories';
import { CustomDimension } from './types';
import useMatomo from './useMatomo';

const useViewTracking = (): {
  viewContactModalTracking: () => void | undefined;
  viewNewsDetailTracking: (title: string, publicationScopes: NewsDetailsPublicationScope[]) => void | undefined;
  viewNewsMediaTracking: (mediaType: string) => void | undefined;
  startViewMediaTracking: () => void;
  endViewMediaTracking: () => void;
  startViewNewsDetailTracking: () => void;
  endViewNewsDetailTracking: () => void;
  viewHomePageTracking: () => void | undefined;
  startHomePageTracking: () => void;
  endHomePageTracking: () => void;
  viewNotInApplicationTracking: () => void;
  viewAppsTracking: () => void | undefined;
  startViewAppsTracking: () => void;
  endViewAppsTracking: () => void;
  viewChangeLanguageTracking: () => void | undefined;
  startViewChangeLanguageTracking: () => void;
  endViewChangeLanguageTracking: () => void;
  viewTranslationsTracking: () => void | undefined;
  startViewTranslationsTracking: () => void;
  endViewTranslationsTracking: () => void;
  viewThemesTracking: () => void | undefined;
  startViewThemesTracking: () => void;
  endViewThemesTracking: () => void;
} => {
  const { trackPageView, trackEvent } = useMatomo();

  const viewContactModalTracking = useCallback(
    () => trackEvent({ category: Category.CONTACT, action: 'Open contact modal' }),
    [trackEvent],
  );

  const viewNewsDetailTracking = useCallback(
    (title: string, publicationScopes: NewsDetailsPublicationScope[]) => {
      const publicationScopesIds = publicationScopes.map(({ id }) => id).join(' ');
      const publicationScopesDimension: CustomDimension = {
        id: 1,
        value: publicationScopesIds,
      };
      trackPageView({ documentTitle: title, customDimensions: [publicationScopesDimension] });
    },
    [trackPageView],
  );

  const viewNotInApplicationTracking = useCallback(
    () => trackPageView({ documentTitle: 'NOT IN APPLICATION' }),
    [trackPageView],
  );

  const viewNewsMediaTracking = useCallback(
    (mediaType: string) =>
      trackPageView({
        documentTitle: mediaType,
      }),
    [trackPageView],
  );

  const startViewMediaTracking = useCallback((): void => {
    trackEvent({ category: Category.MEDIA, action: 'Open media modal' });
  }, [trackEvent]);

  const endViewMediaTracking = useCallback((): void => {
    trackEvent({ category: Category.MEDIA, action: 'Close media modal' });
  }, [trackEvent]);

  const viewHomePageTracking = useCallback(
    () =>
      trackPageView({
        documentTitle: 'HOME',
        href: 'home',
      }),
    [trackPageView],
  );

  const startHomePageTracking = useCallback((): void => {
    trackEvent({ category: Category.HOME, action: 'Open Home' });
  }, [trackEvent]);

  const endHomePageTracking = useCallback((): void => {
    trackEvent({ category: Category.HOME, action: 'Close Home' });
  }, [trackEvent]);

  const startViewNewsDetailTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS, action: 'Start reading the news' });
  }, [trackEvent]);

  const endViewNewsDetailTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS, action: 'End reading the news' });
  }, [trackEvent]);

  const viewAppsTracking = useCallback(
    () =>
      trackPageView({
        documentTitle: 'MY APPS',
      }),
    [trackPageView],
  );

  const startViewAppsTracking = useCallback((): void => {
    trackEvent({ category: Category.MY_APPS, action: 'Start viewing apps' });
  }, [trackEvent]);

  const endViewAppsTracking = useCallback((): void => {
    trackEvent({ category: Category.MY_APPS, action: 'End viewing apps' });
  }, [trackEvent]);

  const viewChangeLanguageTracking = useCallback(
    () =>
      trackPageView({
        documentTitle: 'CHANGE LANGUAGE',
      }),
    [trackPageView],
  );

  const startViewChangeLanguageTracking = useCallback((): void => {
    trackEvent({ category: Category.LANGUAGE, action: 'Start viewing change language' });
  }, [trackEvent]);

  const endViewChangeLanguageTracking = useCallback((): void => {
    trackEvent({ category: Category.LANGUAGE, action: 'End viewing change language' });
  }, [trackEvent]);

  const viewTranslationsTracking = useCallback(
    () =>
      trackPageView({
        documentTitle: 'TRANSLATIONS',
      }),
    [trackPageView],
  );

  const startViewTranslationsTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS_TRANSLATION, action: 'Start viewing translations page' });
  }, [trackEvent]);

  const endViewTranslationsTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS_TRANSLATION, action: 'End viewing translations page' });
  }, [trackEvent]);

  const viewThemesTracking = useCallback(
    () =>
      trackPageView({
        documentTitle: 'THEMES',
      }),
    [trackPageView],
  );

  const startViewThemesTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS_THEMES, action: 'Start viewing themes' });
  }, [trackEvent]);

  const endViewThemesTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS_THEMES, action: 'End viewing themes' });
  }, [trackEvent]);

  return {
    viewContactModalTracking,
    viewNewsDetailTracking,
    viewNewsMediaTracking,
    startViewMediaTracking,
    endViewMediaTracking,
    startViewNewsDetailTracking,
    endViewNewsDetailTracking,
    viewHomePageTracking,
    startHomePageTracking,
    endHomePageTracking,
    viewNotInApplicationTracking,
    viewAppsTracking,
    startViewAppsTracking,
    endViewAppsTracking,
    viewChangeLanguageTracking,
    startViewChangeLanguageTracking,
    endViewChangeLanguageTracking,
    viewTranslationsTracking,
    startViewTranslationsTracking,
    endViewTranslationsTracking,
    viewThemesTracking,
    startViewThemesTracking,
    endViewThemesTracking,
  };
};

export default useViewTracking;

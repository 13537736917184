import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import useClickTracking from 'analytics/useClickTracking';

import './ToolLink.css';

type Props = {
  title: string;
  link: string;
  isGlobal: boolean;
};

export const ToolLink = ({ link, title, isGlobal }: Props): JSX.Element => {
  const { clickToolkitTracking } = useClickTracking();

  return (
    <div className={`tool-link-container${isGlobal ? ' global-tool-link' : ''}`}>
      <a className="tool-link" href={link} target="_blank" rel="noreferrer" onClick={() => clickToolkitTracking(title)}>
        {title}
        <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
      </a>
    </div>
  );
};

import './AvailableToolCarron.css';

interface Props {
  title: string | undefined;
}
export const AvailableToolCarron: React.FC<Props> = ({ title }) => (
  <div className="available-tool-carron-container">
    <p className="available-tool-carron-icon">{title?.charAt(0)}</p>
  </div>
);

import React from 'react';

import useClickTracking from 'analytics/useClickTracking';

import './styles/BouyguesLink.css';

export enum BackgroundColor {
  GREY = 'grey',
  BLUE = 'blue',
  WHITE = 'white',
}

type Props = {
  url: string;
  img: string;
  alt: string;
  withPadding?: boolean;
  backgroundColor: BackgroundColor;
};

export const BouyguesLink = ({ url, img, alt, withPadding, backgroundColor }: Props): JSX.Element => {
  const { clickBouyguesLinkTracking } = useClickTracking();

  return (
    <a
      className={`bouygues-link-box ${withPadding ? 'with-padding' : ''} background-color-${backgroundColor}`}
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={() => clickBouyguesLinkTracking(alt)}
    >
      <div className="bouygues-link-content">
        <img src={img} alt={alt} />
      </div>
    </a>
  );
};

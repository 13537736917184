import { ANALYTICS_LANGUAGE, CodeIso } from 'types/Language';
import { ThemeTranslation } from 'types/ThemeTranslation';

import { useFetchCollection } from './useFetchCollection';
type useFetchThemeTranslationReturnType = {
  areThemeTranslationsLoading: boolean;
  themeTranslations: ThemeTranslation[] | null;
  areThemeTranslationsInError: boolean;
  refetchThemeTranslations: () => void;
};
export const useFetchThemeTranslation = (language?: CodeIso): useFetchThemeTranslationReturnType => {
  const { loading, data, isError, refetch } = useFetchCollection<ThemeTranslation>({
    url: `/api/theme_translations${
      language ? `?language.codeIso[]=${language}&language.codeIso[]=${ANALYTICS_LANGUAGE}` : ''
    }`,
    errorMessage: 'Failed to fetch theme translation',
  });

  return {
    areThemeTranslationsLoading: loading,
    themeTranslations: data,
    areThemeTranslationsInError: isError,
    refetchThemeTranslations: refetch,
  };
};

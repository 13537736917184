import React from 'react';

import { Thumbnail } from 'components/App/FrontOffice/Thumbnail/Thumbnail';
import { LikeCount } from 'components/UI/LikeCount/LikeCount';
import { ThemeDisplayer } from 'components/UI/ThemeDisplayer/ThemeDisplayer';
import { ViewCount } from 'components/ViewCount/ViewCount';
import { HTML_TAGS_EXCEPT_P_AND_BR } from 'utils/constants';
import { useDisplayLongDate } from 'utils/date';
import { removeEmptyLines, sanitizeContentForDisplay } from 'utils/sanitize';

import './styles/NewsBoxElement.css';

interface Props {
  title: string;
  content: string;
  thumbnail: string;
  themes: string[];
  date: string;
  likeCount: number;
  viewCount: number;
}
export const NewsBoxElement: React.FC<Props> = ({ title, content, thumbnail, themes, date, likeCount, viewCount }) => {
  const sanitizeContent = (contentCarousel: string): string => {
    return removeEmptyLines(sanitizeContentForDisplay(contentCarousel).replace(HTML_TAGS_EXCEPT_P_AND_BR, ''));
  };

  return (
    <div className="news-box-element-container">
      <div className="news-box-thumbnail-container">
        <Thumbnail src={thumbnail} alt={'news item thumbnail'} />
      </div>
      <div className="news-box-element-news">
        <p className="news-box-element-title">{title}</p>
        <p
          className="news-box-element-content"
          dangerouslySetInnerHTML={{
            __html: sanitizeContent(content),
          }}
        />
        <div className="news-box-element-date-and-stats">
          <p className="news-box-element-date">{useDisplayLongDate(date)}</p>
          <div className="news-box-element-stats">
            {viewCount > 0 && <ViewCount count={viewCount} />}
            {likeCount > 0 && <LikeCount likeCount={likeCount} />}
          </div>
        </div>
        <ThemeDisplayer themes={themes} />
      </div>
    </div>
  );
};

import { useEffect, useRef } from 'react';

export const useOnUnmount = (onUnmountCallback: () => void): void => {
  const willUnmount = useRef(false);

  useEffect(() => {
    return () => {
      willUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (willUnmount.current) {
        onUnmountCallback();
      }
    };
  }, [onUnmountCallback]);
};

//TODO: Fix typing
/* eslint-disable */
//@ts-nocheck
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isEmpty from 'validator/lib/isEmpty';

import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import { withTranslation } from 'components/Localization/Localisation';
import Modal from 'components/Modal/Modal';
import ToolRow from 'components/Toolbox/ToolRow/ToolRow';

import './AddToolModal.css';

class AddToolModal extends Component {
  constructor(props) {
    super(props);

    this.onAddLink = this.onAddLink.bind(this);
    this.onLinkFormInputChange = this.onLinkFormInputChange.bind(this);

    this.state = {
      activePane: 'add-tool',
      linkFormState: 'initial',
      linkFormErrors: {
        errorMessages: {
          title: [],
          subtitle: [],
          url: [],
        },
      },
      linkFormValues: {
        title: '',
        subtitle: '',
        url: '',
      },
    };
  }

  render() {
    const props = this.props;
    const { t } = props;

    let modalPane;

    if (this.state.activePane === 'add-tool') {
      let toolList;
      if (props.tools.length > 0) {
        toolList = (
          <div className="frontoffice-tool-list">
            {props.tools.map(tool => (
              <ToolRow key={tool['@id']} data={tool} onAdd={() => props.onToolAdd(tool)} />
            ))}
          </div>
        );
      } else {
        toolList = (
          <div className="frontoffice-tool-list empty">
            <div className="message">{t('modal-add-tool.no_tools_to_add')}</div>
          </div>
        );
      }

      modalPane = <div className="modal-body-pane add-tool">{toolList}</div>;
    } else if (this.state.activePane === 'add-link') {
      modalPane = (
        <div className="modal-body-pane add-link">
          <div>
            <form className={`link-form state-${this.state.linkFormState}`} onSubmit={this.onAddLink} noValidate>
              <label className="form-control">
                <span className="label-value">{t('modal-add-tool.title')}* :</span>
                <input
                  className={this.state.linkFormErrors.errorMessages.title.length > 0 ? 'error' : null}
                  name="title"
                  type="text"
                  onChange={this.onLinkFormInputChange}
                  required
                />
                <div className="error-message">
                  {this.state.linkFormErrors.errorMessages.title.map(error => (
                    <p>{error}</p>
                  ))}
                </div>
              </label>

              <label className="form-control">
                <span className="label-value">{t('modal-add-tool.subtitle')} :</span>
                <input
                  className={this.state.linkFormErrors.errorMessages.subtitle.length > 0 ? 'error' : null}
                  name="subtitle"
                  type="text"
                  onChange={this.onLinkFormInputChange}
                />
                <div className="error-message">
                  {this.state.linkFormErrors.errorMessages.subtitle.map(error => (
                    <p>{error}</p>
                  ))}
                </div>
              </label>

              <label className="form-control">
                <span className="label-value">{t('modal-add-tool.url')}* :</span>
                <input
                  className={this.state.linkFormErrors.errorMessages.url.length > 0 ? 'error' : null}
                  name="url"
                  type="text"
                  onChange={this.onLinkFormInputChange}
                  required
                />
                <div className="error-message">
                  {this.state.linkFormErrors.errorMessages.url.map(error => (
                    <p>{error}</p>
                  ))}
                </div>
              </label>

              <div className="link-form-end">
                <p className="link-form-hint">* {t('modal-add-tool.mandatory_information')}</p>
                <div>
                  <button className="btn-form" type="submit">
                    {t('modal-add-tool.add_tool')} <Loader />
                  </button>
                </div>
                <p className="link-form-message" />
              </div>
            </form>
          </div>
        </div>
      );
    }

    return (
      <Modal name="add-tool" visible={props.visible} onClose={() => this.props.onClose()}>
        {this.props.userIsUpdating && (
          <div className="modal-loader">
            <Loader />
            <p>{t('modal-add-tool.tool_update')}</p>
          </div>
        )}

        <div className="modal-header">
          <div className="modal-header-button-container">
            {/* eslint-disable-next-line react/button-has-type -- FIXME */}
            <button
              className={'modal-header-button ' + (this.state.activePane === 'add-tool' ? 'active' : '')}
              data-pane="add-tool"
              onClick={() => this.setState({ activePane: 'add-tool' })}
            >
              {t('modal-add-tool.add_application')}
            </button>
            {/* eslint-disable-next-line react/button-has-type -- FIXME */}
            <button
              className={'modal-header-button ' + (this.state.activePane === 'add-link' ? 'active' : '')}
              data-pane="add-link"
              onClick={() => this.setState({ activePane: 'add-link' })}
            >
              {t('modal-add-tool.add_url')}
            </button>
          </div>

          <div className="modal-header-close" onClick={() => this.props.onClose()}>
            <Icon name="close" />
          </div>
        </div>
        <div className="modal-body">{modalPane}</div>
      </Modal>
    );
  }

  onLinkFormInputChange(event) {
    const input = event.target;

    const inputName = input.name;
    let inputValue = input.value;

    if (inputName === 'url') {
      inputValue = /^https?:\/\//.test(inputValue) ? inputValue : 'https://' + inputValue;
    }

    const updatedValues = Object.assign({}, this.state.linkFormValues, {
      [inputName]: inputValue,
    });

    this.setState({
      linkFormValues: updatedValues,
    });
  }

  validateLinkData(linkData) {
    const { t } = this.props;

    const validationErrors = {
      errorCount: 0,
      errorMessages: {},
    };

    for (const key in linkData) {
      const value = linkData[key];

      const errorMessages = [];

      switch (key) {
        case 'title':
          if (isEmpty(value)) {
            errorMessages.push(t('modal-add-tool.you_must_enter_title'));
          }

          break;
        case 'subtitle':
          break;
        case 'url':
          if (isEmpty(value)) {
            errorMessages.push(t('modal-add-tool.you_must_enter_url'));
          }

          break;

        default:
          break;
      }

      validationErrors.errorMessages[key] = errorMessages;
      validationErrors.errorCount += errorMessages.length;
    }

    return validationErrors;
  }

  onAddLink(event) {
    event.preventDefault();

    const linkFormValues = this.state.linkFormValues;

    const validation = this.validateLinkData(linkFormValues);
    if (validation.errorCount === 0) {
      this.setState({
        linkFormState: 'submitting',
      });

      this.props.onLinkAdd(linkFormValues).then(response => {
        this.props.onClose();
        this.setState({
          linkFormState: 'initial',
        });
      });

      // reset form
      this.setState({
        linkFormValues: {
          title: '',
          subtitle: '',
          url: '',
        },
      });
    } else {
      this.setState({
        linkFormErrors: validation,
      });
    }
  }
}

AddToolModal.propTypes = {
  tools: PropTypes.array,
  onClose: PropTypes.func,

  onToolAdd: PropTypes.func,
  onLinkAdd: PropTypes.func,
};

export default withTranslation(AddToolModal);

import React, { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import { useFetchPublishedNotInHeadlineNews } from 'components/App/hooks/useFetchPublishedNotInHeadlineNews';
import { useFetchThemeTranslation } from 'components/App/hooks/useFetchThemeTranslation';
import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { getFrontOfficePath } from 'components/App/utils';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { NewsBlockError } from 'components/UI/Errors/NewsBlockError';
import { NewsBoxElement } from 'components/UI/NewsBoxElement/NewsBoxElement';
import { NewsTypeToggle } from 'components/UI/NewsTypeToggle/NewsTypeToggle';
import { NoNewsAvailable } from 'components/UI/NoNewsAvailable/NoNewsAvailable';
import { PaginationButtons } from 'components/UI/PaginationButtons/PaginationButtons';
import { ThemeFilter } from 'components/UI/ThemeFilter/ThemeFilter';
import { Title } from 'components/UI/Title/Title';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { isFeatureFlagEnabled } from 'helpers/isFeatureFlagEnabled';
import { FrontOfficeRoutes } from 'router/routes';
import { InternalNewsScopeType } from 'types/InternalNewsScopeType';
import { ANALYTICS_LANGUAGE, frenchCodeIso } from 'types/Language';
import { GLOBAL_PUBLICATION_SCOPE_ENGLISH_NAME, GLOBAL_PUBLICATION_SCOPE_ID } from 'types/PublicationScope';
import { findThemesByLanguage, getSelectedTranslation } from 'utils/internalNewsFunctions';
import { getCountryPublicationScopeDisplayNameWithActivity } from 'utils/publicationScopesDisplayNameGetter';

import './AllNewsBox.css';

const ITEMS_PER_PAGE = 3;

const MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_DESKTOP = 10;
const MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_MOBILE = 5;

export const AllNewsBox: React.FC = () => {
  const { t } = useTranslation();
  const { frontOfficeLocalScope } = useFrontOfficeLocalScopeContext();
  const frontOfficeLocalScopeAnalyticsNameWithActivity = getCountryPublicationScopeDisplayNameWithActivity(
    ANALYTICS_LANGUAGE,
    frontOfficeLocalScope,
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [newsToDisplayThemeId, setNewsToDisplayThemeId] = useState<number | null>(null);
  const { userLanguage } = useUserLanguageContext();
  const isOnMobile = useIsOnMobile();

  const [newsToDisplayScopeType, setNewsToDisplayScopeType] = useState(InternalNewsScopeType.GLOBAL);

  const newsToDisplayScopeId = useMemo(
    () =>
      newsToDisplayScopeType === InternalNewsScopeType.GLOBAL
        ? GLOBAL_PUBLICATION_SCOPE_ID
        : frontOfficeLocalScope?.id ?? '',
    [frontOfficeLocalScope?.id, newsToDisplayScopeType],
  );

  const { areThemeTranslationsLoading, themeTranslations, areThemeTranslationsInError, refetchThemeTranslations } =
    useFetchThemeTranslation(userLanguage);

  const {
    arePublishedNotInHeadlineNewsInError,
    arePublishedNotInHeadlineNewsLoading,
    refetchPublishedNotInHeadlinelNews,
    publishedNotInHeadlineNews,
    publishedNotInHeadlineNewsTotalItems,
  } = useFetchPublishedNotInHeadlineNews({
    themeId: newsToDisplayThemeId,
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage: currentPage,
    languageIsoCode: userLanguage,
    scope: newsToDisplayScopeId,
  });

  const shouldShowError = useMemo(
    () => arePublishedNotInHeadlineNewsInError || areThemeTranslationsInError,
    [arePublishedNotInHeadlineNewsInError, areThemeTranslationsInError],
  );
  const isLoading = useMemo(
    () => arePublishedNotInHeadlineNewsLoading || areThemeTranslationsLoading,
    [arePublishedNotInHeadlineNewsLoading, areThemeTranslationsLoading],
  );

  const { clickNewsTracking, clickAllNewsPaginationButtonTracking, clickAllNewsScopeFilterTracking } =
    useClickTracking();

  useEffect(() => {
    refetchThemeTranslations();
    refetchPublishedNotInHeadlinelNews();
  }, [refetchPublishedNotInHeadlinelNews, refetchThemeTranslations, userLanguage]);

  useEffect(() => {
    refetchPublishedNotInHeadlinelNews();
  }, [refetchPublishedNotInHeadlinelNews, currentPage, newsToDisplayScopeId]);

  const onChangeNewsScopeType = (scopeType: InternalNewsScopeType): void => {
    if (scopeType !== newsToDisplayScopeType) {
      const publicationScopeAnalyticsName =
        InternalNewsScopeType.GLOBAL === scopeType
          ? GLOBAL_PUBLICATION_SCOPE_ENGLISH_NAME
          : frontOfficeLocalScopeAnalyticsNameWithActivity;
      clickAllNewsScopeFilterTracking(publicationScopeAnalyticsName);
    }
    setCurrentPage(1);
    setNewsToDisplayThemeId(null);
    setNewsToDisplayScopeType(scopeType);
  };
  const onSelectFilter = (themeId: number | null): void => {
    setCurrentPage(1);
    setNewsToDisplayThemeId(themeId);
    refetchPublishedNotInHeadlinelNews();
  };

  return (
    <div className="all-news-box-container">
      <div className={isLoading ? 'loading-overlay' : ''}>
        <div className="all-news-box-header">
          <div className="all-news-box-title-container">
            <Title title={t('news.all_news')} size="small" />
            {!isLoading && !shouldShowError && (
              <p className="all-news-box-articles-number">
                {publishedNotInHeadlineNewsTotalItems} {t('news.news_item')}
              </p>
            )}
          </div>

          {isFeatureFlagEnabled('localFO') && (
            <NewsTypeToggle
              selectedNewsScopeType={newsToDisplayScopeType}
              onChangeNewsScopeType={onChangeNewsScopeType}
              inputFieldName="all-news"
            />
          )}
        </div>

        {shouldShowError ? (
          <NewsBlockError />
        ) : (
          <>
            <div>
              <ThemeFilter
                themeTranslations={themeTranslations}
                selectedFilter={newsToDisplayThemeId}
                onSelectFilter={onSelectFilter}
                selectedLanguage={userLanguage}
              />

              {publishedNotInHeadlineNews?.map(internalNewsItem => {
                const internalNewsTranslationInSelectedLanguage = getSelectedTranslation(
                  internalNewsItem.translations,
                  userLanguage,
                );

                const internalNewsTranslationInAnalyticsLanguage = getSelectedTranslation(
                  internalNewsItem.translations,
                  ANALYTICS_LANGUAGE,
                );

                if (
                  internalNewsTranslationInSelectedLanguage === undefined ||
                  internalNewsTranslationInAnalyticsLanguage === undefined
                ) {
                  return null;
                }

                return (
                  <div className="all-news-box-article" key={internalNewsItem.id}>
                    <NavLink
                      to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${internalNewsItem.id}`)}
                      onClick={_ => clickNewsTracking(internalNewsTranslationInAnalyticsLanguage.title)}
                    >
                      <NewsBoxElement
                        title={internalNewsTranslationInSelectedLanguage.title}
                        content={internalNewsTranslationInSelectedLanguage.text}
                        thumbnail={
                          userLanguage === frenchCodeIso
                            ? internalNewsItem.facultativeThumbnail
                            : internalNewsItem.mainThumbnail
                        }
                        themes={findThemesByLanguage(userLanguage, internalNewsItem.themes)}
                        date={internalNewsItem.publishedAt}
                        likeCount={internalNewsItem.likeCount}
                        viewCount={internalNewsItem.viewCount}
                      />
                    </NavLink>
                  </div>
                );
              })}
              {publishedNotInHeadlineNewsTotalItems === 0 && !isLoading && (
                <NoNewsAvailable text={t('frontoffice.news.no_result_all_news')} />
              )}
            </div>
            <div className="all-news-box-pagination">
              {!isLoading && (
                <PaginationButtons
                  totalItems={publishedNotInHeadlineNewsTotalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  onSelectPage={setCurrentPage}
                  initiallySelectedPage={currentPage}
                  maxNumberOfPageButtonsToDisplay={
                    isOnMobile
                      ? MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_MOBILE
                      : MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY_ON_DESKTOP
                  }
                  onClick={clickAllNewsPaginationButtonTracking}
                />
              )}
            </div>
          </>
        )}
      </div>
      {isLoading && (
        <div className="all-news-box-loader-placeholder">
          <div className="all-news-box-loader">
            <Loader />
          </div>
        </div>
      )}
    </div>
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { SecondaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { PublicationScopeType, PublicationScopeWithType } from 'types/PublicationScope';
import { usePublicationScopeDisplayNameInLanguage } from 'utils/publicationScopesDisplayNameGetter';

import './PublicationScopeSelectorModalScopesListRow.css';

interface PublicationScopeSelectorModalScopesListRowProps {
  scope: PublicationScopeWithType;
  onSelect: (newScope: PublicationScopeWithType) => void;
  isSelected?: boolean;
}

export const PublicationScopeSelectorModalScopesListRow: React.FC<PublicationScopeSelectorModalScopesListRowProps> = ({
  scope,
  onSelect,
  isSelected = false,
}) => {
  const { t } = useTranslation();
  const scopeInLanguage = usePublicationScopeDisplayNameInLanguage(scope);

  const scopeTypeClassName = (() => {
    switch (scope.type) {
      case PublicationScopeType.GLOBAL:
        return 'scope-type-global';
      case PublicationScopeType.ZONE:
        return 'scope-type-zone';
      case PublicationScopeType.BUSINESS_UNIT:
        return 'scope-type-business-unit';
      case PublicationScopeType.COUNTRY:
        return 'scope-type-country';
      default:
        return '';
    }
  })();

  return (
    <div className="publication-scope-selector-modal-scopes-list-row-container">
      <div className="scope-details" data-testid="publication-scope-selector-modal-scopes-list-row">
        <span className={`scope-logo-container ${scopeTypeClassName}`}>
          <FontAwesomeIcon icon={['fas', 'earth']} className="scope-logo" />
        </span>
        <span>{scopeInLanguage}</span>
      </div>
      <SecondaryButton
        text={
          isSelected
            ? t('publication_scope_selector_modal.selected_button')
            : t('publication_scope_selector_modal.select_button')
        }
        className={`select-scope-button ${isSelected ? 'selected' : ''}`}
        onClick={() => !isSelected && onSelect(scope)}
        data-testid="publication-scope-selector-modal-scopes-list-row-select-button"
        disabled={isSelected}
      />
    </div>
  );
};

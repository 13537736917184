import { CodeIso } from 'types/Language';
import { PublishedInternalNews } from 'types/PublishedInternalNews';

import { useFetchCollection } from './useFetchCollection';

type useFetchPublishedNotInHeadlineNewsType = {
  arePublishedNotInHeadlineNewsLoading: boolean;
  arePublishedNotInHeadlineNewsInError: boolean;
  publishedNotInHeadlineNews: PublishedInternalNews[] | null;
  refetchPublishedNotInHeadlinelNews: () => void;
  publishedNotInHeadlineNewsTotalItems: number;
};
interface Props {
  themeId: number | null;
  itemsPerPage: number;
  currentPage: number;
  languageIsoCode?: CodeIso;
  scope?: string;
}
export const useFetchPublishedNotInHeadlineNews = ({
  themeId,
  itemsPerPage,
  currentPage,
  languageIsoCode,
  scope,
}: Props): useFetchPublishedNotInHeadlineNewsType => {
  const filters = new URLSearchParams();
  filters.append('itemsPerPage', itemsPerPage.toString());
  filters.append('page', currentPage.toString());

  if (themeId) filters.append('themes.id', themeId.toString());
  if (languageIsoCode) filters.append('languageIsoCode', languageIsoCode);
  if (scope) filters.append('frontOfficePublicationScope', scope);
  const { loading, isError, data, refetch, totalItems } = useFetchCollection<PublishedInternalNews>({
    url: `/api/published_not_in_headline_internal_news?${filters.toString()}`,
    errorMessage: 'Failed to fetch internal news',
  });

  return {
    arePublishedNotInHeadlineNewsLoading: loading,
    arePublishedNotInHeadlineNewsInError: isError,
    publishedNotInHeadlineNews: data,
    refetchPublishedNotInHeadlinelNews: refetch,
    publishedNotInHeadlineNewsTotalItems: totalItems,
  };
};

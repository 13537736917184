import React from 'react';

import { PublicationScope } from 'types/PublicationScope';
import { usePublicationScopeDisplayNameInLanguage } from 'utils/publicationScopesDisplayNameGetter';

import './PublicationScopeTab.css';

interface PublicationScopeTabProps {
  scope: PublicationScope;
  currentlySelectedScope?: PublicationScope;
  onSelectScope: (newScope: PublicationScope) => void;
  customLabel?: string;
}

export const PublicationScopeTab: React.FC<PublicationScopeTabProps> = ({
  scope,
  currentlySelectedScope,
  onSelectScope,
  customLabel,
}) => {
  const isSelected = scope === currentlySelectedScope;

  const scopeInLanguage = usePublicationScopeDisplayNameInLanguage(scope);

  return (
    <div
      className={`publication-scope-tab-container ${isSelected ? 'selected' : ''}`}
      onClick={() => onSelectScope(scope)}
      data-testid="publication-scope-tab"
    >
      {customLabel ?? scopeInLanguage}
    </div>
  );
};

import omit from 'lodash/omit';
import React from 'react';
import { useForm } from 'react-hook-form';

import useClickTracking from 'analytics/useClickTracking';
import { PrimaryButton } from 'components/CustomButton/CustomButton';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { AddURLFormValues } from 'types/AddURLFormValues';

import { useAddUserLink } from './hooks/useAddUserLink';
import { useGenerateURLFormError } from './utils';

import './AddURLForm.css';

interface Props {
  onSubmitSuccessful: () => void;
}

export const AddURLForm: React.FC<Props> = ({ onSubmitSuccessful }) => {
  const { t } = useTranslation();

  const form = useForm<AddURLFormValues>();
  const { register, handleSubmit } = form;
  const URL_PATTERN = /^https:\/\//;

  const { clickAddUrlButtonTracking } = useClickTracking();
  const { doFetch: addLinkToUser, isLoading, isError } = useAddUserLink(onSubmitSuccessful);
  const onSubmit = (data: AddURLFormValues): void => {
    clickAddUrlButtonTracking(data.url);
    if (data.subtitle === '') {
      addLinkToUser(omit(data, ['subtitle']));

      return;
    }
    addLinkToUser(data);
  };

  return (
    <>
      <div className="loader-container">{isLoading && <Loader />}</div>
      <form
        id="add-url-form"
        //need onSubmit to return a promise so isSubmitting can return the correct value (if not isSubmitting is always false
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
        className={`add-url-form-container ${isLoading && 'loading-overlay'}`}
      >
        {isError && <p className="error">{t('frontoffice.error')}</p>}
        <div>
          <label htmlFor="title-input">{t('modal-add-tool.title')}</label>
          <input
            id="title-input"
            {...register('title', {
              required: true,
            })}
            placeholder={t('frontoffice.enter_link_title')}
          />
          <div className={'error'}>{useGenerateURLFormError(form.formState, 'title')}</div>
        </div>
        <div>
          <label htmlFor="subtitle-input">{`${t('modal-add-tool.subtitle')} (${t('optional')})`}</label>
          <input id="subtitle-input" {...register('subtitle')} placeholder={t('frontoffice.enter_link_subtitle')} />
        </div>
        <div>
          <label htmlFor="url-input">{t('modal-add-tool.url')}</label>
          <input
            id="url-input"
            {...register('url', {
              required: true,
              pattern: {
                value: URL_PATTERN,
                message: t('frontoffice.add_app_form_invalid_url'),
              },
            })}
            placeholder={t('frontoffice.enter_url')}
          />
          <p className={'error'}>{useGenerateURLFormError(form.formState, 'url')}</p>
        </div>
        <PrimaryButton text={t('add')} className="add-url-form-button" form="add-url-form" />
      </form>
    </>
  );
};

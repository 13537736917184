import React from 'react';

import './Snackbar.css';

interface Props {
  text: string;
  buttonText: string;
  onClick: () => void;
  type: 'error' | 'success' | 'info';
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- FIXME
export const Snackbar = ({ text, buttonText, onClick, type }: Props) => (
  <div className={`snackbar-container snackbar-${type}`}>
    <h2 className="snackbar-text">{text}</h2>
    {/* eslint-disable-next-line react/button-has-type -- FIXME */}
    <button className="snackbar-button" onClick={onClick}>
      <div className="snackbar-button-content">
        <p className="snackbar-button-text">{buttonText}</p>
      </div>
    </button>
  </div>
);

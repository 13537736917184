import defaults from 'lodash/defaults';
const defaultConfig = {};

const origin =
  window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');

const Config = defaults(
  {
    apiBase: window?.appConfig?.REACT_APP_API_BASE,
    azure: {
      AZURE_CLIENT_ID: window?.appConfig?.REACT_APP_AZURE_CLIENT_ID,
      AZURE_REDIRECT_URI: origin,
      AZURE_ENDPOINT: window?.appConfig?.REACT_APP_AZURE_ENDPOINT,
    },
    datadog: {
      clientToken: window?.appConfig?.REACT_APP_DATADOG_CLIENT_TOKEN,
      site: window?.appConfig?.REACT_APP_DATADOG_SITE,
      service: window?.appConfig?.REACT_APP_DATADOG_SERVICE,
      env: window?.appConfig?.REACT_APP_DATADOG_ENV,
    },
    imgUrl: window?.appConfig?.REACT_APP_IMG_URL,
    matomo: {
      siteId: Number(window?.appConfig?.REACT_APP_MATOMO_SITE_ID),
      baseUrl: window?.appConfig?.REACT_APP_MATOMO_URL_BASE,
      trackerUrl: window?.appConfig?.REACT_APP_MATOMO_TRACKER_URL,
    },
    featureFlag: {
      localFO: window?.appConfig?.REACT_APP_FEATURE_FLAG_LOCAL_FO,
    },
  },
  defaultConfig,
);

export default Config;

import { MouseEvent } from 'react';

import Config from 'Config';
import { useTypedSelector } from 'data/hooks';
import { selectLanguages } from 'data/selectors';
import { CodeIso } from 'types/Language';

export const useGetFlagPath = (): { getFlagPath: (isoCode: CodeIso) => string | undefined } => {
  const languages = useTypedSelector(selectLanguages);
  const getFlagPath = (isoCode: CodeIso): string | undefined => {
    const flagPath = languages.find(language => language.codeIso === isoCode)?.urlImage;
    if (typeof Config.imgUrl !== 'string' || flagPath === undefined) {
      return flagPath;
    }

    return `${Config.imgUrl}${flagPath}`;
  };

  return { getFlagPath };
};

export const getUserInitials = (firstname: string | null, lastname: string | null): string =>
  (firstname && lastname && firstname.charAt(0) + lastname.charAt(0)) ?? '';

export const countryWithFahrenheit = ['usa', 'united states', 'united states of america'];

export const preventDefaultHandlerForNonInputTarget = (event: MouseEvent): void => {
  event.stopPropagation();
  if (!(event.target instanceof HTMLInputElement)) event.preventDefault();
};

import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { HeadlineNews } from 'components/UI/HeadlineNews/HeadlineNews';
import { Title } from 'components/UI/Title/Title';
import { GLOBAL_PUBLICATION_SCOPE_ID } from 'types/PublicationScope';

export const GlobalHeadlineNews: React.FC = () => {
  const { t } = useTranslation();

  return (
    <HeadlineNews
      titleBlock={<Title title={t('news.global_headline_news')} size="small" />}
      publicationScopeId={GLOBAL_PUBLICATION_SCOPE_ID}
    />
  );
};

import { PublicationScope, PublicationScopeWithType, UserPublicationScope } from 'types/PublicationScope';

import { useFetchCollection } from './useFetchCollection';

interface UseFetchPublicationScopesWithDescendantsParams {
  scopes: UserPublicationScope[] | PublicationScope[];
  autoFetch?: boolean;
  onError?: () => void;
}

interface UseFetchPublicationScopesWithDescendantsResult {
  isLoading: boolean;
  isError: boolean;
  publicationScopesWithDescendants: PublicationScopeWithType[] | null;
  fetchPublicationScopesWithDescendants: () => void;
}

export const useFetchPublicationScopesWithDescendants = ({
  scopes,
  autoFetch = false,
  onError,
}: UseFetchPublicationScopesWithDescendantsParams): UseFetchPublicationScopesWithDescendantsResult => {
  const filters = new URLSearchParams();

  scopes.forEach(scope => filters.append('id[]', scope.id));

  const {
    loading: isLoading,
    isError,
    data: publicationScopesWithDescendants,
    refetch: fetchPublicationScopesWithDescendants,
  } = useFetchCollection<PublicationScopeWithType>({
    url: `/api/publication_scopes_with_descendants?${filters.toString()}`,
    errorMessage: 'Failed to fetch publication scopes with descendants',
    autoFetch,
    errorCallback: onError,
  });

  return { isLoading, isError, publicationScopesWithDescendants, fetchPublicationScopesWithDescendants };
};

import React from 'react';

import { useGetFlagPath } from 'components/utils';
import arrow from 'images/icons/arrow-dropdown.svg';
import { CodeIso } from 'types/Language';

import './HeaderLanguageSelector.css';

interface Props {
  onClick: (showLanguageModal: boolean) => void;
  language: CodeIso;
}

export const HeaderLanguageSelector: React.FC<Props> = ({ onClick, language }) => {
  const { getFlagPath } = useGetFlagPath();

  return (
    <nav className="site-menu" aria-label="Language Selector Nav Menu">
      <button
        type="button"
        id="select-language"
        onClick={event => {
          event.preventDefault();
          onClick(true);
        }}
        className={'active'}
      >
        <div className="flag-selector-label">
          <div className="flag-container flag">
            <img src={getFlagPath(language)} alt="lang" />
          </div>
          <p className="country-name"> {language} </p>
        </div>
        <img className="arrow-icon" src={arrow} alt="arrow icon" />
      </button>
    </nav>
  );
};

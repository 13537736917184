import { Modal, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import useViewTracking from 'analytics/useViewTracking';
import { PrimaryButton, SecondaryButton } from 'components/CustomButton/CustomButton';
import { GoBackButton } from 'components/GoBackButton/GoBackButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ToolList } from 'components/ToolList/ToolList';
import { AddAppModal } from 'components/UI/AddAppModal/AddAppModal';
import { Title } from 'components/UI/Title/Title';
import Edit from 'images/icons/edit.svg';
import WhitePlus from 'images/icons/white-plus.svg';

import './AppsModal.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AppsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const [shouldShowAddAppModal, setShouldShowAddAppModal] = useState(false);
  const [isDeletingMode, setIsDeletingMode] = useState(false);

  const onCloseAddAppModal = (): void => setShouldShowAddAppModal(false);

  const { clickAddAppTracking, clickDeleteAppTracking, clickAppsReturnButtonTracking } = useClickTracking();

  const { viewAppsTracking, startViewAppsTracking, endViewAppsTracking } = useViewTracking();

  useEffect(() => {
    if (isOpen) {
      startViewAppsTracking();
      viewAppsTracking();
    }
  }, [isOpen, startViewAppsTracking, viewAppsTracking]);

  const onCloseAppModal = (): void => {
    clickAppsReturnButtonTracking();
    setIsDeletingMode(false);
    endViewAppsTracking();
    onClose();
  };

  return (
    <>
      <Modal open={isOpen} onClose={onCloseAppModal}>
        <Slide in={isOpen} direction="left" mountOnEnter unmountOnExit easing={'ease-in-out'} timeout={200}>
          <div className="apps-modal-container">
            <div className="apps-modal-header">
              <GoBackButton onClick={onCloseAppModal} />
              <div className="apps-modal-buttons">
                <PrimaryButton
                  text={t('frontoffice.add_app')}
                  image={WhitePlus}
                  alt="a White Plus"
                  onClick={() => {
                    setShouldShowAddAppModal(true);
                    clickAddAppTracking();
                  }}
                  disabled={isDeletingMode}
                />
                {isDeletingMode ? (
                  <PrimaryButton text={t('end')} onClick={() => setIsDeletingMode(false)} />
                ) : (
                  <SecondaryButton
                    text={t('frontoffice.delete_apps')}
                    image={Edit}
                    alt="a Pencil"
                    onClick={() => {
                      setIsDeletingMode(true);
                      clickDeleteAppTracking();
                    }}
                  />
                )}
              </div>
            </div>
            <Title title={t('frontoffice.all_apps')} />
            <ToolList isDeletingMode={isDeletingMode} isInAllAppsModal={true} />
          </div>
        </Slide>
      </Modal>
      <AddAppModal isOpen={shouldShowAddAppModal} onClose={onCloseAddAppModal} />
    </>
  );
};

import { HeadlineInternalNews } from 'types/HeadlineInternalNews';
import { CodeIso } from 'types/Language';

import { useFetchCollection } from './useFetchCollection';

export const useFetchHeadlineNews = (
  publicationScopeId: string,
  selectedLanguage: CodeIso,
  onError?: (rejectionError: Record<string, unknown>) => void,
  onSuccess?: () => void,
): {
  isLoading: boolean;
  isError: boolean;
  headlinesNews: HeadlineInternalNews[] | null;
  fetchHeadlineNews: () => void;
} => {
  const {
    loading,
    isError,
    data,
    refetch: fetchHeadlineNews,
  } = useFetchCollection<HeadlineInternalNews>({
    url: `/api/publication_scopes/${publicationScopeId}/headline_news?languageIsoCode=${selectedLanguage}`,
    errorMessage: 'Failed to get headline news',
    errorCallback: onError,
    successCallback: onSuccess,
    autoFetch: false,
  });

  return { isLoading: loading, isError, headlinesNews: data, fetchHeadlineNews };
};

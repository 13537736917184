import { useCallback } from 'react';

import { City } from 'types/City';
import { CodeIso } from 'types/Language';

import { Category } from './categories';
import { Direction } from './types';
import useMatomo from './useMatomo';

const useClickTracking = (): {
  clickNewsTracking: (title: string) => void;
  clickHeadlineNewsTracking: (title: string) => void;
  clickIndispensableAppTracking: (label: string) => void;
  clickPersonalAppTracking: (label: string) => void;
  clickLinkAppTracking: (label: string) => void;
  clickAllAppsToolTracking: (label: string) => void;
  clickToolkitTracking: (label: string) => void;
  clickBouyguesLinkTracking: (label: string) => void;
  clickHeadlineNewsSwiperArrowTracking: (direction: Direction) => void;
  clickThemeFilterTracking: (label: string) => void;
  clickNewsSameThemeTracking: (title: string) => void;
  clickManageAppsTracking: () => void;
  clickAddAppTracking: () => void;
  clickDeleteAppTracking: () => void;
  clickRelaunchTranslationTracking: () => void;
  clickSearchContactTracking: () => void;
  clickChangeWeatherTracking: (city: City) => void;
  clickChangeLanguageTracking: (selectedLanguage: CodeIso) => void;
  clickSearchNewsButtonTracking: () => void;
  clickNewsSearchButtonInModalTracking: (label: string) => void;
  clickNewsInSearchModalTracking: () => void;
  clickChangeNewsTranslationTracking: (selectedLanguage: CodeIso) => void;
  clickShareNewsTracking: (title: string) => void;
  clickSiteLogoTracking: () => void;
  clickAllNewsPaginationButtonTracking: (page: number) => void;
  clickNewsReturnButtonTracking: (title: string) => void;
  clickNewsMediaTracking: (title: string) => void;
  clickAppsReturnButtonTracking: () => void;
  clickAddAppButtonTracking: (label: string) => void;
  clickAddUrlButtonTracking: (url: string) => void;
  clickUnlikeButtonTracking: (title: string) => void;
  clickAddNewsTracking: (title: string) => void;
  clickTeamsNotificationCheckboxTracking: (title: string) => void;
  clickScheduleTracking: (title: string) => void;
  clickNewsAddThemeTracking: (theme: string, title: string) => void;
  clickAddThemeTracking: (theme: string) => void;
  clickTranslationsCategoryTracking: () => void;
  clickAllNewsScopeFilterTracking: (publicationScopeName: string) => void;
} => {
  const { trackEvent } = useMatomo();

  const clickNewsTracking = useCallback(
    (title: string): void => {
      trackEvent({ category: Category.ALL_NEWS, action: 'News Click', name: title });
    },
    [trackEvent],
  );

  const clickHeadlineNewsTracking = useCallback(
    (title: string): void => {
      trackEvent({ category: Category.HEADLINE_NEWS, action: 'Headline News Click', name: title });
    },
    [trackEvent],
  );

  const clickHeadlineNewsSwiperArrowTracking = useCallback(
    (direction: Direction): void => {
      trackEvent({ category: Category.HEADLINE_NEWS, action: 'Headline News Swiper Arrow Click', name: direction });
    },
    [trackEvent],
  );

  const clickIndispensableAppTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.MY_APPS, action: 'Indispensable App Click', name: label });
    },
    [trackEvent],
  );

  const clickPersonalAppTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.MY_APPS, action: 'Personal App Click', name: label });
    },
    [trackEvent],
  );

  const clickAllAppsToolTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.ALL_APPS, action: 'All Apps Tool Click', name: label });
    },
    [trackEvent],
  );

  const clickManageAppsTracking = useCallback((): void => {
    trackEvent({ category: Category.MY_APPS, action: 'Manage Apps Click' });
  }, [trackEvent]);

  const clickAddAppTracking = useCallback((): void => {
    trackEvent({ category: Category.MY_APPS, action: 'Add App Click' });
  }, [trackEvent]);

  const clickDeleteAppTracking = useCallback((): void => {
    trackEvent({ category: Category.MY_APPS, action: 'Delete App Click' });
  }, [trackEvent]);

  const clickLinkAppTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.MY_APPS, action: 'Link App Click', name: label });
    },
    [trackEvent],
  );

  const clickToolkitTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.TOOLKIT, action: 'Toolbox Click', name: label });
    },
    [trackEvent],
  );

  const clickBouyguesLinkTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.BOUYGUES_LINK, action: 'BouyguesLink Click', name: label });
    },
    [trackEvent],
  );

  const clickThemeFilterTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.THEME_FILTER, action: 'Theme Click', name: label });
    },
    [trackEvent],
  );

  const clickNewsSameThemeTracking = useCallback(
    (title: string): void => {
      trackEvent({ category: Category.NEWS, action: 'News Same Theme Click', name: title });
    },
    [trackEvent],
  );
  const clickRelaunchTranslationTracking = useCallback((): void => {
    trackEvent({ category: Category.NEWS_TRANSLATION, action: 'Relaunch Translation Click' });
  }, [trackEvent]);

  const clickSearchContactTracking = useCallback((): void => {
    trackEvent({ category: Category.CONTACT, action: 'Search Contact Click' });
  }, [trackEvent]);

  const clickChangeWeatherTracking = useCallback(
    (city: City): void => {
      trackEvent({ category: Category.WEATHER, action: 'Change Weather City Click', name: city.city });
    },
    [trackEvent],
  );

  const clickChangeLanguageTracking = useCallback(
    (selectedLanguage: CodeIso): void => {
      trackEvent({ category: Category.LANGUAGE, action: 'Change Language Click', name: selectedLanguage });
    },
    [trackEvent],
  );

  const clickSearchNewsButtonTracking = useCallback((): void => {
    trackEvent({ category: Category.SEARCH_NEWS, action: 'Search News Button Click' });
  }, [trackEvent]);

  const clickNewsSearchButtonInModalTracking = useCallback(
    (label: string): void => {
      trackEvent({ category: Category.SEARCH_NEWS, action: 'News Search Button In Modal Click', name: label });
    },
    [trackEvent],
  );

  const clickNewsInSearchModalTracking = useCallback((): void => {
    trackEvent({ category: Category.SEARCH_NEWS, action: 'News In Search Modal Click' });
  }, [trackEvent]);

  const clickChangeNewsTranslationTracking = useCallback(
    (selectedLanguage: CodeIso): void => {
      trackEvent({
        category: Category.NEWS_TRANSLATION,
        action: 'Change News Translation Click',
        name: selectedLanguage,
      });
    },
    [trackEvent],
  );

  const clickShareNewsTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.SHARE_NEWS,
        action: 'Share News Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickSiteLogoTracking = useCallback((): void => {
    trackEvent({
      category: Category.HOME,
      action: 'Site Logo Click',
    });
  }, [trackEvent]);

  const clickAllNewsPaginationButtonTracking = useCallback(
    (page: number): void => {
      trackEvent({
        category: Category.ALL_NEWS,
        action: 'All News Pagination Button Click',
        name: `Page number ${page}`,
      });
    },
    [trackEvent],
  );

  const clickNewsReturnButtonTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.NEWS,
        action: 'News Return Button Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickNewsMediaTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.NEWS,
        action: 'News Media Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickAppsReturnButtonTracking = useCallback((): void => {
    trackEvent({
      category: Category.MY_APPS,
      action: 'Apps Return Button Click',
    });
  }, [trackEvent]);

  const clickAddAppButtonTracking = useCallback(
    (label: string): void => {
      trackEvent({
        category: Category.ADD_APPS,
        action: 'Add App Button Click',
        name: label,
      });
    },
    [trackEvent],
  );

  const clickAddUrlButtonTracking = useCallback(
    (url: string): void => {
      trackEvent({
        category: Category.ADD_APPS,
        action: 'Add URL Button Click',
        name: url,
      });
    },
    [trackEvent],
  );

  const clickUnlikeButtonTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.NEWS,
        action: 'Unlike Button Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickAddNewsTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.BACK_OFFICE_NEWS,
        action: 'Add News Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickTeamsNotificationCheckboxTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.BACK_OFFICE_NEWS,
        action: `Teams Notification Check`,
        name: title,
      });
    },
    [trackEvent],
  );

  const clickScheduleTracking = useCallback(
    (title: string): void => {
      trackEvent({
        category: Category.BACK_OFFICE_NEWS,
        action: 'Schedule Click',
        name: title,
      });
    },
    [trackEvent],
  );

  const clickNewsAddThemeTracking = useCallback(
    (theme: string, title: string): void => {
      trackEvent({
        category: Category.BACK_OFFICE_NEWS,
        action: 'Add Theme Click',
        name: `${theme} - ${title}`,
      });
    },
    [trackEvent],
  );

  const clickAddThemeTracking = useCallback(
    (theme: string): void => {
      trackEvent({
        category: Category.NEWS_THEMES,
        action: 'Add Theme Click',
        name: theme,
      });
    },
    [trackEvent],
  );

  const clickTranslationsCategoryTracking = useCallback((): void => {
    trackEvent({
      category: Category.NEWS_TRANSLATION,
      action: 'Translations Category Click',
    });
  }, [trackEvent]);

  const clickAllNewsScopeFilterTracking = useCallback(
    (publicationScopeName: string): void => {
      trackEvent({
        category: Category.ALL_NEWS,
        action: 'News Scope Type Filter Click',
        name: publicationScopeName,
      });
    },
    [trackEvent],
  );

  return {
    clickNewsTracking,
    clickHeadlineNewsTracking,
    clickIndispensableAppTracking,
    clickPersonalAppTracking,
    clickLinkAppTracking,
    clickAllAppsToolTracking,
    clickToolkitTracking,
    clickBouyguesLinkTracking,
    clickHeadlineNewsSwiperArrowTracking,
    clickThemeFilterTracking,
    clickNewsSameThemeTracking,
    clickManageAppsTracking,
    clickAddAppTracking,
    clickDeleteAppTracking,
    clickRelaunchTranslationTracking,
    clickSearchContactTracking,
    clickChangeWeatherTracking,
    clickChangeLanguageTracking,
    clickSearchNewsButtonTracking,
    clickNewsSearchButtonInModalTracking,
    clickNewsInSearchModalTracking,
    clickChangeNewsTranslationTracking,
    clickShareNewsTracking,
    clickSiteLogoTracking,
    clickAllNewsPaginationButtonTracking,
    clickNewsReturnButtonTracking,
    clickNewsMediaTracking,
    clickAppsReturnButtonTracking,
    clickAddAppButtonTracking,
    clickAddUrlButtonTracking,
    clickUnlikeButtonTracking,
    clickAddNewsTracking,
    clickTeamsNotificationCheckboxTracking,
    clickScheduleTracking,
    clickNewsAddThemeTracking,
    clickAddThemeTracking,
    clickTranslationsCategoryTracking,
    clickAllNewsScopeFilterTracking,
  };
};

export default useClickTracking;

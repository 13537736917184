import { useFetchItem } from 'components/App/hooks/useFetchItem';
import { InternalNewsDetails } from 'types/InternalNewsDetails';
import { CodeIso } from 'types/Language';

export const useFetchPublishedInternalNewsDetails = (
  newsId: string,
  selectedLanguage: CodeIso,
  onError?: (rejectionError: Record<string, unknown>) => void,
): {
  newsDetails: InternalNewsDetails | null;
  isError: boolean;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, loading, isError, refetch } = useFetchItem<InternalNewsDetails>({
    url: `/api/published_internal_news_details/${newsId}?languageIsoCode=${selectedLanguage}`,
    errorMessage: 'Failed to fetch internal news details',
    errorCallback: onError,
  });

  return { newsDetails: data, isError, isLoading: loading, refetch };
};

import findIndex from 'lodash/findIndex';
import set from 'lodash/fp/set';
import sortBy from 'lodash/fp/sortBy';

import { GroupWithTools } from 'types/Group';
import { Tool } from 'types/Tool';

// eslint-disable-next-line no-restricted-imports -- FIXME
import {
  CREATE_GROUP,
  CREATE_TOOL,
  DELETE_GROUP,
  DELETE_TOOL,
  RECEIVE_CUSTOM_TOOLS,
  RECEIVE_GROUPS,
  RECEIVE_INDISPENSABLE_TOOL_ADMIN,
  RECEIVE_INDISPENSABLE_TOOLS,
  UPDATE_GROUP,
  UPDATE_TOOL,
} from '../actions/ToolActions';

const defaultState: Pick<ToolState, 'indispensableAdmin' | 'indispensable' | 'custom' | 'groups'> = {
  indispensableAdmin: [],
  indispensable: [],
  custom: [],
  groups: [],
};

// Create sort function on position property
const sortPosition = sortBy('position');

type ToolActionType =
  | typeof CREATE_GROUP
  | typeof CREATE_TOOL
  | typeof DELETE_GROUP
  | typeof DELETE_TOOL
  | typeof RECEIVE_CUSTOM_TOOLS
  | typeof RECEIVE_GROUPS
  | typeof RECEIVE_INDISPENSABLE_TOOL_ADMIN
  | typeof RECEIVE_INDISPENSABLE_TOOLS
  | typeof UPDATE_GROUP
  | typeof UPDATE_TOOL;

type ToolAction = {
  type: ToolActionType;
} & ToolState;

export type ToolState = {
  tools: Tool[];
  indispensableAdmin: Tool[];
  indispensable: Tool[];
  tool: Tool;
  groups: GroupWithTools[];
  group: GroupWithTools;
  custom: Tool[];
};

const toolReducer = (state = defaultState, action: ToolAction): Partial<ToolState> => {
  let newState;

  switch (action.type) {
    case RECEIVE_INDISPENSABLE_TOOLS:
      newState = set('indispensable', sortPosition(action.tools), state);
      break;
    case RECEIVE_INDISPENSABLE_TOOL_ADMIN:
      newState = set('indispensableAdmin', sortPosition(action.indispensableAdmin), state);
      break;

    case RECEIVE_CUSTOM_TOOLS:
      newState = set('custom', action.tools, state);
      break;

    case CREATE_TOOL:
      newState = set('indispensableAdmin', state.indispensableAdmin.concat([action.tool]), state);
      break;

    case UPDATE_TOOL:
      // Find tool and update it
      // eslint-disable-next-line no-case-declarations
      const toolIndex = findIndex(state.indispensableAdmin, tool => tool['@id'] === action.tool['@id']);
      newState = set(`indispensableAdmin[${toolIndex}]`, action.tool, state);
      // Resort tools in case positions have changed
      newState = set('indispensableAdmin', sortPosition(newState.indispensableAdmin), newState);
      break;

    case DELETE_TOOL:
      // filter out the deleted tool
      newState = set(
        'indispensableAdmin',
        state.indispensableAdmin.filter(tool => tool['@id'] !== action.tool['@id']),
        state,
      );
      break;

    case RECEIVE_GROUPS:
      newState = set('groups', action.groups, state);
      break;

    case CREATE_GROUP:
      newState = set('groups', state.groups.concat([action.group]), state);
      break;

    case UPDATE_GROUP:
      // eslint-disable-next-line no-case-declarations
      const groupIndex = findIndex(state.groups, group => group['@id'] === action.group['@id']);
      newState = set(`groups[${groupIndex}]`, action.group, state);
      break;

    case DELETE_GROUP:
      newState = set(
        'groups',
        state.groups.filter(group => group['@id'] !== action.group['@id']),
        state,
      );
      break;

    default:
      newState = { ...state };
      break;
  }

  return newState;
};

export default toolReducer;

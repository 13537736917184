import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { useTranslation } from 'components/Localization/Localisation';
import { SearchInput } from 'components/SearchInput/SearchInput';
import fetchApi from 'data/fetchApi';
import { logger } from 'services/logs/logger';
import { Contact } from 'types/Contact';
import { FetchApiResponse } from 'types/FetchApiResponse';

import { ContactSearchResultsRow } from './ContactSearchResultsRow/ContactSearchResultsRow';

import './ContactSearch.css';

interface ContactSearchProps {
  isSearchActive: boolean;
  onSelectContact: (contact: Contact) => void;
  shouldClearSearchInputWhenNotActive?: boolean;
  disableTracking?: boolean;
}

const VALUE_LENGTH_THRESHOLD_FOR_SEARCH = 3;
const DEBOUNCE_DURATION_BEFORE_SEARCH = 300;

export const ContactSearch: React.FC<ContactSearchProps> = ({
  isSearchActive,
  onSelectContact,
  disableTracking = false,
  shouldClearSearchInputWhenNotActive = false,
}) => {
  const { t } = useTranslation();

  const [valueFromSearchInput, setValueFromSearchInput] = useState('');
  const [debouncedSearchedValue, setDebouncedSearchedValue] = useState<string | null>(null);
  const [shouldClearSearchInput, setShouldClearSearchInput] = useState(false);
  const [contacts, setContacts] = useState<Contact[] | null>(null);

  const handleSearchValueChange = (newValue: string): void => {
    setShouldClearSearchInput(false);
    setValueFromSearchInput(newValue);
  };

  const handleClickOnContact = (contact: Contact): void => {
    setShouldClearSearchInput(true);
    onSelectContact(contact);
  };

  const { clickSearchContactTracking } = useClickTracking();

  useEffect(() => {
    if (valueFromSearchInput.length < VALUE_LENGTH_THRESHOLD_FOR_SEARCH) {
      setDebouncedSearchedValue(null);

      return;
    }

    const debounce = setTimeout(() => setDebouncedSearchedValue(valueFromSearchInput), DEBOUNCE_DURATION_BEFORE_SEARCH);

    return () => {
      clearTimeout(debounce);
    };
  }, [valueFromSearchInput]);

  useEffect((): void => {
    if (!debouncedSearchedValue) {
      setContacts(null);

      return;
    }

    const searchEndpoint = `/api/contacts_search?query=${encodeURIComponent(debouncedSearchedValue)}`;

    fetchApi(searchEndpoint)
      .then((responseJson: FetchApiResponse<Contact[]>) => {
        setContacts(responseJson['hydra:member']);
      })
      .catch((error: Record<string, unknown>) => {
        setContacts([]);
        logger.error('Failed to fetch contacts', error);
      })
      .finally(() => {
        if (!disableTracking) {
          clickSearchContactTracking();
        }
      });
  }, [disableTracking, clickSearchContactTracking, debouncedSearchedValue]);

  useEffect(() => {
    if (shouldClearSearchInputWhenNotActive && !isSearchActive) {
      setShouldClearSearchInput(true);
    }
  }, [shouldClearSearchInputWhenNotActive, isSearchActive]);

  return (
    <div className={`contact-search-container${isSearchActive ? ' search-active' : ''}`}>
      <div className="contact-search-input">
        <SearchInput
          icon={<FontAwesomeIcon icon={['fas', 'user']} />}
          placeholder={t('contact-search.search_placeholder')}
          onValueChange={handleSearchValueChange}
          hasFocus={isSearchActive}
          shouldClearSearchInput={shouldClearSearchInput}
        />
      </div>
      {isSearchActive && contacts !== null && (
        <div className="contact-search-results" data-testid="contact-search-results">
          {contacts.length > 0 ? (
            contacts.map(contact => (
              <ContactSearchResultsRow key={contact['@id']} contact={contact} onClick={handleClickOnContact} />
            ))
          ) : (
            <span className="contact-search-no-result">{t('contact-search.no_result_found')}</span>
          )}
        </div>
      )}
    </div>
  );
};

import { useCallback, useEffect, useState } from 'react';

import fetchApi from 'data/fetchApi';
import { logger } from 'services/logs/logger';
import { FetchApiResponse } from 'types/FetchApiResponse';

interface Props {
  url: string;
  errorMessage: string;
  errorCallback?: (rejectionError: Record<string, unknown>) => void;
  successCallback?: () => void;
  extraOptions?: {
    method: string;
    body: string;
    expectResponse: boolean;
  };
  autoFetch?: boolean;
}

export const useFetchCollection = <T>({
  url,
  errorMessage,
  errorCallback,
  successCallback,
  extraOptions,
  autoFetch = true,
}: Props): {
  loading: boolean;
  error: Record<string, unknown> | null;
  data: T[] | null;
  totalItems: number;
  isError: boolean;
  refetch: () => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T[] | null>(null);
  const [error, setError] = useState<Record<string, unknown> | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [shouldFetch, setShouldFetch] = useState(autoFetch);

  const refetch = useCallback(() => {
    setShouldFetch(true);
  }, []);

  useEffect(() => {
    if (shouldFetch) {
      setShouldFetch(false);

      setIsLoading(true);
      setData(null);
      setError(null);

      fetchApi(url, extraOptions)
        .then(
          (responseJson: FetchApiResponse<T[]>) => {
            setData(responseJson['hydra:member']);
            setTotalItems(responseJson['hydra:totalItems']);
            successCallback?.();
          },
          (rejectionError: Record<string, unknown>) => {
            setTotalItems(0);
            setError(rejectionError);
            errorMessage && logger.error(`${errorMessage}: `, rejectionError);
            errorCallback?.(rejectionError);
          },
        )
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [errorCallback, errorMessage, extraOptions, shouldFetch, successCallback, url]);

  return { data, error, loading: isLoading, totalItems, isError: error !== null, refetch };
};

import React from 'react';

import { useTranslation } from 'components/Localization/Localisation';
import { TabToDisplay } from 'components/UI/AddAppModal/AddAppModal';
import { AddAppModalTab } from 'components/UI/AddAppModalTab/AddAppModalTab';

import './AddAppModalTabs.css';

interface Props {
  tabToDisplay: TabToDisplay;
  onClick: (tabValueToSet: TabToDisplay) => void;
}

export const AddAppModalTabs: React.FC<Props> = ({ tabToDisplay, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="add-app-modal-tabs-container">
      <AddAppModalTab
        text={t('modal-add-tool.add_application')}
        isSelected={tabToDisplay === TabToDisplay.ADD_APP}
        onClick={() => onClick(TabToDisplay.ADD_APP)}
      />
      <AddAppModalTab
        text={t('modal-add-tool.add_url')}
        isSelected={tabToDisplay === TabToDisplay.ADD_URL}
        onClick={() => onClick(TabToDisplay.ADD_URL)}
      />
    </div>
  );
};

import React from 'react';
import { NavLink } from 'react-router-dom';

import { GoBackBody } from 'components/UI/GoBackBody/GoBackBody';

import './GoBackLink.css';

interface Props {
  wording?: string;
  to: string;
  onClick?: () => void;
}

export const GoBackLink: React.FC<Props> = ({ to, wording, onClick }) => (
  <NavLink data-testid="go-back-link" to={to} className="go-back-link-container" onClick={onClick}>
    <GoBackBody wording={wording} />
  </NavLink>
);

import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
  color?: string;
  name: string;
}

const Icon: React.FC<IconProps> = ({ width = '100%', height = '100%', color, name }) => (
  <svg className="icon" width={width} height={height} style={{ color }} viewBox="0 0 100 100">
    <use xlinkHref={'#' + name}></use>
  </svg>
);

export default Icon;

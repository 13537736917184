import 'abortcontroller-polyfill';
import jwtDecode from 'jwt-decode';

import Config from 'Config';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';

import { authProvider } from 'components/AddSmalAuth/AuthProvider';
import wordings from 'components/Localization/Localisation';
import ApiError from './ApiError';
import { User } from 'types/User';

type UserToken = User & { exp: number; raw: string };

interface FetchApiOptions extends RequestInit {
  expectResponse?: boolean;
}

interface ApiResponse {
  token: string;
  [key: string]: unknown;
}

export type RejectionError<T extends Error = Error> = T & {
  final?: boolean;
};

let userToken: UserToken | null = null;

const rawStorageToken = getLocalStorageItem('userToken');
if (rawStorageToken) {
  userToken = { ...jwtDecode<UserToken>(rawStorageToken), raw: rawStorageToken };
}

const fetchApi = async <T = any>(apiPath: string, extraOptions: FetchApiOptions = {}): Promise<T> => {
  const token = await fetchApi.getToken();

  const parser = new URL(Config.apiBase);
  const basePath = parser.pathname.startsWith('/') ? parser.pathname : `/${parser.pathname}`;

  let endpoint = `${Config.apiBase}${apiPath}`;
  endpoint = endpoint.replace(`${basePath}${basePath}/`, `${basePath}/`);

  const headers: HeadersInit = {
    accept: 'application/ld+json',
    'content-type': 'application/ld+json',
    Authorization: `Bearer ${token.raw}`,
  };

  const requestOptions: RequestInit = {
    ...extraOptions,
    headers: { ...headers, ...extraOptions.headers } as Record<string, string>,
    credentials: 'include',
  };

  const expectResponse =
    extraOptions.expectResponse !== false &&
    (requestOptions.method === undefined || ['GET', 'POST', 'PUT'].includes(requestOptions.method));

  const response = await fetch(endpoint, requestOptions);

  if (!response.ok) {
    let message = wordings['oops_error'];
    if (response.status === 502) {
      message = wordings['error_json'];
    }

    const responseJson = (await response.json()) as Record<string, any>;
    throw new ApiError(message, response.status, responseJson);
  }

  return expectResponse ? response.json() : null;
};

fetchApi.getToken = async (): Promise<UserToken> => {
  if (userToken && userToken.exp >= Date.now() / 1000) {
    return userToken;
  }

  const token = await authProvider.getAccessToken();
  const endpoint = `${Config.apiBase}/token`;

  const response = await fetch(endpoint, {
    credentials: 'include',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      accept: 'application/ld+json',
      'content-type': 'application/ld+json',
      Authorization: `Bearer ${token.accessToken}`,
    },
  });

  if (!response.url.startsWith(endpoint)) {
    if (response.url.match(/^[A-Z]:\\/i) || response.url.startsWith('file://')) {
      if (window.navigator.platform.match(/window/i)) {
        window.location.href = response.url;
      } else {
        const error: RejectionError = new Error(wordings['error_access_colas_page']);
        error.final = true;
        throw error;
      }
    }

    window.location.href = response.url;
  }

  if (!response.ok) {
    const message = response.status === 502 ? wordings['error_json'] : wordings['error_no_authorize_access_portal'];
    const apiError: RejectionError<ApiError> = new ApiError(message, response.status);
    apiError.final = true;
    throw apiError;
  }

  const responseJson = (await response.json()) as ApiResponse;
  const rawToken = responseJson.token;
  setLocalStorageItem('userToken', rawToken);

  userToken = { ...jwtDecode<UserToken>(rawToken), raw: rawToken };

  return userToken;
};

export default fetchApi;

import { Config, sanitize } from 'dompurify';

const DOMPURIFY_SANITIZE_CONFIG: Config & {
  RETURN_DOM_FRAGMENT?: false | undefined;
  RETURN_DOM?: false | undefined;
} = {
  USE_PROFILES: { html: true },
  ADD_ATTR: ['target'],
};

const DOMPURIFY_SANITIZE_CONFIG_FOR_QUILL: Config & {
  RETURN_DOM_FRAGMENT?: false | undefined;
  RETURN_DOM?: false | undefined;
} = {
  ...DOMPURIFY_SANITIZE_CONFIG,
  ADD_ATTR: [...(DOMPURIFY_SANITIZE_CONFIG.ADD_ATTR ?? []), 'contenteditable'],
};

export const removeEmptyLines = (content: string): string => {
  const newLineHtml = '<p><br></p>';

  return content.split(newLineHtml).filter(Boolean).join('').replaceAll('</p>', '</p><br>');
};

export const sanitizeContentForDisplay = (content: string | Node): string =>
  sanitize(content, DOMPURIFY_SANITIZE_CONFIG);

export const sanitizeContentForQuill = (content: string | Node): string =>
  // We need to run sanitization twice on the content to keep initial attributes order in HTML tags
  // Related issue on DOMPurify: https://github.com/cure53/DOMPurify/issues/276
  sanitize(sanitize(content, DOMPURIFY_SANITIZE_CONFIG_FOR_QUILL), DOMPURIFY_SANITIZE_CONFIG_FOR_QUILL);

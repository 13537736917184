import React, { useState } from 'react';

import { BlackCrossButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ModalWithAnimation } from 'components/Modal/ModalWithAnimation';
import { AddAppModalTabs } from 'components/UI/AddAppModalTabs/AddAppModalTabs';
import { AddURLForm } from 'components/UI/AddURLForm/AddURLForm';
import { AvailableCustomTool } from 'components/UI/AvailableCustomTool/AvailableCustomTool';
import { ModalTitle } from 'components/UI/ModalTitle/ModalTitle';

import './AddAppModal.css';

export enum TabToDisplay {
  ADD_APP = 'ADD_APP',
  ADD_URL = 'ADD_URL',
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AddAppModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const [tabToDisplay, setTabToDisplay] = useState<TabToDisplay>(TabToDisplay.ADD_APP);

  return (
    <ModalWithAnimation isOpen={isOpen} onClose={onClose}>
      <div className="add-app-modal-container">
        <div className="add-app-modal-header">
          <ModalTitle text={t('frontoffice.add_app')} />
          <BlackCrossButton onClick={onClose} />
        </div>
        <AddAppModalTabs onClick={setTabToDisplay} tabToDisplay={tabToDisplay} />
        {tabToDisplay === TabToDisplay.ADD_URL && <AddURLForm onSubmitSuccessful={onClose} />}
        {tabToDisplay === TabToDisplay.ADD_APP && <AvailableCustomTool />}
      </div>
    </ModalWithAnimation>
  );
};

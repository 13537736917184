//TODO: Fix typing
/* eslint-disable */
//@ts-nocheck
import { CSSPlugin, gsap, Power3 } from 'gsap';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import './Modal.css';

// eslint-disable-next-line no-unused-vars -- FIXME
const activated = [gsap, Power3, CSSPlugin];

const modalRoot = document.getElementById('modal-root');

class Modal extends Component {
  constructor(props) {
    super(props);

    this.containerClickHandler = this.containerClickHandler.bind(this);

    this.state = {
      visible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        this.setState({ visible: this.props.visible }, () => this.toggleVisibilty(this.props.visible));
      } else {
        this.toggleVisibilty(this.props.visible).then(() => this.setState({ visible: this.props.visible }));
      }
    }
  }

  toggleVisibilty = visible =>
    new Promise(resolve => {
      if (this.modalWrapper && this.modal) {
        gsap.to(this.modalWrapper, { duration: 0.3, autoAlpha: visible ? 1 : 0 });

        gsap.fromTo(
          this.modal,
          {
            duration: 0.3,
            scale: visible ? 0.9 : 1,
          },
          {
            scale: visible ? 1 : 0.9,
            ease: Power3.easeOut,
            onComplete: resolve,
          },
        );
      } else {
        resolve();
      }
    });

  render() {
    if (this.state.visible) {
      const modalDom = (
        <div ref={el => (this.modalWrapper = el)} className="modal-container" onClick={this.containerClickHandler}>
          <div ref={el => (this.modal = el)} className="modal-content" data-name={this.props.name}>
            {this.props.children}
          </div>
        </div>
      );

      return ReactDOM.createPortal(modalDom, modalRoot);
    } else {
      return null;
    }
  }

  containerClickHandler(event) {
    if (event.target.className === 'modal-container') {
      this.props.onClose();
    }
  }
}

Modal.defaultProps = {
  name: 'default',
};

Modal.propTypes = {
  visible: PropTypes.bool,
  name: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

export default Modal;

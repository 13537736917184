// eslint-disable-next-line no-restricted-imports -- FIXME
import { DISMISS_ERROR, DISPLAY_ERROR } from '../actions/ErrorActions';

const defaultErrorState = {
  current: null,
  log: [],
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
const errorReducer = function (state = defaultErrorState, action) {
  let newState;

  switch (action.type) {
    case DISPLAY_ERROR:
      newState = Object.assign({}, state, {
        current: action.error,
        log: [action.error, ...state.log],
      });
      break;
    case DISMISS_ERROR:
      newState = Object.assign({}, state, {
        current: null,
      });

      break;
    default:
      newState = Object.assign({}, state);
      break;
  }

  return newState;
};

export default errorReducer;

import fetchApi, { RejectionError } from 'data/_fetchApi';

import { displayError } from './ErrorActions';
import { User } from 'types/User';
import { Action, Dispatch } from 'redux';
import { Profile } from 'types/Profile';
import { Tool } from 'types/Tool';
import { Link } from 'types/Link';

// Async fetch actions start/end
export const REQUEST_USER = 'REQUEST_USER';
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_EXTERNAL_USER = 'RECEIVE_EXTERNAL_USER';

export const RECEIVE_USER_TOOLS = 'RECEIVE_USER_TOOLS';
export const RECEIVE_USER_TOOLS_ASYNC = 'RECEIVE_USER_TOOLS_ASYNC';
export const RECEIVE_USER_LINKS = 'RECEIVE_USER_LINKS';

type UserData = Pick<
  User,
  | 'uuid'
  | 'username'
  | 'firstname'
  | 'lastname'
  | 'position'
  | 'language'
  | 'picture'
  | 'roles'
  | 'isEditor'
  | 'isGlobalEditor'
  | 'userHash'
  | 'publicationScopes'
  | 'countryPublicationScope'
  | 'profile'
>;
type ExternalUserData = Pick<User, 'uuid' | 'username' | 'firstname' | 'lastname' | 'position' | 'userHash'>;

type RequestUserAction = Action<typeof REQUEST_USER>;
interface ReceiveUserAction extends Action<typeof RECEIVE_USER> {
  user: UserData;
}
interface ReceiveUserToolsAction extends Action<typeof RECEIVE_USER_TOOLS> {
  tools: Tool[];
}
type ReceiveUserToolsAsyncAction = Action<typeof RECEIVE_USER_TOOLS_ASYNC>;
interface ReceiveUserLinksAction extends Action<typeof RECEIVE_USER_LINKS> {
  links: Link[];
}
interface ReceiveExternalUserAction extends Action<typeof RECEIVE_EXTERNAL_USER> {
  user: ExternalUserData;
}

export const requestUser = (): RequestUserAction => {
  return {
    type: REQUEST_USER,
  };
};

export const receiveUser = (userData: UserData): ReceiveUserAction => {
  return {
    type: RECEIVE_USER,
    user: userData,
  };
};

export const receiveUserTools = (tools: Tool[]): ReceiveUserToolsAction => {
  return {
    type: RECEIVE_USER_TOOLS,
    tools: tools,
  };
};

export const receiveUserToolsAsync = (): ReceiveUserToolsAsyncAction => {
  return {
    type: RECEIVE_USER_TOOLS_ASYNC,
  };
};

export const receiveUserLinks = (links: Link[]): ReceiveUserLinksAction => {
  return {
    type: RECEIVE_USER_LINKS,
    links: links,
  };
};

export const receiveExternalUser = (userData: ExternalUserData): ReceiveExternalUserAction => {
  return {
    type: RECEIVE_EXTERNAL_USER,
    user: userData,
  };
};

/*

ASYNC ACTIONS

 */

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function fetchExternalUser(token: User): (dispatch: Dispatch) => Promise<void> {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    const { uuid, username, firstname, lastname, position, userHash } = token;

    return fetchApi(`/api/users/me/profile`).then(
      (responseJson: Profile) => {
        // compose object with token information and JSON response
        const userData = {
          profile: responseJson,
          uuid,
          username,
          firstname,
          lastname,
          position,
          userHash,
        };

        dispatch(receiveExternalUser(userData));
      },
      (rejectionError: RejectionError) => {
        rejectionError.final = true;
        dispatch(displayError(rejectionError));
      },
    );
  };
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function fetchUser(token: User): (dispatch: Dispatch) => Promise<void> {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    dispatch(requestUser());

    const {
      uuid,
      username,
      roles,
      firstname,
      lastname,
      language,
      position,
      picture,
      isEditor,
      isGlobalEditor,
      userHash,
      publicationScopes,
      countryPublicationScope,
    }: User = token;

    return fetchApi(`/api/users/me/profile`).then(
      (responseJson: Profile) => {
        // compose object with token information and JSON response
        const userData = {
          profile: responseJson,
          uuid,
          username,
          firstname,
          lastname,
          position,
          language,
          picture,
          roles,
          isEditor,
          isGlobalEditor,
          userHash,
          publicationScopes,
          countryPublicationScope,
        };

        dispatch(receiveUser(userData));
      },
      (rejectionError: RejectionError) => {
        rejectionError.final = true;
        dispatch(displayError(rejectionError));
      },
    );
  };
}

// TODO limit update body to desired sub-objects
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
export function updateUser(user: User): (dispatch: Dispatch) => Promise<void> {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
  return function (dispatch) {
    if (!user.uuid) throw new Error('unset uuid for user');

    const requestBody = {
      tools: user.tools.map(tool => tool['@id']),
      links: user.links,
    };

    // Optimistically dispatch action to say tools have been updated
    dispatch(receiveUserTools(user.tools));
    dispatch(receiveUserLinks(user.links));

    return fetchApi(`/api/users/${user.uuid}`, {
      method: 'PUT',
      body: JSON.stringify(requestBody),
    }).then(
      responseJson => {
        dispatch(receiveUserToolsAsync());

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return responseJson;
      },
      rejectionError => {
        dispatch(displayError(rejectionError));
      },
    );
  };
}

import differenceBy from 'lodash/differenceBy';
import { useMemo } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { useAddUserTool } from 'components/App/hooks/useAddUserTool';
import Loader from 'components/Loader/Loader';
import { AvailableCustomToolRow } from 'components/UI/AvailableCustomToolRow/AvailableCustomToolRow';
import { useTypedSelector } from 'data/hooks';
import { selectTools, selectUser } from 'data/selectors';
import { Tool } from 'types/Tool';

import './AvailableCustomTool.css';

export const AvailableCustomTool: React.FC = () => {
  const user = useTypedSelector(selectUser);
  const tools = useTypedSelector(selectTools);

  const userTools = user.tools;
  const eligibleCustomTools = tools.custom;

  const availableCustomTools = useMemo(
    () => differenceBy(eligibleCustomTools, userTools, '@id'),
    [eligibleCustomTools, userTools],
  );

  const { isLoading, doFetch } = useAddUserTool();

  const { clickAddAppButtonTracking } = useClickTracking();

  const onAddCustomTool = (customTool: Tool): void => {
    clickAddAppButtonTracking(customTool.title ?? customTool.urlDesktop ?? customTool.uuid);
    doFetch(customTool);
  };

  return (
    <>
      <div className={`available-custom-tool ${isLoading ? 'loading-available-custom-tool' : ''}`}>
        {availableCustomTools.map(availableCustomTool => (
          <AvailableCustomToolRow
            availableCustomTool={availableCustomTool}
            key={`add-modal-${availableCustomTool['@id'] !== undefined ? availableCustomTool['@id'] : ''}`}
            onAdd={() => onAddCustomTool(availableCustomTool)}
          />
        ))}
      </div>
      {isLoading && (
        <div className="add-app-loader">
          <Loader />
        </div>
      )}
    </>
  );
};

import { Dispatch } from 'redux';

import fetchApi from 'data/fetchApi';
import { logger } from 'services/logs/logger';
import { Meteo } from 'types/Meteo';

export const START_FETCH_METEO = 'START_FETCH_METEO';
export const RECEIVE_METEO = 'RECEIVE_METEO';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- FIXME
export const startFetchMeteo = () => {
  return {
    type: START_FETCH_METEO,
  };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- FIXME
export const receiveMeteo = (meteo: Meteo) => {
  return {
    type: RECEIVE_METEO,
    meteo,
  };
};

export const fetchMeteo = () => (dispatch: Dispatch<{ type: string; meteo?: Meteo }>) => {
  dispatch(startFetchMeteo());

  return fetchApi(`/api/users/me/weather`).then(
    responseJson => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME
      dispatch(receiveMeteo(responseJson));
    },
    (rejectionError: Record<string, unknown>) => {
      logger.error('Failed to fetch weather', rejectionError);
    },
  );
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './ViewCount.css';

interface Props {
  count: number;
}

export const ViewCount: React.FC<Props> = ({ count }) => (
  <div className="view-count-container" data-testid={'view-count-container'}>
    <span className="view-count-value">{count}</span>
    <span className="view-count-icon">
      <FontAwesomeIcon icon={['fas', 'eye']} />
    </span>
  </div>
);

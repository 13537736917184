import { useCallback, useEffect, useState } from 'react';

import { receiveCustomTools, receiveIndispensableTools } from 'data/actions/ToolActions';
import { receiveUserLinks, receiveUserTools } from 'data/actions/UserActions';
import fetchApi from 'data/fetchApi';
import { useTypedDispatch, useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { logger } from 'services/logs/logger';
import { FetchApiResponse } from 'types/FetchApiResponse';
import { CodeIso } from 'types/Language';
import { Link } from 'types/Link';
import { ToolWithGroup } from 'types/Tool';

export const useFetchApps = (
  language: CodeIso,
  autoFetch = true,
): {
  areAppsLoading: boolean;
  refetch: () => void;
} => {
  const user = useTypedSelector(selectUser);
  const [areAppsLoading, setAreAppsLoading] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(autoFetch);

  const refetch = useCallback(() => {
    setShouldFetch(true);
  }, []);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (shouldFetch) {
      setAreAppsLoading(true);
      Promise.all([
        fetchApi(`/api/indispensable_tools?query=${language}`),
        fetchApi(`/api/users/me/authorized_custom_tools`),
        user.uuid && fetchApi(`/api/users/${user.uuid}/tools`),
        user.uuid && fetchApi(`/api/users/${user.uuid}/links`),
      ])
        .then(
          (
            responses: [
              FetchApiResponse<ToolWithGroup[]>,
              FetchApiResponse<ToolWithGroup[]>,
              FetchApiResponse<ToolWithGroup[]>,
              FetchApiResponse<Link[]>,
            ],
          ) => {
            const [indispensableToolsResponse, userEnabledToolsResponse, userToolsResponse, userLinksResponse] =
              responses;

            // Receive complete list of indispensable tools. display all.
            dispatch(receiveIndispensableTools(indispensableToolsResponse['hydra:member']));

            // Receive complete list of custom tools. display some.
            dispatch(receiveCustomTools(userEnabledToolsResponse['hydra:member']));

            // Receive users added tools and custom links
            dispatch(receiveUserTools(userToolsResponse['hydra:member']));
            dispatch(receiveUserLinks(userLinksResponse['hydra:member']));

            setAreAppsLoading(false);
          },
        )
        .catch((error: Record<string, unknown>) => logger.error('Failed to fetch user apps', error));
    }
  }, [dispatch, language, shouldFetch, user.uuid]);

  return { areAppsLoading, refetch };
};

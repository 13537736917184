import { useMemo } from 'react';

import { useFetchItem } from './useFetchItem';

interface useLikeNewsReturnType {
  isLikeLoading: boolean;
  doLike: () => void;
}

export const useLikeNews = (onSuccess: () => void, id?: string): useLikeNewsReturnType => {
  const extraOptions = useMemo(
    () => ({
      method: 'PUT',
      body: JSON.stringify({}),
    }),
    [],
  );

  const { loading, refetch } = useFetchItem({
    url: `/api/like_internal_news/${id ?? ''}`,
    errorMessage: `Failed to like the internal news n°${id ?? ''}`,
    successCallback: onSuccess,
    extraOptions,
    autoFetch: false,
  });

  return { isLikeLoading: loading, doLike: id ? refetch : () => undefined };
};

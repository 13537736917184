import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';
import { Expo, gsap } from 'gsap';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

import { useDeleteTool } from 'components/App/hooks/useDeleteTool';
import Icon from 'components/Icon/Icon';
import Loader from 'components/Loader/Loader';
import { receiveUserTools } from 'data/actions/UserActions';
import { useTypedDispatch, useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';
import { ToolWithGroup } from 'types/Tool';

import './Tool.css';

type Props = {
  shouldShowDeleteButton?: boolean;
  shouldShade?: boolean;
  onDelete?: () => void;
  data: ToolWithGroup;
  onClick: (label: string) => void;
};

export const ToolItem = ({
  shouldShowDeleteButton = false,
  data,
  shouldShade = false,
  onClick,
}: Props): JSX.Element => {
  const iconDarkColor = '#3D3D42';
  const iconLightColor = '#FFFFFF';

  const colorCustomTool = '#009F4A';
  const colorNotCustomTool = '#CCCCCC';

  const toolUrl = data.url ?? (isMobile ? data.urlMobile : data.urlDesktop);
  const toolColor = data.group?.color
    ? '#' + data.group.color
    : data['@type'] === 'CustomTool'
    ? colorCustomTool
    : colorNotCustomTool;

  const isLight = Color(toolColor).isLight();
  const [tile, setTile] = useState<HTMLDivElement | null>(null);

  const tileImage =
    data.icon !== undefined && data.icon.length > 0 ? (
      <Icon name={data.icon} width="40%" height="40%" color={isLight ? iconDarkColor : iconLightColor} />
    ) : (
      <span className="initial-icon">{data.title?.charAt(0)}</span>
    );

  const onMouseEnter = (): void => {
    if (!tile) {
      return;
    }

    gsap.to(tile, {
      duration: 0.5,
      scale: 0.9,
      ease: Expo.easeOut,
    });
  };

  const onMouseLeave = (): void => {
    if (!tile) {
      return;
    }
    gsap.to(tile, {
      duration: 0.5,
      scale: 1,
      ease: Expo.easeOut,
    });
  };

  const { loading, deleteTool, isSuccess } = useDeleteTool(data);
  const user = useTypedSelector(selectUser);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (isSuccess) {
      const userTools = user.tools.filter(tool => tool['@id'] !== data['@id']);
      dispatch(receiveUserTools(userTools));
    }
  }, [data, dispatch, isSuccess, user.tools]);

  return (
    <div className="tool-container">
      <div className={loading ? 'loading-tool' : ''}>
        <a
          id={data.icon}
          href={toolUrl}
          className={`tool ${shouldShade && 'shaded-tool'} ${shouldShowDeleteButton && 'disabled-tool'}`}
          target="_blank"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={_ => onClick(data.title ?? data.urlDesktop ?? data.uuid)}
          rel="noreferrer"
        >
          <div ref={setTile} className="tool-tile" style={{ backgroundColor: toolColor }}>
            {tileImage}
          </div>

          <div className="tool-details">
            <p className="tool-title">{data.title}</p>
            <p className="tool-subtitle">{data.subtitle}</p>
          </div>
        </a>
        {shouldShowDeleteButton && (
          <button className="tools-delete-button" onClick={deleteTool} type="button" data-testid="delete-tool-button">
            <div className="tools-delete-button-content">
              <FontAwesomeIcon icon={['fas', 'xmark']} />
            </div>
          </button>
        )}
        {loading && (
          <div className="tool-loader">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

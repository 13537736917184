import React, { useCallback } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { LinkItem } from 'components/Toolbox/Tool/LinkItem';
import { ToolItem } from 'components/Toolbox/Tool/ToolItem';
import { useTypedSelector } from 'data/hooks';
import { selectTools, selectUser } from 'data/selectors';
import { ToolElement, ToolElementType } from 'types/ToolElement';

import './ToolList.css';

interface Props {
  isDeletingMode?: boolean;
  numberOfToolsToDisplay?: number;
  isInAllAppsModal?: boolean;
}

export const ToolList: React.FC<Props> = ({
  isDeletingMode = false,
  numberOfToolsToDisplay,
  isInAllAppsModal = false,
}) => {
  const allTools: ToolElement[] = [];
  const { indispensable: indispensableTools } = useTypedSelector(selectTools);
  const { links: userLinks, tools: userTools } = useTypedSelector(selectUser);

  indispensableTools?.forEach(tool => allTools.push({ type: ToolElementType.INDISPENSABLE_TOOL, element: tool }));

  const isOnMobile = useIsOnMobile();
  const userToolsToDisplay = isOnMobile ? userTools.filter(tool => tool.isAdaptive) : userTools;

  userToolsToDisplay.forEach(tool => allTools.push({ type: ToolElementType.USER_TOOL, element: tool }));

  userLinks.forEach(link => allTools.push({ type: ToolElementType.USER_LINK, element: link }));

  const toolsToDisplay = numberOfToolsToDisplay === undefined ? allTools : allTools.slice(0, numberOfToolsToDisplay);

  const { clickIndispensableAppTracking, clickPersonalAppTracking, clickLinkAppTracking, clickAllAppsToolTracking } =
    useClickTracking();

  const handleToolItemClick = useCallback(
    (toolType: ToolElementType, toolLabel: string) => {
      isInAllAppsModal && clickAllAppsToolTracking(toolLabel);
      switch (toolType) {
        case ToolElementType.INDISPENSABLE_TOOL:
          return clickIndispensableAppTracking(toolLabel);
        case ToolElementType.USER_TOOL:
          return clickPersonalAppTracking(toolLabel);
        case ToolElementType.USER_LINK:
          return clickLinkAppTracking(toolLabel);
      }
    },
    [
      clickAllAppsToolTracking,
      clickIndispensableAppTracking,
      clickLinkAppTracking,
      clickPersonalAppTracking,
      isInAllAppsModal,
    ],
  );

  return (
    <div className="frontoffice-tool-list">
      {toolsToDisplay.map(tool => {
        switch (tool.type) {
          case ToolElementType.INDISPENSABLE_TOOL:
            return (
              <ToolItem
                key={tool.element['@id']}
                data={tool.element}
                shouldShade={isDeletingMode}
                onClick={toolLabel => handleToolItemClick(tool.type, toolLabel)}
              />
            );
          case ToolElementType.USER_TOOL:
            return (
              <ToolItem
                key={tool.element['@id']}
                data={tool.element}
                shouldShowDeleteButton={isDeletingMode}
                onClick={toolLabel => handleToolItemClick(tool.type, toolLabel)}
              />
            );
          case ToolElementType.USER_LINK:
            return (
              <LinkItem
                key={tool.element['@id']}
                userLink={tool.element}
                shouldShowDeleteButton={isDeletingMode}
                onClick={toolLabel => handleToolItemClick(tool.type, toolLabel)}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

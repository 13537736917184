import React, { useEffect } from 'react';

import { PaginationButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import LeftChevron from 'images/icons/left_chevron.svg';
import RightChevron from 'images/icons/right_chevron.svg';

import './PaginationButtons.css';

interface PaginationButtonsProps {
  totalItems: number;
  itemsPerPage?: number;
  initiallySelectedPage?: number;
  onSelectPage: (selectedPage: number) => void;
  maxNumberOfPageButtonsToDisplay?: number;
  onClick?: (clickedPage: number) => void;
}

const DEFAULT_ITEMS_PER_PAGE = 10;
const DEFAULT_INITIALLY_SELECTED_PAGE = 1;
const DEFAULT_MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY = 10;

export const PaginationButtons: React.FC<PaginationButtonsProps> = ({
  totalItems,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
  initiallySelectedPage = DEFAULT_INITIALLY_SELECTED_PAGE,
  onSelectPage,
  maxNumberOfPageButtonsToDisplay = DEFAULT_MAX_NUMBER_OF_PAGE_BUTTONS_TO_DISPLAY,
  onClick,
}) => {
  const { t } = useTranslation();

  const totalPages = getTotalPages(totalItems, itemsPerPage);

  const [selectedPage, setSelectedPage] = React.useState(initiallySelectedPage);

  useEffect(() => onSelectPage(selectedPage), [onSelectPage, selectedPage]);

  const pageButtonsToDisplay = Array.from(
    { length: getNumberOfPageButtonsToDisplay(totalItems, itemsPerPage, maxNumberOfPageButtonsToDisplay) },
    (_, i) => i + getFirstPageButtonToDisplay(totalItems, totalPages, selectedPage, maxNumberOfPageButtonsToDisplay),
  );

  const shouldShowSelectPreviousPageButton: boolean = selectedPage > 1;

  const shouldShowSelectNextPageButton: boolean = selectedPage < totalPages;

  const onPaginationButtonClick = (clickedPage: number): void => {
    if (onClick !== undefined) onClick(clickedPage);
    setSelectedPage(clickedPage);
  };

  return (
    <div className="pagination-button-container">
      {shouldShowSelectPreviousPageButton ? (
        <PaginationButton
          image={LeftChevron}
          alt={t('table_pagination.previous')}
          onClick={() => onPaginationButtonClick(selectedPage - 1)}
          key="previous-pagination-button"
        />
      ) : (
        <div className="pagination-button-placeholder" />
      )}
      {pageButtonsToDisplay.map(buttonValue => (
        <PaginationButton
          text={buttonValue}
          onClick={() => onPaginationButtonClick(buttonValue)}
          isSelected={selectedPage === buttonValue}
          key={`button ${buttonValue}`}
        />
      ))}
      {shouldShowSelectNextPageButton ? (
        <PaginationButton
          image={RightChevron}
          alt={t('table_pagination.next')}
          onClick={() => onPaginationButtonClick(selectedPage + 1)}
          key="next-pagination-button"
        />
      ) : (
        <div className="pagination-button-placeholder" />
      )}
    </div>
  );
};

const getNumberOfPageButtonsToDisplay = (
  totalItems: number,
  itemsPerPage: number,
  maxNumberOfPageButtonsToDisplay: number,
): number => {
  if (totalItems <= itemsPerPage) {
    return 0;
  }

  if (totalItems > itemsPerPage * maxNumberOfPageButtonsToDisplay) {
    return maxNumberOfPageButtonsToDisplay;
  }

  return Math.ceil(totalItems / itemsPerPage);
};

const getTotalPages = (totalItems: number, itemsPerPage: number): number => {
  return Math.ceil(totalItems / itemsPerPage);
};

const getFirstPageButtonToDisplay = (
  totalItems: number,
  totalPages: number,
  currentPage: number,
  maxNumberOfPageButtonsToDisplay: number,
): number => {
  if (totalPages <= maxNumberOfPageButtonsToDisplay) {
    return 1;
  }

  if (currentPage <= Math.ceil(maxNumberOfPageButtonsToDisplay / 2)) {
    return 1;
  }

  if (currentPage > totalPages - Math.ceil(maxNumberOfPageButtonsToDisplay / 2)) {
    return totalPages - maxNumberOfPageButtonsToDisplay + 1;
  }

  return currentPage - Math.ceil(maxNumberOfPageButtonsToDisplay / 2) + 1;
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { getUserInitials } from 'components/utils';
import { Contact } from 'types/Contact';

import './ContactSearchResultsRow.css';

interface ContactSearchResultsRowProps {
  contact: Contact;
  onClick: (contact: Contact) => void;
}

export const ContactSearchResultsRow: React.FC<ContactSearchResultsRowProps> = ({ contact, onClick }) => {
  const [avatarUrl, setAvatarUrl] = useState(contact.avatarUrl);

  return (
    <div
      className={'contact-search-results-row-container'}
      data-testid="contact-search-results-row"
      onClick={() => onClick(contact)}
    >
      <div className="contact-picture">
        {avatarUrl !== null ? (
          <img
            src={avatarUrl}
            alt={getUserInitials(contact.firstname, contact.lastname)}
            onError={() => {
              setAvatarUrl(null);
            }}
          />
        ) : (
          <FontAwesomeIcon icon={['fas', 'user']} />
        )}
      </div>
      <div className={'contact-infos'}>
        <span className={'contact-name'}>
          {contact.firstname} {contact.lastname}
        </span>
        {contact.post && <span className={'contact-job'}>{contact.post}</span>}
      </div>
    </div>
  );
};

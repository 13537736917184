import { useUserLanguageContext } from 'context/UserLanguageContext';
import { CodeIso, frenchCodeIso } from 'types/Language';
import { UserCountryPublicationScope } from 'types/PublicationScope';
import { PublicationScopeListItem } from 'types/PublicationScopeListItem';

export const getPublicationScopesDisplayName = (
  publicationScopes: PublicationScopeListItem[] | null,
  isoCode: CodeIso,
): string =>
  publicationScopes === null
    ? ''
    : publicationScopes
        .map((scope: PublicationScopeListItem) => getPublicationScopeDisplayName(scope, isoCode))
        .join(', ');

export const getPublicationScopeDisplayName = (
  publicationScope: PublicationScopeListItem | null | undefined,
  isoCode: CodeIso,
): string => {
  if (!publicationScope) {
    return '';
  }

  return isoCode === frenchCodeIso ? publicationScope?.frenchName : publicationScope?.englishName;
};
export const usePublicationScopeDisplayNameInLanguage = (
  publicationScope: PublicationScopeListItem | null | undefined,
): string => {
  const { userLanguage } = useUserLanguageContext();

  return getPublicationScopeDisplayName(publicationScope, userLanguage);
};

export const getCountryPublicationScopeDisplayNameWithActivity = (
  isoCode: CodeIso,
  countryPublicationScope: UserCountryPublicationScope | null,
): string =>
  null === countryPublicationScope
    ? ''
    : `${getPublicationScopeDisplayName(
        countryPublicationScope?.activityParentScope,
        isoCode,
      )} - ${getPublicationScopeDisplayName(countryPublicationScope, isoCode)}`;

export const useCountryPublicationScopeDisplayNameWithActivityInLanguage = (
  countryPublicationScope: UserCountryPublicationScope | null,
): string => {
  const { userLanguage } = useUserLanguageContext();

  return getCountryPublicationScopeDisplayNameWithActivity(userLanguage, countryPublicationScope);
};

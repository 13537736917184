import 'abortcontroller-polyfill';
import jwtDecode from 'jwt-decode';

import Config from 'Config';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/localStorage';

// eslint-disable-next-line no-restricted-imports -- FIXME
import { authProvider } from '../components/AddSmalAuth/AuthProvider';
// eslint-disable-next-line no-restricted-imports -- FIXME
import wordings from '../components/Localization/Localisation';
import ApiError from './ApiError';

const rawStorageToken = getLocalStorageItem('userToken');
let userToken = rawStorageToken ? { raw: rawStorageToken, ...jwtDecode(rawStorageToken) } : null;

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
const fetchApi = function fetchApi(apiPath, extraOptions = {}) {
  return fetchApi.getToken().then(token => {
    // Avoid double pathname from apiBase when using ID's as endpoint
    const parser = document.createElement('a');
    parser.href = `${Config.apiBase}`;
    const basePath = parser.pathname.charAt(0) === '/' ? parser.pathname : '/' + parser.pathname;

    let endpoint = `${Config.apiBase}${apiPath}`;
    endpoint = endpoint.replace(`${basePath}${basePath}/`, `${basePath}/`);

    const headers = {
      accept: 'application/ld+json',
      'content-type': 'application/ld+json',
      Authorization: 'Bearer ' + token.raw,
    };

    const requestOptions = Object.assign(
      {
        headers: headers,
        credentials: 'include',
      },
      extraOptions,
    );

    // DELETE requests will not return anything, do not expect response body
    // extraOptions.expectResponse might be set to false too.
    const expectResponse =
      !(
        // eslint-disable-next-line no-prototype-builtins
        (extraOptions.hasOwnProperty('expectResponse') && extraOptions.expectResponse === false)
      ) &&
      (requestOptions.method === undefined || ['GET', 'POST', 'PUT'].indexOf(requestOptions.method) > -1);

    return fetch(endpoint, requestOptions)
      .then(response => {
        if (response.status.toString().charAt(0) !== '2') {
          let message = wordings['oops_error'];
          if (response.status === 502) {
            message = wordings['error_json'];
          }

          // return promise that will eventually throw an Error with the response body
          return response.json().then(responseJson => {
            throw new ApiError(message, response.status, responseJson);
          });
        } else {
          return expectResponse ? response.json() : null;
        }
      })
      .catch(error => {
        throw error;
      });
  });
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions -- FIXME
fetchApi.getToken = async function () {
  let tokenPromise;
  const token = await authProvider.getAccessToken();

  // If there is a token and it hasn't expired, return it in promise
  if (userToken && userToken.exp >= Date.now() / 1000) {
    // create immediately resolving promise
    tokenPromise = new Promise(resolve => resolve(userToken));
  } else {
    const endpoint = `${Config.apiBase}/token`;

    tokenPromise = fetch(endpoint, {
      credentials: 'include',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        accept: 'application/ld+json',
        'content-type': 'application/ld+json',
        Authorization: 'Bearer ' + token.accessToken,
      },
    })
      .then(response => {
        // PREVENT REDIRECT IF API IS DOWN SO I CAN WORK ON DASHBOARD OFFLINE ----- TODO : REMOVE THIS BEFORE DEPLOY
        //return {}

        // If the token request is redirecting because we aren't logged in via Colas sign-in, follow the redirect.
        if (response.url.indexOf(endpoint) < 0) {
          // should use response.redirected but IE 11 doesn't want it
          // Attempting local redirect, test if we're on windows first
          if (response.url.match(/^[A-Z]:\\/i) || response.url.match(/^file:\/\//i)) {
            if (window.navigator.platform.match(/window/i)) {
              window.location = response.url; // Warning : The WhatWG fetch polyfill gets this information from the custom X-Request-URL header
            } else {
              const error = new Error(wordings['error_access_colas_page']);
              error.final = true; // This prevents error from being dissmissed
              throw error;
            }
          }

          window.location = response.url; // Warning : The WhatWG fetch polyfill gets this information from the custom X-Request-URL header
        } else {
          if (response.status.toString().charAt(0) !== '2') {
            let message = wordings['error_no_authorize_access_portal'];
            if (response.status === 502) {
              message = wordings['error_json'];
            }
            // Case where ARU is wrong but we are connected via Colas Signin
            const apiError = new ApiError(message, response.status);
            apiError.final = true; // can't recover from this error
            throw apiError;
          } else {
            return response.json();
          }
        }
      })
      .then(responseJson => {
        // Decode and save the token
        const rawToken = responseJson.token;
        setLocalStorageItem('userToken', rawToken);

        userToken = jwtDecode(rawToken);
        userToken.raw = rawToken;

        return userToken;
      })
      .catch(error => {
        throw error;
      });
  }

  return tokenPromise;
};

export default fetchApi;

import { useTypedSelector } from 'data/hooks';
import { selectUserCountryPublicationScope } from 'data/selectors';
import { useHasFetchedUser } from 'hooks/useHasFetchedUser';
import { getPublicationScopeFromLocalStorage } from 'utils/localStorage';

export const useUserAllowedToSeeWorldMap = (): boolean => {
  const hasFetchedUser = useHasFetchedUser();
  const userCountryScope = useTypedSelector(selectUserCountryPublicationScope);

  return hasFetchedUser && userCountryScope === null && getPublicationScopeFromLocalStorage() === null;
};

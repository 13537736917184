import { useMemo, useState } from 'react';

import { Tool } from 'types/Tool';

import { useFetchItem } from './useFetchItem';

type useDeleteToolReturnType = {
  loading: boolean;
  deleteTool: () => void;
  isSuccess: boolean;
};
export const useDeleteTool = (toolToDelete: Tool): useDeleteToolReturnType => {
  const extraOptions = useMemo(
    () => ({
      method: 'PUT',
      body: JSON.stringify({ toolUuid: toolToDelete.uuid }),
    }),
    [toolToDelete],
  );
  const [isSuccess, setIsSuccess] = useState(false);

  const onSuccess = (): void => setIsSuccess(true);

  const { loading, refetch } = useFetchItem<Tool[]>({
    url: `/api/users/custom_tools/update`,
    errorMessage: 'Failed to delete user tool',
    successCallback: onSuccess,
    extraOptions,
    autoFetch: false,
  });

  return { loading, deleteTool: refetch, isSuccess };
};

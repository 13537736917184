import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './LikeCount.css';

interface Props {
  likeCount: number;
}

export const LikeCount: React.FC<Props> = ({ likeCount }) => (
  <div className="like-count-container">
    <div className="like-count-number-of-likes"> {likeCount} </div>
    <div className="like-count-icon" data-testid="likes-icon">
      <FontAwesomeIcon icon={['far', 'thumbs-up']} />
    </div>
  </div>
);

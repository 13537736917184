import { ComponentType, createContext, useContext, useEffect, useState } from 'react';

import { useTypedSelector } from 'data/hooks';
import { selectUserCountryPublicationScope } from 'data/selectors';
import { useHasFetchedUser } from 'hooks/useHasFetchedUser';
import { UserCountryPublicationScope } from 'types/PublicationScope';
import { getPublicationScopeFromLocalStorage } from 'utils/localStorage';

interface FrontOfficeLocalScopeContextProps {
  frontOfficeLocalScope: UserCountryPublicationScope | null;
  setFrontOfficeLocalScope: (scope: UserCountryPublicationScope) => void;
}

const defaultSetFrontOfficeLocalScope = (_: UserCountryPublicationScope): void => {
  return;
};

export const FrontOfficeLocalScopeContext = createContext<FrontOfficeLocalScopeContextProps>({
  frontOfficeLocalScope: null,
  setFrontOfficeLocalScope: defaultSetFrontOfficeLocalScope,
});

export const withFrontOfficeLocalScopeContext =
  (WrappedComponent: ComponentType): ComponentType =>
  (props: object) => {
    const userCountryPublicationScope = useTypedSelector(selectUserCountryPublicationScope);

    const [frontOfficeLocalScope, setFrontOfficeLocalScope] = useState(userCountryPublicationScope);
    const hasFetchedUser = useHasFetchedUser();
    const isUserFetchedAndHasNoPublicationScope = hasFetchedUser && userCountryPublicationScope === null;

    useEffect(() => {
      if (!frontOfficeLocalScope) {
        if (isUserFetchedAndHasNoPublicationScope) {
          setFrontOfficeLocalScope(getPublicationScopeFromLocalStorage());
        } else {
          setFrontOfficeLocalScope(userCountryPublicationScope);
        }
      }
    }, [userCountryPublicationScope, frontOfficeLocalScope, isUserFetchedAndHasNoPublicationScope]);

    return (
      <FrontOfficeLocalScopeContext.Provider value={{ frontOfficeLocalScope, setFrontOfficeLocalScope }}>
        <WrappedComponent {...props} />
      </FrontOfficeLocalScopeContext.Provider>
    );
  };

export const useFrontOfficeLocalScopeContext = (): FrontOfficeLocalScopeContextProps =>
  useContext(FrontOfficeLocalScopeContext);

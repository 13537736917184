import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gsap } from 'gsap';
import React, { useCallback, useEffect, useState } from 'react';

import useClickTracking from 'analytics/useClickTracking';
import { useFetchApps } from 'components/App/hooks/useFetchApps';
import { useIsOnMobile } from 'components/App/hooks/useIsOnMobile';
import { PrimaryButton } from 'components/CustomButton/CustomButton';
import { useTranslation } from 'components/Localization/Localisation';
import { ToolList } from 'components/ToolList/ToolList';
import { AppsModal } from 'components/UI/AppsModal/AppsModal';
import { Title } from 'components/UI/Title/Title';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { useTypedSelector } from 'data/hooks';
import { selectUser } from 'data/selectors';

import { TilesSkeleton } from './TilesSkeleton';

import './AppBox.css';

const NUMBER_OF_TOOLS_TO_DISPLAY = 16;

const AppBox: React.FC = () => {
  const { t } = useTranslation();

  const isOnMobile = useIsOnMobile();
  const { userLanguage } = useUserLanguageContext();

  const [tileAnimation, setTileAnimation] = useState<GSAPTween>();
  const [fakeTiles, setFakeTiles] = useState<unknown[]>([]);

  const [toolList, setToolList] = useState<JSX.Element>();
  const user = useTypedSelector(selectUser);

  const { clickManageAppsTracking } = useClickTracking();

  const { areAppsLoading, refetch } = useFetchApps(userLanguage, false);
  const isLoading = areAppsLoading || !user.uuid;
  useEffect(() => {
    if (user.uuid !== undefined) {
      refetch();
    }
  }, [refetch, user, userLanguage]);

  const startLoadAnimation = (): void => {
    fakeTiles.length > 0 &&
      setTileAnimation(
        gsap.to(fakeTiles, {
          duration: 0.8,
          opacity: 0.35,
          yoyo: true,
          repeat: -1,
          stagger: 0.025,
        }),
      );
  };

  const stopLoadingAnimation = useCallback((): void => {
    tileAnimation?.kill();
  }, [tileAnimation]);

  useEffect(() => {
    if (!isLoading) {
      stopLoadingAnimation();
      setToolList(<ToolList numberOfToolsToDisplay={isOnMobile ? undefined : NUMBER_OF_TOOLS_TO_DISPLAY} />);
    } else {
      startLoadAnimation();
      setToolList(<TilesSkeleton setFakeTiles={setFakeTiles} numberOfTiles={16} />);
    }
    //we don't want to put other dependency to avoid infinite rendering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const [shouldShowAppsModal, setShouldShowAppsModal] = useState(false);

  const onCloseAppsModal = (): void => {
    setShouldShowAppsModal(false);
  };

  return (
    <>
      <div className="appbox">
        <Title title={t('frontoffice.toolbox.my_tools')} />
        {toolList}

        {!isLoading && (
          <PrimaryButton
            text={t('frontoffice.manage_apps')}
            alt={'A Right Arrow'}
            image={<FontAwesomeIcon icon={['fas', 'arrow-right']} color="white" />}
            positionOfImage="right"
            onClick={() => {
              setShouldShowAppsModal(true);
              clickManageAppsTracking();
            }}
          />
        )}
      </div>
      <AppsModal isOpen={shouldShowAppsModal} onClose={onCloseAppsModal} />
    </>
  );
};

export default AppBox;

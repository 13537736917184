import compact from 'lodash/compact';
import React, { useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import useClickTracking from 'analytics/useClickTracking';
import { PreviewNewsWithSameThemes } from 'components/App/FrontOffice/Home/NewsWithSameThemes/PreviewNewsWithSameThemes';
import { useFetchNewsWithSameThemes } from 'components/App/hooks/useFetchNewsWithSameThemes';
import { getFrontOfficePath } from 'components/App/utils';
import Loader from 'components/Loader/Loader';
import { useTranslation } from 'components/Localization/Localisation';
import { NewsBlockError } from 'components/UI/Errors/NewsBlockError';
import { Title } from 'components/UI/Title/Title';
import { useFrontOfficeLocalScopeContext } from 'context/FrontOfficeLocalScopeContext';
import { useUserLanguageContext } from 'context/UserLanguageContext';
import { FrontOfficeRoutes } from 'router/routes';
import { InternalNewsDetails } from 'types/InternalNewsDetails';
import { ANALYTICS_LANGUAGE } from 'types/Language';
import { GLOBAL_PUBLICATION_SCOPE_ID } from 'types/PublicationScope';
import { getSelectedTranslation } from 'utils/internalNewsFunctions';

import './NewsWithSameThemes.css';

interface NewsWithSameThemesProps {
  newsDetails: InternalNewsDetails;
}

const ITEMS_PER_PAGE = 3;

export const NewsWithSameThemes: React.FC<NewsWithSameThemesProps> = ({ newsDetails }) => {
  const { t } = useTranslation();
  const { frontOfficeLocalScope } = useFrontOfficeLocalScopeContext();

  const themesIds = useMemo(() => compact(newsDetails?.themes.map(theme => theme.id?.toString()) ?? []), [newsDetails]);
  const newsScopeId = newsDetails.isGlobalNews ? GLOBAL_PUBLICATION_SCOPE_ID : frontOfficeLocalScope?.id ?? '';

  const {
    newsWithSameThemes,
    fetchNewsWithSameThemes,
    isLoading,
    isError: shouldShowError,
  } = useFetchNewsWithSameThemes(themesIds, newsDetails.id, ITEMS_PER_PAGE, newsScopeId);

  const { userLanguage } = useUserLanguageContext();

  useEffect(() => {
    fetchNewsWithSameThemes();
  }, [fetchNewsWithSameThemes]);

  const newsWithSameThemesInSelectedLanguageAndAnalyticsLanguage = useMemo(() => {
    newsWithSameThemes?.forEach(
      newWithSameTheme =>
        (newWithSameTheme.translations = [
          ...compact([getSelectedTranslation(newWithSameTheme.translations, userLanguage)]),
          ...compact([getSelectedTranslation(newWithSameTheme.translations, ANALYTICS_LANGUAGE)]),
        ]),
    );

    return compact(newsWithSameThemes);
  }, [newsWithSameThemes, userLanguage]);

  const { clickNewsSameThemeTracking } = useClickTracking();

  if (isLoading) {
    return (
      <div className="news-with-same-themes-full-height-container">
        <div className="news-with-same-themes-loader">
          <Loader />
        </div>
      </div>
    );
  }

  if (shouldShowError) {
    return (
      <div className="news-with-same-themes-full-height-container">
        <NewsBlockError />
      </div>
    );
  }

  if (
    (!newsDetails.isGlobalNews && frontOfficeLocalScope === null) ||
    newsWithSameThemesInSelectedLanguageAndAnalyticsLanguage === null ||
    newsWithSameThemesInSelectedLanguageAndAnalyticsLanguage.length === 0 ||
    newsWithSameThemesInSelectedLanguageAndAnalyticsLanguage.every(
      internalNews => internalNews.translations[0] === undefined,
    )
  ) {
    return null;
  }

  return (
    <div className="news-with-same-themes-container">
      <Title title={t('news.same_theme')} size="small" />
      <div className="news-with-same-themes">
        {newsWithSameThemesInSelectedLanguageAndAnalyticsLanguage.map(internalNews => {
          const newsTranslationInSelectedLanguage = getSelectedTranslation(internalNews.translations, userLanguage);
          const newsTranslationInAnalyticsLanguage = getSelectedTranslation(
            internalNews.translations,
            ANALYTICS_LANGUAGE,
          );

          return (
            newsTranslationInSelectedLanguage !== undefined &&
            newsTranslationInAnalyticsLanguage !== undefined && (
              <NavLink
                to={getFrontOfficePath(FrontOfficeRoutes.NEWS_DETAILS).replace(':id', `${internalNews.id}`)}
                key={internalNews.id}
                className="news-link-with-same-themes"
                data-testid="news-link-with-same-themes"
                onClick={() => clickNewsSameThemeTracking(newsTranslationInAnalyticsLanguage.title)}
              >
                <PreviewNewsWithSameThemes
                  title={newsTranslationInSelectedLanguage.title}
                  date={internalNews.publishedAt}
                  content={newsTranslationInSelectedLanguage.text}
                  thumbnail={userLanguage === 'FRA' ? internalNews.facultativeThumbnail : internalNews.mainThumbnail}
                  likeCount={internalNews.likeCount}
                  viewCount={internalNews.viewCount}
                />
              </NavLink>
            )
          );
        })}
      </div>
    </div>
  );
};
